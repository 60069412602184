/**
 * component ConnectedDatePicker
 */

import React from 'react'

import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { useField } from 'formik'

export type OnChangeFunc = (newValue: Date | null) => void

interface IProps extends DatePickerProps<Date> {
  name: string
  allowed?: boolean
  clearable?: boolean
  connected?: boolean
  value?: Date | null
  onValueChange?: OnChangeFunc
}

const ConnectedDatePicker: React.FC<IProps> = ({
  name,
  allowed = true,
  slotProps,
  clearable = true,
  connected = true,
  value = null,
  onValueChange,
  ...datePickerProps
}) => {
  let fieldValue
  let handleChange
  if (connected) {
    const [field, , helpers] = useField(name)
    fieldValue = field.value
    handleChange = (newVal: Date | null) => helpers.setValue(newVal)
  } else {
    fieldValue = value
    handleChange = onValueChange
  }

  return (
    <DatePicker
      value={fieldValue}
      onChange={handleChange}
      slotProps={{
        ...slotProps,
        textField: {
          variant: 'standard',
          fullWidth: true,
          type: clearable ? 'search' : 'input',
          ...slotProps?.textField
        },
        actionBar: {
          actions: clearable ? ['clear'] : []
        }
      }}
      disabled={!allowed}
      disableFuture
      {...datePickerProps}
    />
  )
}

export default ConnectedDatePicker
