import React from 'react'
import { styled } from '@mui/material/styles'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import { useField } from 'formik'

const PREFIX = 'SearchSlider'

const classes = {
  formControl: `${PREFIX}-formControl`,
  mark: `${PREFIX}-mark`,
  markActive: `${PREFIX}-markActive`,
  markLabel: `${PREFIX}-markLabel`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.formControl}`]: {
    width: '100%'
  },
  [`& .${classes.mark}`]: {
    backgroundColor: 'transparent'
  },
  [`& .${classes.markActive}`]: {
    opacity: 0
  },
  [`& .${classes.markLabel}`]: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    '&[data-index="0"]': {
      paddingLeft: '15px'
    },
    '&[data-index="1"]': {
      paddingRight: '20px'
    }
  }
}))

const SLIDER_DEFAULT = 85
const MIN_SLIDER_VALUE = 50
const MAX_SLIDER_VALUE = 100

export interface SliderProps {
  label?: string
  defaultValue?: number
  value?: number
  handleChange?: (event: Event, value: number | number[]) => void
  handleChangeCommitted?: (
    event: Event | React.SyntheticEvent,
    value: number | number[]
  ) => void
  min?: number
  max?: number
}

const SearchSlider: React.FC<SliderProps> = ({
  label = 'Minimum Score',
  defaultValue = SLIDER_DEFAULT,
  value,
  handleChange,
  handleChangeCommitted,
  min = MIN_SLIDER_VALUE,
  max = MAX_SLIDER_VALUE
}) => {
  const marks = [
    {
      label: min,
      value: min
    },
    {
      label: max,
      value: max
    }
  ]

  return (
    <Root className={classes.formControl}>
      <Typography gutterBottom>{label}</Typography>
      <Slider
        classes={{
          mark: classes.mark,
          markActive: classes.markActive,
          markLabel: classes.markLabel
        }}
        data-cy='search-slider'
        defaultValue={defaultValue}
        value={value}
        valueLabelDisplay='auto'
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        step={1}
        marks={marks}
        min={min}
        max={max}
        size='small'
      />
    </Root>
  )
}

type ConnectedSearchSliderProps = Omit<
  SliderProps,
  'handleChange' | 'handleChangeCommitted'
> & { name: string }

export const ConnectedSearchSlider: React.FC<ConnectedSearchSliderProps> = ({
  name,
  ...sliderProps
}) => {
  const formik = useField(name)
  const meta = formik[1]
  const helpers = formik[2]

  const handleChangeCommitted = (
    e: React.SyntheticEvent | Event,
    v: number | number[]
  ) => {
    if (typeof v === 'number') {
      helpers.setValue(v)
    }
  }
  return (
    <SearchSlider
      {...sliderProps}
      defaultValue={meta.initialValue}
      handleChangeCommitted={handleChangeCommitted}
    />
  )
}

export default SearchSlider
