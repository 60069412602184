import React from 'react'
import { styled } from '@mui/material/styles'

import Button, { ButtonProps } from '@mui/material/Button'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

const StyledButton = styled(Button)(() => ({
  minWidth: '132px'
}))

const ExportIcon = styled(PictureAsPdfIcon)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}))

export interface IExportButtonProps extends ButtonProps {
  loading?: boolean
}

export const ExportButton: React.FC<IExportButtonProps> = ({
  disabled = false,
  fullWidth = false,
  loading = false,
  onClick
}) => {
  return (
    <StyledButton
      variant='contained'
      data-cy='report-button'
      fullWidth={fullWidth}
      disabled={disabled || loading}
      color='grey'
      onClick={onClick}
    >
      {!loading && (
        <>
          Workflow
          <ExportIcon />
        </>
      )}
      {loading && 'Generating...'}
    </StyledButton>
  )
}

export default ExportButton
