import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { styled } from '@mui/material/styles'

import { useBranding } from '@/lib/hooks'

const PREFIX = 'Footer'

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  link: `${PREFIX}-link`,
  linkWrapper: `${PREFIX}-linkWrapper`,
  socialButton: `${PREFIX}-socialButton`,
  wrapper: `${PREFIX}-wrapper`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: 'black',
    color: 'white',
    padding: theme.spacing(9)
  },
  [`& .${classes.container}`]: {
    width: '100%'
  },
  [`& .${classes.linkWrapper}`]: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right'
    }
  },
  [`& .${classes.socialButton}`]: {
    color: 'black',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  [`& .${classes.wrapper}`]: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  }
}))

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textDecoration: 'none',
  '&:not(:last-child)': {
    paddingRight: '12px'
  },
  '&:hover': {
    textDecoration: 'none'
  },
  [theme.breakpoints.down('md')]: {
    display: 'block'
  }
}))

export const Footer: React.FC = () => {
  const branding = useBranding()

  return (
    <Root className={classes.root}>
      <Container maxWidth='lg'>
        <Grid className={classes.container} container spacing={3}>
          <Grid item xs={12} md={8}>
            {branding.isSuccess && (
              <>
                <Typography variant='h4'>{branding.data.company}</Typography>
                <Typography>{branding.data.tagline}</Typography>
              </>
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container direction='column' spacing={3}>
              <Grid item>
                <Grid container className={classes.wrapper} spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.linkWrapper}
                      variant='body1'
                      color='initial'
                    >
                      <StyledLink href='https://www.castellum.ai/privacy-policy'>
                        Privacy Policy
                      </StyledLink>
                      <StyledLink href='https://www.castellum.ai/terms-of-service'>
                        Terms of Service
                      </StyledLink>
                      <StyledLink href='https://support.castellum.ai/hc/en-us/requests/new'>
                        Support
                      </StyledLink>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      className={classes.socialButton}
                      href='https://www.linkedin.com/company/castellum-ai/'
                      target='_blank'
                      size='large'
                    >
                      <LinkedInIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      className={classes.socialButton}
                      href='https://twitter.com/CastellumAI'
                      target='_blank'
                      size='large'
                    >
                      <TwitterIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Root>
  )
}
