import React, { HTMLAttributes } from 'react'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete, {
  createFilterOptions,
  AutocompleteChangeDetails,
  AutocompleteChangeReason
} from '@mui/material/Autocomplete'
import { IOption, TDropDownProps } from '.'

/**
 * filterOptions determines how options are dynamically filtered as the user types in the
 * autocomplete text box. A string containing the option name and group is returned so that options
 * can be filtere by list source name and category name.
 */
const filterOptions = createFilterOptions({
  stringify: (option: IOption) => `${option.name}-${option.group}`
})

/**
 * Component for rendering a drop-down menu of list source options. The menu includes an "All"
 * option, followed by list source categoy options, followed by individual list source options
 * grouped by category. Option state is controled externally.
 *
 * @component
 * @example
 * optionsData = useListSourcesDropDownOptions()
 * return (
 *  <ListSourcesDropDown
 *    status=optionsData.status
 *    options=optionsData.options
 *    toggle=optionsData.toggle
 *    prune=optionsData.prune
 *    clear=optionsData.clear
 *  />
 * )
 */
export const ListSourcesDropDown = ({
  clear,
  status,
  options,
  toggle,
  prune
}: TDropDownProps): JSX.Element => {
  const [input, setInput] = React.useState('')
  const [selectedCount, setSelectedCount] = React.useState(0)

  const dropdownOptions: IOption[] = React.useMemo(() => {
    if (status === 'success') {
      const allOption = options.option
      const categoryOptions = []
      const listSourceOptions = []
      let numSelected = 0

      for (const categoryOptionNode of options.subOptions.values()) {
        categoryOptions.push(categoryOptionNode.option)
        for (const { option } of categoryOptionNode.subOptions.values()) {
          if (option.selected) {
            numSelected++
          }

          listSourceOptions.push(option)
        }
      }

      setSelectedCount(numSelected)
      return [allOption, ...categoryOptions, ...listSourceOptions]
    }

    return []
  }, [status, options])

  const onChange = (
    event: React.SyntheticEvent,
    value: IOption[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<IOption> | undefined
  ) => {
    if (reason === 'clear') {
      clear()
    } else if (details) {
      toggle(details.option.type, details.option.id)
    }
  }

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: IOption
  ) => {
    return (
      <li {...props}>
        <Checkbox
          color='primary'
          checked={option.selected}
          indeterminate={option.partial}
        />
        {option.name}
      </li>
    )
  }

  return (
    <Autocomplete
      getOptionDisabled={(option) => option.disabled}
      disableCloseOnSelect
      disabled={status !== 'success'}
      filterOptions={filterOptions}
      fullWidth
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) =>
        option.id === value.id && option.type === value.type
      }
      groupBy={(option) => option.group || ''}
      id='list-sources'
      includeInputInList
      inputValue={input}
      limitTags={3}
      multiple
      onClose={() => {
        setInput('')
        prune()
      }}
      onChange={onChange}
      onInputChange={(event, value, reason) => {
        if (reason !== 'reset') {
          setInput(value)
        }
      }}
      options={dropdownOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          error={status === 'error'}
          label={`List Sources (${selectedCount})`}
          variant='standard'
        />
      )}
      renderOption={renderOption}
      renderTags={() => null}
      size='small'
      value={[options.option]}
    />
  )
}

export default ListSourcesDropDown
