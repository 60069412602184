import { THitClassification } from '@/lib/features/Screening'

export enum STATUS_CLASSES {
  error = 'error',
  success = 'success',
  needMoreInfo = 'needMoreInfo',
  default = 'default'
}

/*
  Return the appropriate classname to show on the frontend based on the
  last hit's classification.
*/
export function hitAuditStatus(
  classification: THitClassification | undefined
): STATUS_CLASSES {
  let className = STATUS_CLASSES.default

  switch (classification) {
    case 'true-positive':
      className = STATUS_CLASSES.error
      break
    case 'false-positive':
      className = STATUS_CLASSES.success
      break
    case 'need-more-info':
      className = STATUS_CLASSES.needMoreInfo
      break
  }

  return className
}
