import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import {
  useDeleteScheduledJobRPC,
  TDeleteScheduledJobRequest
} from '@/lib/hooks/api/useDeleteScheduledJobRPC'
import { jobQueryKeys } from './queryKeys'

export type TDeleteScheduledJobMutation = UseMutationResult<
  unknown,
  unknown,
  TDeleteScheduledJobRequest,
  unknown
>

/**
 * useDeleteScheduledJob is a hook for deleting a scheduled job by ID.
 *
 * @param {number} jobID - The job ID to query.
 * @param {TQueryOptions} options - react-query query options.
 */
export const useDeleteScheduledJob = (
  id: number
): TDeleteScheduledJobMutation => {
  const rpc = useDeleteScheduledJobRPC()
  const queryClient = useQueryClient()

  return useMutation(rpc, {
    onSuccess: async (resp) => {
      queryClient.invalidateQueries(jobQueryKeys.userJobs)
      queryClient.setQueryData(jobQueryKeys.job(id), resp)
    }
  })
}
