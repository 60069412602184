import React from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'

const PREFIX = 'Highlight'

const classes = {
  root: `${PREFIX}-root`
}

const HighlightedText = styled('em')(({ theme }) => ({
  color: theme.palette.highlight.main
}))

const highlightWholeString = (str: string): JSX.IntrinsicElements['span'] => {
  return (
    <span>
      <HighlightedText>{str}</HighlightedText>
    </span>
  )
}

const highlightPartial = (
  str: string,
  tag: string
): JSX.IntrinsicElements['span'] => {
  const tagOpenStr = `<${tag}`
  const tagCloseStr = `</${tag}>`

  const reTagOpen = RegExp(tagOpenStr)
  const reTagClose = RegExp(tagCloseStr)

  const tagOpenIdx = str.search(RegExp(reTagOpen))
  if (tagOpenIdx >= 0) {
    const hlStartIdx = str.search(/>/)
    const hlEndIdx = str.search(reTagClose)
    if (hlStartIdx >= 0 && hlStartIdx < str.length && hlEndIdx > hlStartIdx) {
      const hlStr = str.substring(hlStartIdx + 1, hlEndIdx)
      const left = highlightPartial(str.substring(0, tagOpenIdx), tag)
      const right = highlightPartial(
        str.substring(hlEndIdx + tagCloseStr.length, str.length),
        tag
      )
      return (
        <span>
          {left}
          <HighlightedText>{hlStr}</HighlightedText>
          {right}
        </span>
      )
    }
  }
  return <>{str}</>
}

export type THighlight = {
  str: string
  className?: string
  tag?: string
  wholeString?: boolean
}

const Highlight: React.FC<THighlight> = ({
  str,
  className = '',
  tag = 'em',
  wholeString = false,
  ...rest
}) => {
  const components = wholeString
    ? highlightWholeString(str)
    : highlightPartial(str, tag)

  return (
    <span className={clsx(classes.root, className)} {...rest}>
      {components}
    </span>
  )
}

export default Highlight
