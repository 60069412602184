import React from 'react'
import { styled } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'

import Header from '@/components/lib/Header'
import Footer from '@/components/lib/Footer'
import GlobeSVG from '@/media/globe'

const PREFIX = 'ErrorPage'

const classes = {
  root: `${PREFIX}-root`,
  landing: `${PREFIX}-landing`,
  formContainer: `${PREFIX}-formContainer`,
  globeContainer: `${PREFIX}-globeContainer`,
  globe: `${PREFIX}-globe`,
  content: `${PREFIX}-content`,
  title: `${PREFIX}-title`
}

const StyledErrorPage = styled('div')(() => ({
  [`&.${classes.root}`]: {
    minHeight: 'inherit',
    width: '100%'
  },
  [`& .${classes.landing}`]: {
    minHeight: 'inherit',
    overflow: 'hidden'
  },
  [`& .${classes.formContainer}`]: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '25vh',
    position: 'relative',
    zIndex: 2
  },
  [`& .${classes.globeContainer}`]: {
    height: '60vw',
    minHeight: '400px',
    minWidth: '400px',
    position: 'absolute',
    right: '0px',
    width: '60vw',
    whiteSpace: 'nowrap',
    zIndex: -1
  },
  [`& .${classes.globe}`]: {
    paddingLeft: '5vw',
    height: '100%',
    width: '100%'
  },
  [`& .${classes.content}`]: {
    minHeight: 'inherit',
    position: 'relative',
    zIndex: 3,
    backgroundImage: `url(${import.meta.env.VITE_APP_CDN_PATH}/media/background.svg)`
  },
  [`& .${classes.title}`]: {
    fontSize: '26px',
    paddingBottom: '12px'
  }
}))

const DEFAULT_HEADER = 'Oops! Something went wrong...'
const DEFAULT_DESCRIPTION =
  'Help us improve your experience by letting us know at support@castellum.ai.'
const MESSAGE_REGEX = /^(\[([^\]].+)\] - )?(.+)$/

export const parseErrorMessage = (message: string | null): string[] => {
  const match = message?.match(MESSAGE_REGEX)
  const header = match && match[2] ? match[2] : DEFAULT_HEADER
  const description = match && match[3] ? match[3] : DEFAULT_DESCRIPTION
  return [header, description]
}

const ErrorPage: React.FC = () => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const [header, description] = parseErrorMessage(query.get('message'))

  return (
    <StyledErrorPage className={classes.root}>
      <Header />
      <a id='home' href='#home'>
        <p style={{ display: 'hidden' }} />
      </a>
      <div className={classes.landing}>
        <div className={classes.globeContainer}>
          <GlobeSVG className={classes.globe} />
        </div>

        <div className={classes.formContainer}>
          <div className={classes.title}>{header}</div>
          <div>{description}</div>
        </div>
      </div>
      <Footer />
    </StyledErrorPage>
  )
}

export default ErrorPage
