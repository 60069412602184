import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useAbility } from '@/lib/authorization'
import { getTypeName } from '@/lib/entity'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import DetailsPane from './DetailsPane'
import ProfilePane, { AdverseMediaPane } from './ProfilePane'
import LocationsPane from './LocationsPane'
import CatchAllPane from './CatchAllPane'
import { ListEntry } from 'proto-js'

const PREFIX = 'EntityCard'

const classes = {
  flex: `${PREFIX}-flex`,
  gridBorderBottom: `${PREFIX}-gridBorderBottom`,
  gridBorderTop: `${PREFIX}-gridBorderTop`,
  gridBorderLeft: `${PREFIX}-gridBorderLeft`
}

const ContentGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(6)
}))

const StyledEntityCardContent = styled('div', {
  shouldForwardProp: (props) => props !== 'showWatermark'
})<{ showWatermark: boolean }>(({ showWatermark, theme }) => ({
  width: '100%',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  [`.highlight`]: {
    color: theme.palette.error.main
  },
  [`& .${classes.flex}`]: {
    minHeight: '100%',
    flex: 1
  },
  [`& .${classes.gridBorderTop}`]: {
    borderTop: `1px solid ${theme.palette.divider}`
  },
  [`& .${classes.gridBorderBottom}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  [`& .${classes.gridBorderLeft}`]: {
    borderLeft: `1px solid ${theme.palette.divider}`
  },
  ...(showWatermark && {
    backgroundImage: `url(${import.meta.env.VITE_APP_CDN_PATH}/media/watermark_small.svg)`,
    backgroundSize: '33%',
    backgroundPosition: 'right',
    backgroundRepeat: 'repeat'
  })
}))

interface EntityCardContentProps {
  entity: typeof ListEntry
  listSourceQueryString: string
}

const EntityCardContent: React.FC<EntityCardContentProps> = ({
  entity,
  listSourceQueryString = ''
}) => {
  const [pane, setPane] = useState(0)
  const ability = useAbility()
  const showWatermark = ability.can('view', 'Watermark')

  const handlePaneChange = (e: React.SyntheticEvent, newPane: number) => {
    setPane(newPane)
  }

  return (
    <StyledEntityCardContent
      showWatermark={showWatermark}
      data-cy='entity-card'
    >
      <Grid
        container
        spacing={0}
        justifyContent='flex-start'
        alignContent='flex-start'
      >
        <Grid item xs={2} className={classes.gridBorderBottom} />

        <Grid
          item
          xs={10}
          className={`${classes.gridBorderLeft} ${classes.gridBorderBottom}`}
        >
          <Tabs
            indicatorColor='secondary'
            textColor='inherit'
            variant='fullWidth'
            value={pane}
            onChange={handlePaneChange}
            centered
          >
            <Tab label='Profile' />
            <Tab label='Locations' />
            <Tab label='Additional Information' />
          </Tabs>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.flex}
        justifyContent='flex-start'
      >
        <Grid item xs={2}>
          <DetailsPane isMedia={false} entity={entity} />
        </Grid>

        <Grid item xs={10} className={classes.gridBorderLeft}>
          {pane === 0 && (
            <ProfilePane
              entity={entity}
              listSourceQueryString={listSourceQueryString}
            />
          )}
          {pane === 1 && <LocationsPane entity={entity} />}
          {pane === 2 && <CatchAllPane entity={entity} />}
        </Grid>
      </Grid>
    </StyledEntityCardContent>
  )
}

export const AdverseMediaContent: React.FC<{ entity: typeof ListEntry }> = ({
  entity
}) => (
  <StyledEntityCardContent showWatermark={false} data-cy='entity-card'>
    <Grid
      container
      spacing={0}
      className={`${classes.flex} ${classes.gridBorderTop}`}
      justifyContent='flex-start'
    >
      <Grid item xs={2}>
        <DetailsPane isMedia entity={entity} />
      </Grid>

      <ContentGrid item xs={10} className={classes.gridBorderLeft}>
        <AdverseMediaPane
          title={entity.getTitle()}
          snippet={entity.getSnippet()}
          article={entity.getArticle()}
          url={entity.getUrl()}
        />
      </ContentGrid>
    </Grid>
  </StyledEntityCardContent>
)

interface EntityCardProps {
  entity?: typeof ListEntry
  listSourceQueryString: string
  variant?: 'card' | 'plain'
}

const EntityCard: React.FC<EntityCardProps> = ({
  entity,
  listSourceQueryString,
  variant = 'card'
}) => {
  const primName = entity?.getName()?.getFullName()
  const entityType = getTypeName(entity)
  const isMedia = entityType === 'media'

  const content = isMedia ? (
    <AdverseMediaContent entity={entity} />
  ) : (
    <EntityCardContent
      listSourceQueryString={listSourceQueryString}
      entity={entity}
    />
  )

  if (variant === 'card') {
    return (
      <Card>
        <CardHeader title={primName} />
        <CardContent>{content}</CardContent>
      </Card>
    )
  }

  return content
}

export default EntityCard
