import React from 'react'

import { GRPCContext, IGRPCContext } from '@/lib/gRPC/grpcContext'

export const useGRPC = (): IGRPCContext => {
  const grpcContext = React.useContext(GRPCContext)

  if (grpcContext === undefined) {
    throw new Error('useGRPC must be used within a gRPCProvider')
  }

  return grpcContext
}

export default useGRPC
