import React from 'react'
import DisplayedRowsDisplay from './DisplayedRowsDisplay'
import TablePagination from '@mui/material/TablePagination'

type TPaginatorProps = {
  count: number
  page: number
  rowsPerPage: number
  totalHits: number
  onPageChange: (
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void
  onRowsPerPageChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
}

export const ROWS_PER_PAGE_OPTIONS = [25, 50]

const Paginator: React.FC<TPaginatorProps> = ({
  count,
  page,
  rowsPerPage,
  totalHits,
  onPageChange,
  onRowsPerPageChange,
  ...rest
}) => (
  <TablePagination
    data-cy='table-paginator'
    count={count}
    component='div'
    labelDisplayedRows={(labelProps) => (
      <DisplayedRowsDisplay totalHits={totalHits} {...labelProps} />
    )}
    page={page}
    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
    rowsPerPage={rowsPerPage}
    onPageChange={onPageChange}
    onRowsPerPageChange={onRowsPerPageChange}
    {...rest}
  />
)

export default Paginator
