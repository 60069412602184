/**
 * component DateRangePicker
 */

import React from 'react'

import Grid from '@mui/material/Grid'
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { isValid } from 'date-fns'
import ConnectedDatePicker, { OnChangeFunc } from './ConnectedDatePicker'

export const isDateValid = (date: Date | null): boolean =>
  !date || isValid(date)

interface IProps extends DatePickerProps<Date> {
  startDate: Date | null
  endDate: Date | null
  startLabel?: string
  endLabel?: string
  onStartDateChange: OnChangeFunc
  onEndDateChange: OnChangeFunc
  clearable?: boolean
}

const DateRangePicker: React.FC<IProps> = ({
  startDate,
  endDate,
  startLabel = '',
  endLabel = '',
  onStartDateChange,
  onEndDateChange,
  clearable = true,
  ...datePickerProps
}) => {
  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12} md={6}>
        <ConnectedDatePicker
          name={startLabel}
          connected={false}
          label={startLabel}
          value={startDate}
          onValueChange={(newVal) => {
            onStartDateChange(newVal)
          }}
          maxDate={endDate ? endDate : undefined}
          clearable={clearable}
          disableFuture
          {...datePickerProps}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ConnectedDatePicker
          name={endLabel}
          connected={false}
          label={endLabel}
          value={endDate}
          onValueChange={(newVal) => {
            onEndDateChange(newVal)
          }}
          minDate={startDate ? startDate : undefined}
          clearable={clearable}
          disableFuture
          {...datePickerProps}
        />
      </Grid>
    </Grid>
  )
}

export default DateRangePicker
