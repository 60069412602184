import { useCallback } from 'react'
import { TGetListSourcesResponse } from '@/lib/hooks/api/useGetListSourcesRPC'
import { useListSources } from '@/lib/features/referenceData'

type TCategoryMap = { [key: string]: number[] }
type TListSourceMap = { [key: string]: number[] }
type TListMap = { [key: string]: number }

/**
 * getNameToListIDMaps returns three objects mapping list source categories, list sources, and
 * lists to the specific list IDs they cover.
 *
 * @param listSourcesData - Information for all list sources and lists.
 * @returns - A list of mappings to list IDs.
 */
const getNameToListIDMaps = (
  listSourcesData: TGetListSourcesResponse
): [TCategoryMap, TListSourceMap, TListMap] => {
  const categoryMap: TCategoryMap = {}
  const listSourceMap: TListSourceMap = {}
  const listMap: TListMap = {}
  listSourcesData.forEach((listSource) => {
    if (!(listSource.category.name in categoryMap)) {
      categoryMap[listSource.category.name] = []
    }

    if (!(listSource.name in listSourceMap)) {
      listSourceMap[listSource.name] = []
    }

    listSource.lists.forEach((list) => {
      categoryMap[listSource.category.name].push(list.id)
      listSourceMap[listSource.name].push(list.id)
      listMap[`${listSource.name} | ${list.name}`] = list.id // List names are not unique
    })
  })
  return [categoryMap, listSourceMap, listMap]
}

const allListsSelected = (
  listSourceCategories: string[] | undefined,
  listSources: { [key: string]: string[] } | undefined
) =>
  (listSourceCategories === undefined || listSourceCategories.length === 0) &&
  (listSources === undefined || Object.keys(listSources).length === 0)

const flattenLists = (
  listSourceName: string,
  listNames: string[],
  listSourceMap: TListSourceMap,
  listMap: TListMap
) =>
  listNames.length === 0
    ? listSourceMap[listSourceName]
    : listNames.flatMap(
        (listName) => listMap[`${listSourceName} | ${listName}`]
      )

export const unravel = (
  listSourceCategories: string[] | undefined,
  listSources: { [key: string]: string[] } | undefined,
  listSourcesData: TGetListSourcesResponse
): number[] => {
  const [categoryMap, listSourceMap, listMap] = getNameToListIDMaps(
    listSourcesData
  )

  if (allListsSelected(listSourceCategories, listSources)) {
    return Object.values(listMap)
  }

  const listIdsSet = new Set([
    ...(listSourceCategories || []).flatMap((c) => categoryMap[c]),
    ...Object.entries(
      listSources || {}
    ).flatMap(([listSourceName, listNames]) =>
      flattenLists(listSourceName, listNames, listSourceMap, listMap)
    )
  ])
  return [...listIdsSet]
}

/**
 * useUnravelLists translates a user selection of list source names, categories, and/or specific
 * lists into a coresponding flat array of list IDs.
 *
 * @returns {number[]}
 */
export const useUnravelLists = (): ((
  listSourceCategories: string[] | undefined,
  listSources: { [key: string]: string[] } | undefined
) => number[]) => {
  const listSourcesQuery = useListSources()

  const unravelLists = useCallback(
    (listSourceCategories, listSources) => {
      if (listSourcesQuery.isSuccess) {
        return unravel(listSourceCategories, listSources, listSourcesQuery.data)
      }
      return []
    },
    [listSourcesQuery]
  )

  return unravelLists
}
