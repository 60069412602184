import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'
import { useQueryClient, QueryClient } from 'react-query'

import PDFReport from './Pdf/Report'
import { useBranding } from '@/lib/hooks'
import { useGetScreenedEntityAuditsRPC } from '@/lib/hooks/api'
import { TGetListSourcesResponse } from '@/lib/hooks/api/useGetListSourcesRPC'
import { useListSources } from '@/lib/features/referenceData'
import { AuditTrailData, ListSourcesState, ListSourceState } from './types'
import {
  listSourceCategoryRanks,
  listSourceRanks,
  sortFunc
} from '@/components/lib/ListSourcesDropDown/'

import { trackEvent } from '@/lib/analytics/ga4'
import { reportDate } from '@/components/lib/Reporting/utils'

import ExportButton from './ExportButton'
import {
  TAdHocScreenData,
  TPersistedAdHocScreenData,
  TScreenedEntity,
  TPersistedScreenedEntity
} from '@/lib/features/Screening'
import { auditQueryKeys } from '@/lib/features/audit'

/**
 * calculateListSourcesState processes screen results to come up with information about which list
 * source categories, list sources, and lists were screened and which of them had hits. This
 * function is primarily concerned with the complexity of deducing this information because
 * categories, list sources, and lists can be explicitly selected or implicitly selected when
 * looking at just the query params. For example, selecting the sanctions category implies all list
 * sources and lists under that category. Conversely, selecting a list source implies selecting it's
 * category.
 *
 * @param {TGetListSourcesResponse} listSources
 * @param {TAdHocScreenData} screenData
 * @returns {ListSourcesState}
 */
export const calculateListSourcesState = (
  listSources: TGetListSourcesResponse,
  screenData: TAdHocScreenData
): ListSourcesState => {
  // Build tree of categories, list sources, and lists state.
  let listSourcesState: ListSourcesState = new Map()

  for (const listSource of listSources) {
    const categoryName = listSource.category.name
    let categoryState = listSourcesState.get(categoryName)
    if (categoryState === undefined) {
      categoryState = {
        selected: false,
        hasHits: false,
        listSources: new Map()
      }
      listSourcesState.set(categoryName, categoryState)
    }

    const newListSourceData: ListSourceState = {
      selected: false,
      hasHits: false,
      lists: new Map()
    }

    for (const list of listSource.lists) {
      newListSourceData.lists.set(list.name, false)
    }

    // Sort lists.
    newListSourceData.lists = new Map(
      [...newListSourceData.lists.entries()].sort((a, b) =>
        sortFunc({})(a[0], b[0])
      )
    )

    categoryState.listSources.set(listSource.name, newListSourceData)
  }

  // Sort categories.
  listSourcesState = new Map(
    [...listSourcesState.entries()].sort((a, b) =>
      sortFunc(listSourceCategoryRanks)(a[0], b[0])
    )
  )

  // Sort list sources.
  for (const categoryState of listSourcesState.values()) {
    categoryState.listSources = new Map(
      [...categoryState.listSources.entries()].sort((a, b) =>
        sortFunc(listSourceRanks)(a[0], b[0])
      )
    )
  }

  // Deduce selected categories, list sources, and lists.
  const query = screenData.screen.parameters
  const selectedCategories = new Set(query.listSourceCategories)
  const selectedListSources = new Map<string, Set<string>>(
    Object.entries(query.listSources || new Array<string>()).map(([k, v]) => [
      k,
      new Set(v)
    ])
  )

  const all = selectedCategories.size === 0 && selectedListSources.size === 0

  for (const [categoryName, categoryState] of listSourcesState.entries()) {
    categoryState.selected = all || selectedCategories.has(categoryName)

    let categoryImplicitlySelected = false
    for (const [
      listSourceName,
      listSourceState
    ] of categoryState.listSources.entries()) {
      listSourceState.selected =
        categoryState.selected || selectedListSources.has(listSourceName)

      const selectedLists = selectedListSources.get(listSourceName)
      const listImplicitlySelected =
        listSourceState.selected && !(selectedLists && selectedLists.size > 0)
      for (const listName of listSourceState.lists.keys()) {
        const listExplicitelySelected = Boolean(selectedLists?.has(listName))
        listSourceState.lists.set(
          listName,
          listImplicitlySelected || listExplicitelySelected
        )
      }

      categoryImplicitlySelected =
        categoryImplicitlySelected || listSourceState.selected
    }

    categoryState.selected =
      categoryState.selected || categoryImplicitlySelected
  }

  // Mark categories and list sources that have hits.
  for (const screenEntity of screenData.entities) {
    for (const hit of screenEntity.hits) {
      const listSource = hit.protobuf.getListEntry().getListSource()
      const categoryName = listSource.getCategory().getName()
      const listSourceName = listSource.getName()
      const listSourceCategoryState = listSourcesState.get(categoryName)

      if (listSourceCategoryState !== undefined) {
        const currentListSourceState = listSourceCategoryState.listSources.get(
          listSourceName
        )
        if (
          listSourceCategoryState !== undefined &&
          currentListSourceState !== undefined
        ) {
          listSourceCategoryState.hasHits = true
          currentListSourceState.hasHits = true
        }
      }
    }
  }

  return listSourcesState
}

/**
 * getAuditTrailData retreives audit trail data for the entire screen from the react-query cache.
 * This function is used to inject the audit trail data into the ReactPDF component where we
 * cannot use React hooks.
 *
 * This function interactis with the react-query cache via the non-hook functions provided by the
 * QueryClient.
 *
 * @param queryClient
 * @param rpc
 * @param screenEntityIDs
 * @returns
 */
const getAuditTrailData = async (
  queryClient: QueryClient,
  rpc: ReturnType<typeof useGetScreenedEntityAuditsRPC>,
  screenData: TAdHocScreenData | TPersistedAdHocScreenData
) => {
  const screenedEntityIDs: number[] = []
  screenData.entities.forEach(
    (screenedEntity: TScreenedEntity | TPersistedScreenedEntity) => {
      if ('id' in screenedEntity) {
        screenedEntityIDs.push(screenedEntity.id)
      }
    }
  )

  const hitAuditTrails: AuditTrailData = {}
  for (const screenedEntityID of screenedEntityIDs) {
    const queryKey = auditQueryKeys.logs(screenedEntityID)
    const screenedEntityAuditTrail = await queryClient.fetchQuery(
      queryKey,
      () => rpc(screenedEntityID),
      { staleTime: Infinity }
    )
    screenedEntityAuditTrail.forEach((h) => {
      hitAuditTrails[h.id] = h
    })
  }

  return hitAuditTrails
}

export interface ButtonProps {
  screenData: TAdHocScreenData | undefined
  disabled?: boolean
  fullWidth?: boolean
}

export const GenerateReportButton: React.FC<ButtonProps> = ({
  screenData,
  disabled = false,
  fullWidth = false
}) => {
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState(false)
  const listSources = useListSources()
  const listSourcesState = React.useMemo(() => {
    if (
      listSources.status === 'success' &&
      screenData !== undefined &&
      screenData.entities.length > 0
    ) {
      return calculateListSourcesState(listSources.data, screenData)
    }
    return new Map()
  }, [listSources.status, listSources.data, screenData])

  const getScreenedEntityAuditsRPC = useGetScreenedEntityAuditsRPC()

  const title = `Screening Report ${reportDate()}`
  const { user } = useAuth0()
  const branding = useBranding()

  // Called when clicking the export button.
  // Display a loading icon while we generate the PDF, and disable the button
  // https://github.com/diegomura/react-pdf/issues/736
  const generatePdfDocument = async (auditTrailData: AuditTrailData) => {
    if (screenData === undefined) {
      return
    }

    setLoading(true)

    const startTime = new Date().getTime()
    const blob = await pdf(
      <PDFReport
        screenData={screenData}
        listSourcesState={listSourcesState}
        auditTrailData={auditTrailData}
        title={title}
        user={user}
        branding={branding}
      />
    ).toBlob()

    saveAs(blob, title)

    const endTime = new Date().getTime()

    trackEvent('file_download', {
      subject: 'workflow',
      type: 'report',
      gen_time: endTime - startTime
    })

    setLoading(false)
  }

  const handleClick = async () => {
    if (screenData !== undefined) {
      const auditTrailData = await getAuditTrailData(
        queryClient,
        getScreenedEntityAuditsRPC,
        screenData
      )
      await generatePdfDocument(auditTrailData)
    }
  }

  return (
    <ExportButton
      disabled={disabled}
      loading={loading}
      onClick={handleClick}
      fullWidth={fullWidth}
    />
  )
}

export default GenerateReportButton
