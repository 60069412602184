import { CRONExpression, Schedule, ScheduledJob, Screen } from 'proto-js'
import { TSchedule, TScheduledJob } from '@/lib/features/job'
import { TPersistedScreen } from '@/lib/features/Screening'

export const scheduleToProtobuf = (schedule: TSchedule): typeof Schedule => {
  const cronPB = new CRONExpression()
  cronPB.setMinute(schedule.cron.minute.toString())
  cronPB.setHour(schedule.cron.hour.toString())
  cronPB.setDayOfMonth(schedule.cron.dayOfMonth)
  cronPB.setMonth(schedule.cron.month)
  cronPB.setDayOfWeek(schedule.cron.dayOfWeek)

  const schedulePB = new Schedule()
  schedulePB.setCron(cronPB)
  schedulePB.setTimezone(schedule.timezone)
  return schedulePB
}

export const scheduleFromProtobuf = (
  schedulePB: typeof Schedule
): TSchedule => {
  const cronPB = schedulePB.getCron()
  return {
    cron: {
      minute: parseInt(cronPB.getMinute()),
      hour: parseInt(cronPB.getHour()),
      dayOfMonth: cronPB.getDayOfMonth(),
      month: cronPB.getMonth(),
      dayOfWeek: cronPB.getDayOfWeek()
    },
    timezone: schedulePB.getTimezone()
  }
}

export const screenFromProtobuf = (
  screenPB: typeof Screen
): TPersistedScreen => ({
  id: screenPB.getId(),
  jobID: screenPB.getJobId(),
  screenType: screenPB.getScreenType(),
  ranAt: screenPB.getRanAt().toDate(),
  listIDs: screenPB.getListIdsList(),
  scoreThreshold: screenPB.getMinScore(),
  weakAKAs: !screenPB.getExcludeWeakAkas(),
  dobMonthsRange: screenPB.getDobMonthsRange(),
  screenedEntitiesCount: screenPB.getScreenedEntitiesCount(),
  screenedEntitiesFlaggedCount: screenPB.getScreenedEntitiesFlaggedCount(),
  reviewCompleted: screenPB.getReviewCompleted()
})

export const jobFromProtobuf = (
  jobPB: typeof ScheduledJob
): Persisted<TScheduledJob> => ({
  id: jobPB.getId(),
  name: jobPB.getName(),
  schedule: scheduleFromProtobuf(jobPB.getSchedule()),
  filename: jobPB.getFilename(),
  listSourceCategoryIDs: jobPB.getListSourceCategoryIdsList(),
  listSourceIDs: jobPB.getListSourceIdsList(),
  listIDs: jobPB.getListIdsList(),
  minScore: jobPB.getMinScore(),
  weakAKAs: !jobPB.getExcludeWeakAkas(),
  dobMonthsRange: jobPB.getDobMonthsRange()
})
