import React from 'react'
import { geoPath, geoOrthographic } from 'd3-geo'
import { feature } from 'topojson-client'
import { Topology } from 'topojson-specification'

import worldDataTopo from '@/media/world-110m.json'

const RESET_TIMEOUT = 75

const topoData = (worldDataTopo as unknown) as Topology
const worldData = feature(topoData, 'countries')

type TGlobeSVG = {
  className: string
}

const GlobeSVG: React.FC<TGlobeSVG> = ({ className }) => {
  const targetRef = React.useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 })
  const [rotation] = React.useState(0)
  const isMounted = React.useRef(true)

  let movementTimer = 0
  const testDimensions = () => {
    if (targetRef.current && isMounted.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      })
    }
  }

  React.useLayoutEffect(() => {
    testDimensions()
    return () => {
      isMounted.current = false
    }
  }, [])

  window.addEventListener('resize', () => {
    clearInterval(movementTimer)
    movementTimer = window.setTimeout(testDimensions, RESET_TIMEOUT)
  })

  const proj = geoOrthographic()
    .fitSize([dimensions.width, dimensions.height], worldData)
    .rotate([rotation, 5, -25])

  const geoGenerator = geoPath().projection(proj)
  const pathString = geoGenerator(worldData) as string

  // window.requestAnimationFrame(() => {
  //   if (isMounted.current) {
  //     setRotation(rotation + 0.05)
  //   }
  // })

  return (
    <div ref={targetRef} className={className}>
      <svg width='100%' height='100%' style={{ overflowY: 'visible' }}>
        <path
          d={pathString}
          stroke='#FFFFFF'
          fill='#EFEFEFEF'
          strokeWidth={1}
        />
      </svg>
    </div>
  )
}

export default GlobeSVG
