import React from 'react'
import Typography from '@mui/material/Typography'
import { List, ListEntry } from 'proto-js'

interface ListSourceDisplayProps {
  listEntry?: typeof ListEntry
}

const ListSourceDisplay: React.FC<ListSourceDisplayProps> = ({ listEntry }) => {
  let outputString = ''

  if (listEntry === undefined) {
    outputString = ' on [list source], '
  } else {
    const listSourceName = listEntry?.getListSource()?.getName()
    const listNames = listEntry
      .getListSource()
      .getListsList()
      .map((ls: typeof List) => ls.getName())
      .join(', ')

    if (listSourceName === listNames) {
      outputString = ` on ${listSourceName}, `
    } else {
      outputString = ` on ${listSourceName} - ${listNames}, `
    }
  }

  return (
    <Typography variant='body2' display='inline'>
      {outputString}
    </Typography>
  )
}

export default ListSourceDisplay
