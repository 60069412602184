import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import {
  useGetListEntriesDataRPC,
  TGetListEntriesDataResponse
} from '@/lib/hooks/api/useGetListEntriesDataRPC'
import { reportQueryKeys } from './queryKeys'
import { TReportName } from './types'

type TQueryOptions = Omit<
  UseQueryOptions<
    TGetListEntriesDataResponse,
    (string | Record<string, string>)[],
    TGetListEntriesDataResponse,
    (string | Record<string, string>)[]
  >,
  'queryKey' | 'queryFn'
>

/**
 * useGetListEntriesData is a hook for retrieving reports of a given type.
 *
 * @param {TQueryOptions} options - react-query query options.
 * @returns {UseQueryResult<TGetListEntriesDataResponse>} - The query result..
 */
export const useGetListEntriesData = (
  reportName: TReportName,
  options?: TQueryOptions
): UseQueryResult<TGetListEntriesDataResponse> => {
  const rpc = useGetListEntriesDataRPC()

  return useQuery(
    reportQueryKeys.report(reportName),
    () => rpc({ queryName: reportName }),
    {
      refetchOnWindowFocus: false,
      ...options
    }
  )
}
