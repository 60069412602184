import { QueryClient } from 'react-query'
import { screenQueryKeys } from './queryKeys'
import {
  TAdHocScreenData,
  TPersistedAdHocScreenData,
  TScreenData
} from './types'

const queryFilters = { exact: false, active: true, inactive: false }

/**
 * getAdHocScreenDataFromCache returns screen data from the react-query cache for ad hoc screen
 * data i.e. advanced searches and batch searches dcirectly from the app.
 *
 * @param {QueryClient} queryClient - The react-query client object.
 * @returns {TAdHocScreenData | TPersistedAdHocScreenData | undefined} - The screen data object.
 */
export const getAdHocScreenDataFromCache = (
  queryClient: QueryClient
): TAdHocScreenData | TPersistedAdHocScreenData | undefined =>
  queryClient.getQueryData<TAdHocScreenData | TPersistedAdHocScreenData>(
    screenQueryKeys.adHoc,
    queryFilters
  )

/**
 * findScreenDataFromCache looks for screen data with an ID that has been fetched from the
 * back-end. If it doesn't find a screen ID there it returns the ad hoc screen data. It
 * returns the query that was used for fetching the data.
 *
 * @param {QueryClient} queryClient - The react-query client object.
 * @param {number} screenID - The screen ID to look for.
 * @returns {screenData: TScreenData | undefined, queryKey: (string | Record<string, number>)[]} - An object containing the screen data and the query key used.
 */
export const findScreenDataFromCache = (
  queryClient: QueryClient,
  screenID: number
): {
  screenData: TScreenData | undefined
  queryKey: (string | Record<string, number>)[]
} => {
  let queryKey = screenQueryKeys.persisted(screenID)
  let screenData = queryClient.getQueryData<TScreenData>(queryKey, queryFilters)
  if (screenData === undefined) {
    queryKey = screenQueryKeys.adHoc
    screenData = queryClient.getQueryData<TScreenData>(queryKey, queryFilters)
  }
  return { screenData: screenData, queryKey: queryKey }
}

/**
 *  setScreenDataCache set the screen data object in the query cache at the specified query key.
 *
 * @param {QueryClient} queryClient - The react-query client object.
 * @param {TScreenData} screenData - The screen data object.
 * @param {(string | Record<string, number>)[]} queryKey - The query key to use.
 */
export const setScreenDataCache = (
  queryClient: QueryClient,
  screenData: TScreenData,
  queryKey: (string | Record<string, number>)[]
): void => {
  queryClient.setQueriesData(
    {
      queryKey: queryKey,
      ...queryFilters
    },
    screenData
  )
}
