import { TScreenedEntityStatus, THitClassification, TScreenType } from './types'

export const screenedEntityStatusLabels: Record<
  TScreenedEntityStatus,
  string
> = {
  incomplete: 'Review Incomplete',
  cleared: 'No Matches',
  flagged: 'True Positive',
  resolved: 'False Positive',
  wip: 'Need More Information'
}

export const hitClassificationLabels: Record<THitClassification, string> = {
  'true-positive': 'True Positive',
  'false-positive': 'False Positive',
  'need-more-info': 'Need More Information'
}

export const screenTypeLabels: Record<TScreenType, string> = {
  advanced: 'Advanced',
  batch: 'Batch',
  scheduled: 'Scheduled'
}
