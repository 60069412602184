import React from 'react'

import { styled } from '@mui/material/styles'
import { useGetScheduledJobs } from '@/lib/features/job'
import { ContentWithLoader } from '@/components/lib/Loader'

import PageHeader from '@/components/App/ScheduledScreeningPage/lib/PageHeader'
import JobsTable from './JobsTable'
import Typography from '@mui/material/Typography'

const NoJobs = styled(Typography)(() => ({
  textAlign: 'center',
  paddingTop: 15
}))

const JobListPage: React.FC = () => {
  const { data, isFetching } = useGetScheduledJobs()
  const jobs = data?.jobs

  return (
    <>
      <PageHeader />
      <ContentWithLoader
        showLoader
        isFetching={isFetching}
        data={jobs}
        emptyMessage={<NoJobs variant='h5'>No Scheduled Screens Found</NoJobs>}
      >
        {jobs === undefined ? null : <JobsTable data={jobs} />}
      </ContentWithLoader>
    </>
  )
}

export default JobListPage
