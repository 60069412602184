import {
  WEAK_AKAS_DEFAULT,
  SLIDER_DEFAULT,
  FUZZY_DOB_TOGGLE_DEFAULT,
  FUZZY_DOB_DEFAULT_MONTHS_RANGE,
  DEFAULT_DOB_MONTHS_RANGE
} from '@/lib/constants/search'
import { usePostScheduledJob } from '@/lib/features/job'
import {
  getDisabledDropDownCategories,
  useListSourcesDropDownOptions,
  selectedListSourcesOptionsIDs,
  translateListSourceSelections
} from '@/components/lib/ListSourcesDropDown'
import { useNotification } from '@/lib/notification'
import JobForm, { IFormValues } from './JobForm'
import { useHistory } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import { TServerError } from '@/lib/gRPC'
import { handleServerErrors, validateFn } from './validation'
import { trackEvent } from '@/lib/analytics/ga4'
import { useAbility } from '@/lib/authorization'

export const CreateJobForm = (): JSX.Element => {
  const ability = useAbility()
  const listSourcesDropDownOptions = useListSourcesDropDownOptions(
    undefined,
    getDisabledDropDownCategories((subject: string) =>
      ability.cannot('manage', 'Jobs', subject)
    )
  )

  const postScheduledJob = usePostScheduledJob()
  const setNotification = useNotification()
  const history = useHistory()

  const showNotificationAndRedirect = () => {
    setNotification({
      message: 'Scheduled screen created',
      variant: 'success'
    })

    history.push('/jobs')
  }

  const handleSubmit = (
    values: IFormValues,
    actions: FormikHelpers<IFormValues>
  ) => {
    if (!values.schedule || !values.screenEntities) {
      return
    }
    trackEvent('create_scheduled_job_submitted')
    const listSourceSelections = translateListSourceSelections(
      selectedListSourcesOptionsIDs(listSourcesDropDownOptions.options)
    )
    postScheduledJob.mutate(
      {
        job: {
          name: values.name,
          schedule: values.schedule,
          filename: values.screenEntities.name,
          ...listSourceSelections,
          minScore: values.scoreThreshold / 100,
          weakAKAs: values.weakAKAs,
          dobMonthsRange: values.fuzzyDOB
            ? FUZZY_DOB_DEFAULT_MONTHS_RANGE
            : DEFAULT_DOB_MONTHS_RANGE
        },
        file: values.screenEntities
      },
      {
        onSuccess: showNotificationAndRedirect,
        onError: (error: TServerError) => handleServerErrors(error, actions)
      }
    )
  }

  return (
    <JobForm
      initialValues={{
        name: '',
        schedule: null,
        scoreThreshold: SLIDER_DEFAULT,
        screenEntities: null,
        weakAKAs: WEAK_AKAS_DEFAULT,
        fuzzyDOB: FUZZY_DOB_TOGGLE_DEFAULT
      }}
      listSourcesDropDownOptions={listSourcesDropDownOptions}
      onSubmit={handleSubmit}
      validate={validateFn(false)}
    />
  )
}
