import { JavaScriptValue } from 'google-protobuf/google/protobuf/struct_pb.js'
import isURL from 'validator/lib/isURL'

import ListItem from '@mui/material/ListItem'

const urlConfig = {
  protocols: ['http', 'https', 'ftp'],
  require_protocol: true,
  allow_underscores: true,
  allow_trailing_dot: true
}

const CatchAllValue = ({ value }: { value: JavaScriptValue }): JSX.Element => (
  <ListItem>
    {typeof value === 'string' && isURL(value, urlConfig) ? (
      <a href={value as string} target='_blank' rel='noreferrer'>
        {value as string}
      </a>
    ) : (
      <>{value}</>
    )}
  </ListItem>
)

export default CatchAllValue
