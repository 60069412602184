import {
  TScreenedEntity,
  TPersistedScreenedEntity,
  TAdHocScreen
} from '@/lib/features/Screening'
import { useGRPC } from '@/lib/hooks'
import ProtoTimestamp from 'google-protobuf/google/protobuf/timestamp_pb.js'
import { HitRecord, PostScreenRequest, PostScreenResponse } from 'proto-js'
import { useUnravelLists } from '@/lib/features/referenceData'

export type TPostScreenParams = {
  screen: TAdHocScreen
  screenedEntity: TScreenedEntity
}

export const prepareRPCRequest = (
  screen: TAdHocScreen,
  screenedEntity: TScreenedEntity,
  unravel: ReturnType<typeof useUnravelLists>
): Promise<typeof PostScreenRequest> => {
  const request = new PostScreenRequest()
  request.setScreenType(screen.screenType)
  request.setRanAt(ProtoTimestamp.Timestamp.fromDate(screen.ranAt))
  request.setListIdsList(
    unravel(
      screen.parameters.listSourceCategories,
      screen.parameters.listSources
    )
  )
  request.setMinScore(screen.parameters.scoreThreshold)
  request.setExcludeWeakAkas(screen.parameters.weakAKAs)
  request.setName(screenedEntity.name)
  request.setIdNumber(screenedEntity.idNumber)
  request.setLocation(screenedEntity.location)
  if (screenedEntity.dob) {
    request.setDob(ProtoTimestamp.Timestamp.fromDate(screenedEntity.dob))
  }
  request.setReferenceId(screenedEntity.referenceId)
  request.setTotalHits(screenedEntity.totalHits)
  request.setHitsList(screenedEntity.hits.map((hit) => hit.protobuf))
  return request
}

export type TPostScreenResponse = {
  screenId: number
  screenedEntity: TPersistedScreenedEntity
}

export const prepareRPCResponse = (
  resp: typeof PostScreenResponse
): TPostScreenResponse => ({
  screenId: resp.getScreenId(),
  screenedEntity: {
    id: resp.getScreenedEntityId(),
    name: resp.getName(),
    idNumber: resp.getIdNumber(),
    location: resp.getLocation(),
    dob: resp.getDob()?.toDate(),
    referenceId: resp.getReferenceId(),
    totalHits: resp.getTotalHits(),
    hits: resp.getHitsList().map((hitRecord: typeof HitRecord) => ({
      id: hitRecord.getHitId(),
      protobuf: hitRecord.getHit()
    })),
    status: resp.getTotalHits() === 0 ? 'cleared' : 'incomplete'
  }
})

type TPostScreenRPC = (
  params: TPostScreenParams
) => Promise<TPostScreenResponse>

/**
 *  usePostScreenRPC provides an async function to invoke the PostScreen RPC.
 *
 * @returns {TPostScreenRPC} - An async function to invoke the RPC.
 */
export const usePostScreenRPC = (): TPostScreenRPC => {
  const { workflowPromiseService } = useGRPC()
  const unravel = useUnravelLists()
  return async (params: TPostScreenParams) => {
    const req = prepareRPCRequest(params.screen, params.screenedEntity, unravel)
    return workflowPromiseService.postScreen(req, {}).then(prepareRPCResponse)
  }
}

export default usePostScreenRPC
