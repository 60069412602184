/**
 * Renders the given component as a link that either redirects to a route or shows a teaser
 */

import React from 'react'
import { LinkProps as RouterLinkProps } from 'react-router-dom'

import { LinkProps } from '@mui/material/Link'

import { SupportDialog } from '@/components/lib/SupportDialog'
import { Can } from '@/lib/authorization'
import { Actions, GenericSubjects } from '@/lib/authorization/ability'

export interface TeaserLinkProps {
  action: Actions
  subject: GenericSubjects
  component: (props: LinkProps) => JSX.Element
  routerLinkProps: RouterLinkProps
  dialogBody?: JSX.Element
}

const TeaserLink: React.FC<TeaserLinkProps> = ({
  action,
  subject,
  component,
  dialogBody,
  routerLinkProps,
  children
}): JSX.Element => {
  return (
    <Can I={action} a={subject} passThrough>
      {(allowed: boolean) =>
        allowed ? (
          <>{component({ ...routerLinkProps, children: children })}</>
        ) : (
          <SupportDialog
            buttonComponent={(openModal) => (
              <>{component({ onClick: openModal, children: children })}</>
            )}
          >
            {dialogBody}
          </SupportDialog>
        )
      }
    </Can>
  )
}

export default TeaserLink
