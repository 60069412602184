import React from 'react'
import * as grpcWeb from 'grpc-web'
import { useGRPC } from '@/lib/hooks'

import {
  File,
  BatchScreenEntitiesRequest,
  BatchScreenEntitiesResponse,
  Hit,
  List,
  ListSource,
  WorkflowServicePromiseClient,
  ScreenEntityResponse
} from 'proto-js'
import { THit, TScreenedEntity } from '@/lib/features/Screening'

// IScreenEntitiesBatchParams are the input parameters for a batch screen.
export interface IScreenEntitiesBatchParams {
  file: File
  listSourceCategories?: string[]
  listSources?: { [key: string]: string[] }
  scoreThreshold: number
  weakAKAs: boolean
  dobMonthsRange: number
}

// TBatchScreenEntitiesRPC encapsulates a set of functions for interacting with the batch screen
// stream response.
export type TBatchScreenEntitiesRPC = {
  invoke: (p: IScreenEntitiesBatchParams) => void
  reset: () => void
  cancel: () => void
}

// TBatchScreenEntitiesRPCCallbacks encapsulates a set of callbacks passed to the batch screen
// hook.
export type TBatchScreenEntitiesRPCCallbacks = {
  onData: (screenEntity: TScreenedEntity) => void
  onStatus: (status: grpcWeb.Status) => void
  onError: (err: grpcWeb.Error) => void
}

/**
 * getStream invokes the RPC and returns the stream object.
 *
 * @param {typeof WorkflowServicePromiseClient} gRPCClient - The gRPC client.
 * @param {IScreenEntitiesBatchParams} params - The batch screen parameters.
 * @returns {grpcWeb.ClientReadableStream} - The gRPC stream object.
 */
const getStream = async (
  gRPCClient: typeof WorkflowServicePromiseClient,
  params: IScreenEntitiesBatchParams
) => {
  const pbFile = new File()
  pbFile.setBytes(
    await params.file.arrayBuffer().then((b) => new Uint8Array(b))
  )
  const req = new BatchScreenEntitiesRequest()
  req.setMinScore(params.scoreThreshold)

  if (
    Object.hasOwnProperty.call(params, 'listSourceCategories') &&
    params.listSourceCategories
  ) {
    req.setListSourceCategoriesList(params.listSourceCategories)
  }

  if (Object.hasOwnProperty.call(params, 'listSources') && params.listSources) {
    const listSources = []
    for (const [listSourceName, listNames] of Object.entries(
      params.listSources
    )) {
      const listSource = new ListSource()
      listSource.setName(listSourceName)
      listSource.setListsList(
        listNames.map((listName) => {
          const list = new List()
          list.setName(listName)
          return list
        })
      )
      listSources.push(listSource)
    }
    req.setListSourcesList(listSources)
  }

  req.setFile(pbFile)
  req.setExcludeWeakAkas(!params.weakAKAs)
  req.setDobMonthsRange(params.dobMonthsRange)

  return gRPCClient.batchScreenEntities(req, {})
}

/**
 * prepareResponse transforms the incoming protobuf message to a TScreenedEntity object.
 *
 * @param {typeof BatchScreenEntitiesResponse} res - The incoming protobuf message.
 * @returns {TScreenedEntity} - The transformed data.
 */
const prepareResponse = (
  res: typeof BatchScreenEntitiesResponse
): TScreenedEntity => {
  const resp: typeof ScreenEntityResponse = res.getResult()
  const hits = resp
    .getHitsList()
    .map((hit: typeof Hit): THit => ({ protobuf: hit }))
  const status = hits.length === 0 ? 'cleared' : 'incomplete'

  const screenEntity: TScreenedEntity = {
    name: resp?.getQuery()?.getName(),
    idNumber: resp?.getQuery()?.getIdNumber(),
    location: resp?.getQuery()?.getAddress(),
    dob: resp?.getQuery()?.getDob()?.toDate(),
    referenceId: resp?.getQuery()?.getReferenceId(),
    totalHits: resp?.getTotalHits(),
    hits: hits,
    status: status
  }
  return screenEntity
}

/**
 * useBatchScreenEntitiesRPC initializes a stream object and returns callbacks for interacting with
 * the stream process.
 *
 * @returns {IScreenEntitiesBatchReturn} - The job state and helper functions.
 */
export const useBatchScreenEntitiesRPC = (
  callbacks: TBatchScreenEntitiesRPCCallbacks
): TBatchScreenEntitiesRPC => {
  const { workflowPromiseService } = useGRPC()

  const [stream, setStream] = React.useState<
    grpcWeb.ClientReadableStream<typeof BatchScreenEntitiesResponse> | undefined
  >(undefined)

  const invoke = React.useCallback(
    async (params: IScreenEntitiesBatchParams) => {
      const newStream = await getStream(workflowPromiseService, params)

      newStream?.on('data', (res: typeof BatchScreenEntitiesResponse) =>
        callbacks.onData(prepareResponse(res))
      )

      newStream?.on('status', (status: grpcWeb.Status) =>
        callbacks.onStatus(status)
      )

      newStream?.on('error', (err: grpcWeb.Error) => callbacks.onError(err))

      setStream(newStream)
    },
    [workflowPromiseService]
  )

  const reset = React.useCallback(async () => {
    stream?.cancel()
  }, [stream])

  const cancel = React.useCallback(() => {
    stream?.cancel()
  }, [stream])

  React.useEffect(
    () => () => {
      stream?.cancel()
    },
    [stream]
  )

  return {
    invoke: invoke,
    reset: reset,
    cancel: cancel
  }
}
