import ProtoTimestamp from 'google-protobuf/google/protobuf/timestamp_pb.js'
import { useGRPC } from '@/lib/hooks'
import { TPersistedScreenedEntity } from '@/lib/features/Screening'
import { TWorkflowState } from '@/components/lib/Workflow/workflowReducer'
import { Audit, PostAuditsRequest } from 'proto-js'

export type TPostAuditsParams = {
  screenID: number
  screenedEntity: TPersistedScreenedEntity
  workflow: TWorkflowState
}

const prepareRPCRequest = (
  params: TPostAuditsParams
): typeof PostAuditsRequest => {
  const timestamp = new Date()
  const audits = params.workflow.checkedHitIndices.map((i) => {
    const audit = new Audit()
    audit.setHitId(params.screenedEntity.hits[i].id)
    audit.setClassification(params.workflow.classification)
    audit.setMatchType(params.workflow.matchType)
    audit.setReasonsList([...params.workflow.reasons]) // workflow.reasons is a Set
    audit.setAnalysis(params.workflow.analysis)
    audit.setTimestamp(ProtoTimestamp.Timestamp.fromDate(timestamp))
    return audit
  })

  const req = new PostAuditsRequest()
  req.setAuditsList(audits)

  return req
}

export type TPostAuditsRPC = (params: TPostAuditsParams) => Promise<void>

export const usePostAuditsRPC = (): TPostAuditsRPC => {
  const { workflowPromiseService } = useGRPC()
  return async (params: TPostAuditsParams) => {
    const req = prepareRPCRequest(params)
    return workflowPromiseService.postAudits(req, {})
  }
}
