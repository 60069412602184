import { useState } from 'react'
import { useDialog } from '@/lib/hooks'
import PageWithLoader from '@/components/lib/PageWithLoader'
import ContentPane from '@/components/lib/ContentPane'
import { screenedEntitiesStats } from '@/lib/features/job'
import { useGetScreenData, TPersistedScreenData } from '@/lib/features/Screening'
import PageHeader from '@/components/App/ScheduledScreeningPage/lib/PageHeader'
import ScreenStats from '@/components/lib/ScreenStats'
import ScreenedEntitiesList from '@/components/lib/ScreenedEntitiesList'
import WorkflowDialog from '@/components/lib/Workflow'
import { AuditTrailDialog } from '@/components/lib/AuditTrail'
import { NoMatches } from '@/components/lib/Search'

import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { formatDate } from '@/lib/utils'
import { Link as RouterLink } from 'react-router-dom'
import {
  CompleteIcon,
  IncompleteIcon,
  ScreenedIcon
} from '@/components/App/ScheduledScreeningPage/lib/Icons'
import IconButton from '@mui/material/IconButton'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'

type TScreenResultsPageContainerProps = {
  screenID: number
}

const StyledDetails = styled(Grid)(() => ({
  fontWeight: 500,
  fontSize: 16
}))

const ScreenDetails = ({
  screenData
}: {
  screenData: TPersistedScreenData
}): JSX.Element => {
  const { ranAt, reviewCompleted } = screenData.screen

  return (
    <StyledDetails
      container
      spacing={6}
      alignItems='center'
      flexDirection='row'
    >
      {screenData.job && (
        <Grid item data-cy='job-name'>
          {screenData.job.name}{' '}
          <IconButton
            aria-label='edit job'
            size='small'
            data-cy='edit-job-button'
            component={RouterLink}
            to={`/jobs/${screenData.job.id}/edit`}
          >
            <ModeEditOutlineIcon />
          </IconButton>
        </Grid>
      )}

      <Grid item data-cy='job-last-screened'>
        Screened:{' '}
        {ranAt !== undefined && <ScreenedIcon screenedAt={formatDate(ranAt)} />}
      </Grid>
      <Grid item>
        Review Status: {reviewCompleted ? <CompleteIcon /> : <IncompleteIcon />}
      </Grid>
    </StyledDetails>
  )
}

type TScreenResultsPageProps = {
  screenData: TPersistedScreenData
}

/**
 * ScreenResultsPage encapsulates the contents of the Screen Results page.
 *
 * @component
 */
const ScreenResultsPage = ({
  screenData
}: TScreenResultsPageProps): JSX.Element => {
  const [screenedEntityIndex, setScreenedEntityIndex] = useState(0)
  const workflowDialog = useDialog()
  const auditTrailDialog = useDialog()
  const { job, screen, entities } = screenData

  const handleSelectScreenedEntity = (i: number | null) => {
    if (i !== null) {
      setScreenedEntityIndex(i)
    }
  }

  const screenedEntityID = entities[screenedEntityIndex]?.id
  const { numResolved } = screenedEntitiesStats(entities)

  return (
    <>
      <ContentPane>
        <PageHeader />
        <ScreenDetails screenData={screenData} />
        <ScreenStats
          open={true}
          filename={job?.filename}
          numQueries={screen.screenedEntitiesCount}
          numScreened={screen.screenedEntitiesCount}
          numFlagged={screen.screenedEntitiesFlaggedCount}
          numResolved={numResolved}
        />
      </ContentPane>

      {entities.length === 0 && <NoMatches />}

      {entities.length > 0 && (
        <>
          <ScreenedEntitiesList
            screenEntities={entities}
            selected={screenedEntityIndex}
            onSelect={handleSelectScreenedEntity}
            onAudit={auditTrailDialog.onOpen}
            onWorkflow={workflowDialog.onOpen}
          />

          <WorkflowDialog
            {...workflowDialog}
            screenData={screenData}
            screenedEntityIndex={screenedEntityIndex}
          />

          <AuditTrailDialog
            {...auditTrailDialog}
            screenedEntityID={screenedEntityID}
          />
        </>
      )}
    </>
  )
}

/**
 * ScreenResultsPageContainer encapsulates the data query and conditional rendering of the Screen
 * Results page
 *
 * @component
 */
export const ScreenResultsPageContainer = ({
  screenID
}: TScreenResultsPageContainerProps): JSX.Element => {
  const screenDataQuery = useGetScreenData(screenID, {
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })
  return (
    <PageWithLoader
      isSuccess={screenDataQuery.isSuccess}
      isError={screenDataQuery.isError}
    >
      {screenDataQuery.data && (
        <ScreenResultsPage screenData={screenDataQuery.data} />
      )}
    </PageWithLoader>
  )
}

export default ScreenResultsPageContainer
