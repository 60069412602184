import React from 'react'

import Skeleton from '@mui/material/Skeleton'

const ImageSkeleton: React.FC = () => (
  <Skeleton animation='wave' variant='rectangular' width='100%' height='100%' />
)

const Image: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = (
  props: React.ImgHTMLAttributes<HTMLImageElement> & {
    className?: string | undefined
    src?: string | undefined
  }
) => {
  const [isLoading, setIsLoading] = React.useState(true)

  const handleLoaded = () => {
    setIsLoading(false)
  }

  const handleError = () => {
    console.warn('Image loading error')
  }

  return (
    <>
      {isLoading && <ImageSkeleton />}
      {props.src !== '' && (
        <img
          alt=''
          {...props}
          style={isLoading ? { display: 'none' } : {}}
          className={props.className}
          onLoad={handleLoaded}
          onError={handleError}
        />
      )}
    </>
  )
}

export default Image
