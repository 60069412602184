import React from 'react'
import { styled } from '@mui/material/styles'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useScreenSettings } from '@/lib/authorization'

import UpgradeLink from '@/components/lib/Auth/UpgradeLink'
import HitListItem from './HitListItem'
const PLACEHOLDER_NAME = 'Abcdefgh Ijklmnopqrstuv '
const PLACEHOLDER_LIST = 'ABCDEF'
const PLACEHOLDER_SCORE = 100

const BlurredAccordionSummary = styled(AccordionSummary)(() => ({
  filter: 'blur(4px)',
  opacity: 0.3
}))

const MessageWrapper = styled('div')(() => ({
  position: 'relative'
}))

const Message = styled('div')(() => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  zIndex: 1000
}))

const BlurredHit: React.FC = () => (
  <Paper elevation={4}>
    <BlurredAccordionSummary data-cy='hidden-hit-list-item'>
      <HitListItem
        entityType='individual'
        name={PLACEHOLDER_NAME}
        secondaryText={PLACEHOLDER_LIST}
        score={PLACEHOLDER_SCORE}
      />
      <ExpandMore />
    </BlurredAccordionSummary>
  </Paper>
)

const HiddenResultsMessage: React.FC<{ totalHits: number }> = ({
  totalHits
}) => {
  const { maxResults, isPremium } = useScreenSettings()
  const hiddenResults = totalHits - maxResults

  if (isPremium) return null
  if (hiddenResults <= 0) return null

  return (
    <MessageWrapper data-cy='hidden-results-message'>
      <Message>
        <Typography variant='h6'>
          You have {hiddenResults.toLocaleString('en-US')} more result
          {hiddenResults > 1 ? 's' : ''}
        </Typography>
        <Typography variant='h6'>
          <UpgradeLink additionalText='to see more' />
        </Typography>
      </Message>
      <BlurredHit />
      <BlurredHit />
      <BlurredHit />
    </MessageWrapper>
  )
}

export default HiddenResultsMessage
