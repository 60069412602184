import ProtoTimestamp from 'google-protobuf/google/protobuf/timestamp_pb.js'
import {
  Country,
  DateRange,
  ListEntry,
  Location,
  Id,
  Hit,
  Name
} from 'proto-js'
import { MONTH_NAMES } from '@/lib/utils'

export const getPrimaryAlias = (e: typeof ListEntry): typeof Name => {
  const name: typeof Name = e.getName()

  if (name) {
    return name
  }

  console.error('ListEntry has no primary alias')
  return new Name()
}

export const getTypeName = (e: typeof ListEntry): string => {
  return e.getType().getName().toLowerCase()
}

export const getHitScore = (h: typeof Hit): number => {
  return Math.round(h.getScore() * 100)
}

export const getCountryName = (country: typeof Country): string => {
  const name = country.getName()

  if (name !== '') {
    return name
  }

  return country.getOfficialName()
}

const dateDetails = (d: Date) => {
  const day = d.getUTCDate()
  const month = d.getUTCMonth() + 1
  const monthName = MONTH_NAMES[d.getUTCMonth()]
  const year = d.getUTCFullYear()

  return {
    day,
    month,
    monthName,
    year
  }
}

export const datesToString = (startDate: Date, endDate: Date): string => {
  const {
    day: startDay,
    month: startMonth,
    monthName: startMonthName,
    year: startYear
  } = dateDetails(startDate)
  const {
    day: endDay,
    month: endMonth,
    monthName: endMonthName,
    year: endYear
  } = dateDetails(endDate)

  if (startDay === 1 && startMonth === 1 && endDay === 31 && endMonth === 12) {
    if (startYear === endYear) {
      return `${startYear}`
    } else {
      return `${startYear} - ${endYear}`
    }
  } else if (startDate.getTime() === endDate.getTime()) {
    return `${startDay} ${startMonthName} ${startYear}`
  } else {
    const startStr = `${startDay} ${startMonthName} ${startYear}`
    const endStr = `${endDay} ${endMonthName} ${endYear}`

    return `${startStr} - ${endStr}`
  }
}

export const dateRangeToString = (d: typeof DateRange): string => {
  if (d === undefined) {
    return ''
  }

  const start: ProtoTimestamp.Timestamp = d.getStart()
  const end: ProtoTimestamp.Timestamp = d.getEnd()
  const raw: string = d.getRaw()

  if (start && end) {
    return datesToString(start.toDate(), end.toDate())
  } else if (raw) {
    return raw
  }

  return ''
}

export const highlightNameField = (
  nameMsg: typeof Name,
  nameHLMsg: typeof Hit.Highlight.NameHighlight
): typeof Name => {
  const nameMsgClone = nameMsg.cloneMessage()
  if (nameHLMsg === undefined) {
    return nameMsgClone
  }

  const fullNameHL = nameHLMsg.getFullName()
  if (fullNameHL !== '') {
    nameMsgClone.setFullName(fullNameHL)
  }

  // Highlight IDs
  const idsList = nameMsgClone.getIdsList()
  const idHLMap = nameHLMsg.getIdsMap()
  nameMsgClone.clearIdsList()
  for (let i = 0; i < idsList.length; i++) {
    const idMsg = idsList[i].cloneMessage()
    if (idHLMap.has(i)) {
      idMsg.setNumber(idHLMap.get(i))
    }
    nameMsgClone.addIds(idMsg)
  }

  return nameMsgClone
}

export function highlightName(data: ListEntryHighlight): void {
  const { src, dest, highlight } = data

  const highlightedName = highlightNameField(src.getName(), highlight.getName())
  dest.setName(highlightedName)
}

export function highlightAlias(data: ListEntryHighlight): void {
  const { src, dest, highlight } = data
  const srcAliasesList = src.getAliasesList()
  const highlightedAliases = highlight.getAliasesMap()
  dest.clearAliasesList()

  srcAliasesList.forEach(
    (alias: typeof ListEntry.Aliases[0], index: number) => {
      const highlightedAlias = highlightNameField(
        alias,
        highlightedAliases.get(index)
      )
      dest.addAliases(highlightedAlias)
    }
  )
}

export function highlightDobs(data: ListEntryHighlight): void {
  const { src, dest, highlight } = data
  const srcDobsList = src.getDobsList()
  const highlightedDobs = highlight.getDobsList()
  dest.clearDobsList()

  srcDobsList.forEach((dob: typeof ListEntry.Dobs[0], index: number) => {
    dob.highlight = highlightedDobs.includes(index)
    dest.addDobs(dob)
  })
}

export function highlightAddress(
  src: typeof ListEntry,
  highlight: typeof Hit.Highlight
): void {
  const addressStr = highlight.getAddress()
  if (addressStr !== '') {
    src.setAddress(addressStr)
  }

  const cityStr = highlight.getCity()
  if (cityStr !== '') {
    src.setCity(cityStr)
  }

  const postalStr = highlight.getPostal()
  if (postalStr !== '') {
    src.setPostal(postalStr)
  }

  const regionStr = highlight.getRegion()
  if (regionStr !== '') {
    src.setRegion(regionStr)
  }

  if (src.hasCountry()) {
    const pbCountry = src.getCountry()
    const countryNameStr = highlight.getCountryName()
    const countryISOStr = highlight.getCountryIso2()
    if (countryISOStr !== '') {
      if (pbCountry.getName() !== '') {
        pbCountry.setName(`<em>${pbCountry.getName()}</em>`)
      } else {
        pbCountry.setOfficialName(`<em>${pbCountry.getOfficialName()}</em>`)
      }
    } else if (countryNameStr !== '') {
      pbCountry.setOfficialName(countryNameStr)
    }
  }
}

export function highlightPobs(data: ListEntryHighlight): void {
  const { src, dest, highlight } = data

  const srcPobsList = src.getPobsList()
  const highlightedPobs = highlight.getPobsMap()
  dest.clearPobsList()

  srcPobsList.forEach((pob: typeof ListEntry.POBs[0], index: number) => {
    const pobClone = pob.cloneMessage()
    if (highlightedPobs.has(index)) {
      highlightAddress(pobClone, highlightedPobs.get(index))
    }
    dest.addPobs(pobClone)
  })
}

export function highlightAddresses(data: ListEntryHighlight): void {
  const { src, dest, highlight } = data
  const srcAddressesList = src.getAddressesList()
  const highlightedAddresses = highlight.getAddressesMap()
  dest.clearAddressesList()

  srcAddressesList.forEach(
    (address: typeof ListEntry.Addresses[0], index: number) => {
      const addressClone = address.cloneMessage()
      if (highlightedAddresses.has(index)) {
        highlightAddress(addressClone, highlightedAddresses.get(index))
      }
      dest.addAddresses(addressClone)
    }
  )
}

export type ListEntryHighlight = {
  src: typeof ListEntry
  dest: typeof ListEntry
  highlight: typeof Hit.Highlight
}

// getHighlightedEntity takes a Hit message type and returns a cloned ListEntry message type
// populated with the highlighted version of each field. The majority of the cloning is
// done because the javascript protocol buffers spec states not to mutate the list
// returned by getXXXList().
export const getHighlightedEntity = (h: typeof Hit): typeof ListEntry => {
  const src = h.getListEntry()

  if (src.getType().getName() === 'Media') {
    return src
  }

  const data = {
    src,
    dest: src.cloneMessage(),
    highlight: h.getHighlight()
  }

  highlightName(data)
  highlightAlias(data)
  highlightDobs(data)
  highlightAddresses(data)
  highlightPobs(data)

  return data.dest
}

type EntityMatchInformation = {
  id?: typeof Id
  names: typeof Name[]
  addresses: typeof Location[]
  pobs: typeof Location[]
}

// Return a hash of ids/addresses/pobs that matched on the hit
export const getEntityMatchInformation = (
  h: typeof Hit
): EntityMatchInformation => {
  const pbHL = h.getHighlight()
  const pbEntitySrc = h.getListEntry()

  const entityMatchInformation = {
    id: undefined,
    names: [],
    addresses: [],
    pobs: []
  } as EntityMatchInformation

  const aliasesListSrc = pbEntitySrc.getAliasesList()
  const highlightAliases = pbHL.getAliasesMap()

  if (pbHL.hasName()) {
    const name = pbEntitySrc.getName()
    entityMatchInformation.names.push(name)

    // Get matching IDs from name
    const idsListSrc = name.getIdsList()

    const pbIDsHL = pbHL.getName().getIdsMap()
    for (let iIdx = 0; iIdx < idsListSrc.length; iIdx++) {
      const id = idsListSrc[iIdx]

      if (pbIDsHL.has(iIdx)) {
        entityMatchInformation.id = id
      }
    }
  }

  for (let aIdx = 0; aIdx < aliasesListSrc.length; aIdx++) {
    const alias = aliasesListSrc[aIdx]
    const pbAliasHL = highlightAliases.get(aIdx)

    if (highlightAliases.has(aIdx)) {
      entityMatchInformation.names.push(alias)

      // Get matching IDs from aliases
      if (entityMatchInformation.id === undefined) {
        const idsListSrc = alias.getIdsList()
        const pbIDsHL = pbAliasHL.getIdsMap()

        for (let iIdx = 0; iIdx < idsListSrc.length; iIdx++) {
          const id = idsListSrc[iIdx]

          if (pbIDsHL.has(iIdx)) {
            entityMatchInformation.id = id
          }
        }
      }
    }
  }

  // Get matching addresses
  const addressesListSrc = pbEntitySrc.getAddressesList()
  const highlightAddresses = pbHL.getAddressesMap()
  for (let i = 0; i < addressesListSrc.length; i++) {
    const address = addressesListSrc[i]

    if (highlightAddresses.has(i)) {
      entityMatchInformation.addresses.push(address)
    }
  }

  // Get matching POBs
  const pobsListSrc = pbEntitySrc.getPobsList()
  const highlightPobs = pbHL.getPobsMap()
  for (let i = 0; i < pobsListSrc.length; i++) {
    const pob = pobsListSrc[i]

    if (highlightPobs.has(i)) {
      entityMatchInformation.pobs.push(pob)
    }
  }

  return entityMatchInformation
}
