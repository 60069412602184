import React from 'react'

export type TDialog = {
  open: boolean
  onClose: () => void
  onOpen: () => void
  setOpen: (newValue: boolean) => void
}

export const useDialog = (defaultOpen = false): TDialog => {
  const [open, setOpen] = React.useState(defaultOpen)

  const onClose = () => {
    setOpen(false)
  }

  const onOpen = () => {
    setOpen(true)
  }

  return {
    open,
    onClose,
    onOpen,
    setOpen
  }
}

export default useDialog
