import * as types from '@/types'
import { useGRPC } from '@/lib/hooks'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { List, ListSource, GetListSourcesResponse } from 'proto-js'

export type TGetListSourcesResponse = types.IListSource[]

/**
 * Format the RPC response and create a list of IListSource types.
 *
 * @param {typeof GetListSourcesResponse} resp - The raw RPC response protobuf message
 * @returns {TGetListSourcesResponse} - The list of list sources.
 */
const prepareRPCResponse = (
  resp: typeof GetListSourcesResponse
): TGetListSourcesResponse => {
  const ret = resp.getListSourcesList().map(
    (listSourcePB: typeof ListSource): types.IListSource => {
      const listSource: types.IListSource = {
        id: listSourcePB.getId(),
        name: listSourcePB.getName(),
        category: {
          id: listSourcePB.getCategory().getId(),
          name: listSourcePB.getCategory().getName()
        },
        lists: listSourcePB.getListsList().map((listPB: typeof List) => ({
          id: listPB.getId(),
          name: listPB.getName()
        }))
      }
      return listSource
    }
  )
  return ret
}

/**
 * Hook for fetching list sources data from the back-end.
 *
 * @returns {() => Promise<TGetListSourcesResponse>}
 */
export const useGetListSourcesRPC = (): (() => Promise<
  TGetListSourcesResponse
>) => {
  const { dataPromiseService } = useGRPC()
  return async (): Promise<TGetListSourcesResponse> => {
    const req = new Empty()
    const md = { deadline: new Date(Date.now() + 2 * 1000).getTime() }
    return dataPromiseService
      .getListSources(req, md)
      .then((resp: typeof GetListSourcesResponse) => {
        return prepareRPCResponse(resp)
      })
  }
}
