import React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import Help from '@mui/icons-material/Help'
const PREFIX = 'DisplayedRowsDisplay'

const classes = {
  wrapper: `${PREFIX}-wrapper`
}

const Root = styled('span')(() => ({
  [`&.${classes.wrapper}`]: {
    display: 'flex'
  }
}))

export const HITS_LIMIT = 50
export const DISPLAY_LIMIT = 10000

const tooltipText = `To see more than ${HITS_LIMIT} results per search, email sales@castellum.ai to upgrade to the next tier.`

interface DisplayedRowsDisplayProps {
  from: number
  to: number
  totalHits: number
}

const DisplayedRowsDisplay: React.FC<DisplayedRowsDisplayProps> = ({
  from,
  to,
  totalHits
}) => {
  return (
    <Root data-cy='pagination-display' className={classes.wrapper}>
      {from}-{to} of{' '}
      {totalHits >= DISPLAY_LIMIT
        ? `over ${DISPLAY_LIMIT.toLocaleString('en-US')}`
        : totalHits.toLocaleString('en-US')}
      {totalHits > HITS_LIMIT && (
        <Tooltip title={tooltipText} data-cy='hits-limit-exceeded-tooltip'>
          <Help fontSize='small' />
        </Tooltip>
      )}
    </Root>
  )
}

export default DisplayedRowsDisplay
