import { useGRPC } from '@/lib/hooks'
import { GetScheduledJobsResponse, ScheduledJobWithStatus } from 'proto-js'
import { TScheduledJobWithStatus } from '@/lib/features/job'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { jobFromProtobuf } from './utils'

export type TGetScheduledJobsResponse = {
  jobs: TScheduledJobWithStatus[]
}

export const prepareRPCResponse = (
  response: typeof GetScheduledJobsResponse
): TGetScheduledJobsResponse => {
  const jobs = response
    .getJobsList()
    .map((jobWithStatus: typeof ScheduledJobWithStatus) => {
      const job = jobWithStatus.getJob()

      return {
        job: jobFromProtobuf(job),
        lastScreened: jobWithStatus.getLastScreened()?.toDate(),
        lastReviewCompleted: jobWithStatus.getLastReviewCompleted()
      }
    })

  return {
    jobs: jobs
  }
}

type TGetScheduledJobsRPC = () => Promise<ReturnType<typeof prepareRPCResponse>>

/**
 * useGetScheduledJobsRPC provides an RPC to fetch jobs for the signed in user.
 *
 * @returns {TGetScheduledJobsRPC} - An async function to invoke the RPC.
 */
export const useGetScheduledJobsRPC = (): TGetScheduledJobsRPC => {
  const { workflowPromiseService } = useGRPC()

  return async () => {
    const req = new Empty()

    return workflowPromiseService
      .getScheduledJobs(req, {})
      .then(prepareRPCResponse)
  }
}
