import React from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import ListSourceDisplay from './ListSourceDisplay'
import { ListEntry } from 'proto-js'
import {
  hitClassificationLabels,
  THitClassification,
  THitClassificationReason,
  TScreenEntity
} from '@/lib/features/Screening'
import { formatTZAgnosticDate } from '@/lib/utils'

const TimestampTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary
}))

const EmphasisTypography = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300]
}))

const AuditTimestamp = ({ timestamp }: { timestamp: Date }) => (
  <TimestampTypography variant='overline'>
    {timestamp ? timestamp.toString() : ''}
  </TimestampTypography>
)

const AuditElems = () => ({
  elems: new Array<JSX.Element>(),
  key: 0,
  push: function (name: string, value: string) {
    this.elems.push(
      <Typography key={(this.key += 1)} variant='body2' display='inline'>
        {name + ' '}
      </Typography>
    )

    this.elems.push(
      <EmphasisTypography
        key={(this.key += 1)}
        variant='body2'
        display='inline'
      >
        {value.trim()}
      </EmphasisTypography>
    )

    this.elems.push(
      <Typography key={(this.key += 1)} variant='body2' display='inline'>
        {', '}
      </Typography>
    )
  },
  finalize: function () {
    return this.elems.slice(0, this.elems.length - 1)
  }
})

const AuditQuery = ({ screenEntity }: { screenEntity: TScreenEntity }) => {
  const elems = AuditElems()

  if (screenEntity.name) {
    elems.push('name', screenEntity.name)
  }

  if (screenEntity.location) {
    elems.push('address', screenEntity.location)
  }

  if (screenEntity.idNumber) {
    elems.push('id', screenEntity.idNumber)
  }

  if (screenEntity.dob) {
    let formattedDOB
    if (screenEntity.dob instanceof Date) {
      formattedDOB = formatTZAgnosticDate(screenEntity.dob)
    } else {
      formattedDOB = screenEntity.dob
    }

    elems.push('date of birth', formattedDOB)
  }

  if (screenEntity.referenceId) {
    elems.push('(Reference ID:', screenEntity.referenceId + ')')
  }

  return <>{elems.finalize()}</>
}

const AuditReasons = ({ reasons }: { reasons: THitClassificationReason[] }) => {
  if (reasons.length === 0) {
    return null
  }
  let elems: JSX.Element[] = []

  reasons.forEach((r: string, i: number) => {
    elems.push(
      <EmphasisTypography key={i} variant='body2' display='inline'>
        {r}
      </EmphasisTypography>
    )
    elems.push(
      <Typography key={reasons.length + i} variant='body2' display='inline'>
        {', '}
      </Typography>
    )
  })
  elems = elems.slice(0, elems.length - 1) // Remove last divider

  return (
    <>
      <Typography variant='body2' display='inline'>
        {' due to '}
      </Typography>
      {elems}
    </>
  )
}

const AuditAnalysis = ({ analysis }: { analysis: string }) => {
  if (typeof analysis === 'undefined' || !analysis) {
    return null
  }
  return (
    <>
      <Typography variant='body2' display='inline'>
        {' Analysis: '}
      </Typography>
      <Typography variant='caption' display='inline'>
        {analysis.trim()}
      </Typography>
    </>
  )
}

type TAuditLogPreview = {
  screenEntity: TScreenEntity
  listEntry?: typeof ListEntry
  timestamp: Date
  classification: THitClassification
  matchType: string
  reasons: THitClassificationReason[]
  analysis: string
}

const AuditLogPreview: React.FC<TAuditLogPreview> = ({
  screenEntity,
  listEntry,
  timestamp,
  classification,
  matchType,
  reasons,
  analysis
}) => {
  const reasonsComponent = <AuditReasons reasons={reasons} />
  const analysisComponent = <AuditAnalysis analysis={analysis} />
  return (
    <>
      <AuditTimestamp timestamp={timestamp} />
      <div data-cy='audit-log-preview'>
        <Typography variant='body2' display='inline'>
          {'Scan for '}
        </Typography>
        <AuditQuery screenEntity={screenEntity} />
        <Typography variant='body2' display='inline'>
          {', classified as '}
        </Typography>
        <EmphasisTypography variant='body2' display='inline'>
          {hitClassificationLabels[classification].toLowerCase()}
        </EmphasisTypography>
        <ListSourceDisplay listEntry={listEntry} />
        <Typography variant='body2' display='inline'>
          {' for type '}
        </Typography>
        <EmphasisTypography variant='body2' display='inline'>
          {matchType.toLowerCase()}
        </EmphasisTypography>
        {reasonsComponent && <>{reasonsComponent}</>}
        <Typography variant='body2' display='inline'>
          .
        </Typography>
        {analysisComponent && <>{analysisComponent}</>}
      </div>
    </>
  )
}

export default AuditLogPreview
