import { useGRPC } from '@/lib/hooks'

import { GetJobScreensRequest, GetJobScreensResponse, Screen } from 'proto-js'
import { TPersistedScreen } from '@/lib/features/Screening'
import { screenFromProtobuf } from './utils'

export type TGetJobScreensResponse = {
  screens: TPersistedScreen[]
}

export const prepareRPCRequest = (
  jobID: number,
  pageSize = 5
): typeof GetJobScreensRequest => {
  const request = new GetJobScreensRequest()

  request.setJobIdsList([jobID])
  request.setPageSize(pageSize)

  return request
}

export const prepareRPCResponse = (
  response: typeof GetJobScreensResponse
): TGetJobScreensResponse => {
  const screens = response.getScreensList().map((screen: typeof Screen) => {
    return screenFromProtobuf(screen)
  })

  return {
    screens: screens
  }
}

type TGetJobScreensRPC = (
  ...args: Parameters<typeof prepareRPCRequest>
) => Promise<ReturnType<typeof prepareRPCResponse>>

/**
 * useGetJobScreensRPC provides an RPC to fetch screens for a job by job Id.
 *
 * @returns {TGetJobScreensRPC} - An async function to invoke the RPC.
 */
export const useGetJobScreensRPC = (): TGetJobScreensRPC => {
  const { workflowPromiseService } = useGRPC()

  return async (...args: Parameters<typeof prepareRPCRequest>) => {
    return workflowPromiseService
      .getJobScreens(prepareRPCRequest(...args), {})
      .then(prepareRPCResponse)
  }
}
