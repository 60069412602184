export function insertNewlineAfter(string: string, length: number): string {
  const re = new RegExp(`.{1,${length}}`, 'g')
  const splitString = string.match(re)

  if (splitString === null) {
    return string
  }

  return splitString.join(`\n`)
}
