import React from 'react'

export interface FieldState<T> {
  status: 'success' | 'error' | 'warn' | 'disabled'
  default: T
  value: T
  label: string
  message: string
  required: boolean
  maxLength: number
  lastUpdated: number
}

export type FieldAction<T> =
  | { action: 'value'; value: T }
  | { action: 'default' }
  | { action: 'error'; message: string }
  | { action: 'warn'; message: string }
  | { action: 'disable' }

function reducer<T>(
  state: FieldState<T>,
  action: FieldAction<T>
): FieldState<T> {
  switch (action.action) {
    case 'value':
      return {
        ...state,
        status: 'success',
        value: action.value,
        lastUpdated: Date.now()
      }
    case 'default':
      return {
        ...state,
        status: 'success',
        value: state.default,
        lastUpdated: Date.now()
      }
    case 'error':
      return { ...state, status: 'error', message: action.message }
    case 'warn':
      return { ...state, status: 'warn', message: action.message }
    case 'disable':
      return { ...state, status: 'disabled' }
    default:
      return state
  }
}

export function useFormField<T>(
  label: string,
  defaultValue: T,
  required = false,
  maxLength = -1
): [FieldState<T>, React.Dispatch<FieldAction<T>>] {
  const [state, dispatch] = React.useReducer<
    React.Reducer<FieldState<T>, FieldAction<T>>
  >(reducer, {
    status: 'success',
    value: defaultValue,
    default: defaultValue,
    label: label,
    message: '',
    required: required,
    maxLength: maxLength,
    lastUpdated: Date.now()
  })

  return [state, dispatch]
}
