import {
  useMutation,
  useQueryClient,
  UseMutationResult,
  QueryClient
} from 'react-query'
import { screenQueryKeys } from './queryKeys'
import { usePostAudits, TPostAuditsMutation } from './usePostAudits'
import { screenMutationKeys } from './mutationKeys'
import { getAdHocScreenDataFromCache, setScreenDataCache } from './utils'
import {
  usePostScreenedEntityRPC,
  TPostScreenedEntityParams,
  TPostScreenedEntityResponse
} from '@/lib/hooks/api/usePostScreenedEntityRPC'
import { TWorkflowState } from '@/components/lib/Workflow/workflowReducer'

// The return type of the usePostScreenedEntity mutation hook.
export type TPostScreenedEntityMutation = UseMutationResult<
  TPostScreenedEntityResponse,
  unknown,
  TPostScreenedEntityParams,
  unknown
>

type TUpdateScreenDataCacheAndPostAuditsArgs = {
  queryClient: QueryClient
  postAudits: TPostAuditsMutation
  resp: TPostScreenedEntityResponse
  screenID: number
  screenedEntityIndex: number
  workflow: TWorkflowState
}

/**
 * updateScreenDataCacheAndPostAudits updates the screen data cache and subsequently invokes the
 * postAudits mutation.
 *
 * @param {TUpdateScreenDataCacheAndPostAuditsArgs} args - The data and functions required to perform side effects.
 */
const updateScreenDataCacheAndPostAudits = async (
  args: TUpdateScreenDataCacheAndPostAuditsArgs
) => {
  const screenData = getAdHocScreenDataFromCache(args.queryClient)
  if (screenData !== undefined) {
    const newScreenData = {
      ...screenData
    }
    const newScreenedEntity = {
      ...newScreenData.entities[args.screenedEntityIndex],
      ...args.resp
    }
    newScreenData.entities.splice(
      args.screenedEntityIndex,
      1,
      newScreenedEntity
    )
    if ('id' in newScreenData.screen && newScreenData.screen.id !== undefined) {
      setScreenDataCache(args.queryClient, newScreenData, screenQueryKeys.adHoc)
      await args.postAudits.mutateAsync({
        screenID: args.screenID,
        screenedEntity: newScreenedEntity,
        workflow: args.workflow
      })
    }
  }
}

/**
 * Hook for posting a new screened entity to the backend. Mutates the screen data in the
 * react-query cache with additional information from the RPC response. Upon success, it invokes
 * the mutation to post audit logs as a side-effect.
 *
 * @param {number} screenedEntityIndex - The index of the screened entity to submit.
 * @param {TWorkflowState} workflow - The workflow state.
 * @returns {UseMutationResult} - The react-query mutation object.
 */
export const usePostScreenedEntity = (
  screenedEntityIndex: number,
  workflow: TWorkflowState
): TPostScreenedEntityMutation => {
  const rpc = usePostScreenedEntityRPC()
  const queryClient = useQueryClient()
  const postAudits = usePostAudits(screenedEntityIndex)
  return useMutation((params) => rpc(params), {
    mutationKey: screenMutationKeys.all,
    onSuccess: (resp, variables) =>
      updateScreenDataCacheAndPostAudits({
        queryClient: queryClient,
        postAudits: postAudits,
        resp: resp,
        screenID: variables.screenID,
        screenedEntityIndex: screenedEntityIndex,
        workflow: workflow
      })
  })
}
