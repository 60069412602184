import {
  alpha,
  Theme,
  ThemeOptions,
  createTheme as createMuiTheme
} from '@mui/material/styles'

import { grey } from '@mui/material/colors'

// Readd default styling for button
// https://codesandbox.io/s/mimic-v4-button-default-color-bklx8?file=/src/Demo.tsx:32-486
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true
  }
}

declare module '@mui/material' {
  interface Color {
    main: string
    dark: string
  }
}

declare module '@mui/material/styles/createPalette' {
  interface HighlightColorPaletteOptions {
    main?: string
  }

  interface HighlightColorPalette {
    main: string
  }

  interface PaletteOptions {
    success?: PaletteColorOptions
    warning?: PaletteColorOptions
    highlight?: HighlightColorPaletteOptions
    dividerLight?: string
  }

  interface Palette {
    success: PaletteColor
    warning: PaletteColor
    highlight: HighlightColorPalette
    dividerLight: string
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#484848',
      main: '#212121',
      dark: '#000000',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#fce700'
    },
    error: {
      light: '#ffe8eb',
      main: '#cf0a1a',
      dark: '#a60311',
      contrastText: '#000000'
    },
    success: {
      light: '#eaffe8',
      main: '#54E247',
      dark: '#249819',
      contrastText: '#000000'
    },
    warning: {
      light: '#fffee8',
      main: '#f5ed84',
      dark: '#f5ed84'
    },
    highlight: {
      main: '#ff0000'
    },
    text: {
      primary: '#000000'
    },
    dividerLight: 'rgba(255, 255, 255, 0.25)',
    background: {
      default: '#FFFFFF'
    },
    grey: {
      main: grey[300],
      dark: grey[400]
    }
  }
})

const componentsConfig = {
  MuiButton: {
    variants: [
      {
        props: { variant: 'contained', color: 'grey' },
        style: {
          color: theme.palette.getContrastText(theme.palette.grey[300])
        }
      },
      {
        props: { variant: 'outlined', color: 'grey' },
        style: {
          color: theme.palette.text.primary,
          borderColor:
            theme.palette.mode === 'light'
              ? 'rgba(0, 0, 0, 0.23)'
              : 'rgba(255, 255, 255, 0.23)',
          '&.Mui-disabled': {
            border: `1px solid ${theme.palette.action.disabledBackground}`
          },
          '&:hover': {
            borderColor:
              theme.palette.mode === 'light'
                ? 'rgba(0, 0, 0, 0.23)'
                : 'rgba(255, 255, 255, 0.23)',
            backgroundColor: alpha(
              theme.palette.text.primary,
              theme.palette.action.hoverOpacity
            )
          }
        }
      },
      {
        props: { color: 'grey', variant: 'text' },
        style: {
          color: theme.palette.text.primary,
          '&:hover': {
            backgroundColor: alpha(
              theme.palette.text.primary,
              theme.palette.action.hoverOpacity
            )
          }
        }
      }
    ]
  }
}

export default function createTheme(options: ThemeOptions): Theme {
  return createMuiTheme(theme, {
    components: componentsConfig,
    ...options
  })
}
