import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const HeaderLink = styled(Button)(({ theme }) => ({
  position: 'relative',
  color: theme.palette.text.primary,
  textDecoration: 'none',
  textTransform: 'capitalize',
  '&:hover': {
    color: theme.palette.primary.dark,
    backgroundColor: 'transparent'
  },
  '&:before': {
    content: "''",
    position: 'absolute',
    width: '100%',
    height: '2px',
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.primary.dark,
    visibility: 'hidden',
    WebkitTransform: 'scaleX(0)',
    transform: 'scaleX(0)',
    WebkitTransition: 'all 0.3s ease-in-out 0s',
    transition: 'all 0.3s ease-in-out 0s'
  },
  '&:hover:before': {
    visibility: 'visible',
    WebkitTransform: 'scaleX(1)',
    transform: 'scaleX(1)'
  }
}))

export default HeaderLink
