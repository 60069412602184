import React from 'react'
import { StyleSheet, Text } from '@react-pdf/renderer'

const pdfStyles = StyleSheet.create({
  pageNumber: {
    fontSize: 14,
    position: 'absolute',
    bottom: 10,
    right: 15,
    fontWeight: 700
  },
  image: {
    position: 'absolute',
    bottom: 30,
    left: 70,
    width: 460
  }
})

// A page number component cannot be wrapped in a view if it will work correctly
// when views wrap https://github.com/diegomura/react-pdf/issues/525
export const PageNumber: React.FC = () => (
  <Text
    fixed
    style={pdfStyles.pageNumber}
    render={({ pageNumber }) => `${pageNumber}`}
  />
)

export default PageNumber
