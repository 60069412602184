import React, { useState } from 'react'
import FormHelperText from '@mui/material/FormHelperText'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { useField } from 'formik'

import {
  FileUploadButton,
  FileUploadChip,
  FilenameChip
} from '@/components/lib/Form'

type TScreenEntitiesFieldProps = {
  filename?: string
}

/**
 * ScreenEntitiesField renders a file upload button and corresponding chip component.
 *
 * If rendered without the filename prop, the File object from the formik field will drive the
 * chip. This satisfies the scenario when a new file is uploaded via the form.
 *
 * If rendered with the filename prop, a chip will be rendered with the filename value. This
 * satisfies the scenario when a file has already been uploaded to the backend and we only have
 * access to the filename.
 *
 * @component
 */
const ScreenEntitiesField = ({
  filename
}: TScreenEntitiesFieldProps): JSX.Element => {
  const theme = useTheme()
  const [field, meta, helpers] = useField('screenEntities')
  const [filenameChipRemoved, setFilenameChipRemoved] = useState(false)

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement
    if (element.files && element.files.length > 0) {
      helpers.setValue(element.files[0])
      helpers.setTouched(true, false)
    }
  }

  const handleFileReset = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = e.target as HTMLInputElement
    element.value = ''

    if (meta.touched && meta.error) {
      helpers.setValue(null)
    }
  }

  return (
    <>
      <Stack
        sx={{ gap: `${theme.spacing(2)}` }}
        direction={{ xs: 'column', sm: 'row' }}
      >
        <FileUploadButton
          onFileSelect={handleFileSelect}
          onFileReset={handleFileReset}
        />
        {filename && !field.value && !filenameChipRemoved && (
          <FilenameChip
            filename={filename}
            isError={meta.error !== undefined}
            remove={() => {
              setFilenameChipRemoved(true)
            }}
          />
        )}
        {field.value !== null && (
          <FileUploadChip
            file={field.value}
            isError={meta.error !== undefined}
            remove={() => {
              helpers.setValue(null)
            }}
            onError={() => {
              helpers.setError('File format error')
            }}
          />
        )}
      </Stack>
      <FormHelperText
        hidden={!(meta.touched && Boolean(meta.error))}
        error={meta.touched && Boolean(meta.error)}
      >
        {meta.error}
      </FormHelperText>
    </>
  )
}

export default ScreenEntitiesField
