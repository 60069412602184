import { useMutation, useQueryClient, UseMutationResult } from 'react-query'
import {
  usePostScheduledJobRPC,
  TPostScheduledJobRequest,
  TPostScheduledJobResponse
} from '@/lib/hooks/api/usePostScheduledJobRPC'
import { jobQueryKeys } from './queryKeys'
import { TServerError } from '@/lib/gRPC'

// The return type of the usePostScheduledJob mutation hook.
export type TPostScheduledJobMutation = UseMutationResult<
  TPostScheduledJobResponse,
  TServerError,
  TPostScheduledJobRequest,
  unknown
>

/**
 * Hook for posting a new scheduled job.
 *
 * @returns {TPostScheduledJobMutation} - The react-query mutation object.
 */
export const usePostScheduledJob = (): TPostScheduledJobMutation => {
  const rpc = usePostScheduledJobRPC()
  const queryClient = useQueryClient()

  return useMutation(rpc, {
    onSuccess: async (resp) => {
      queryClient.invalidateQueries(jobQueryKeys.userJobs)
      queryClient.setQueryData(jobQueryKeys.job(resp.id), resp)
    }
  })
}
