import React from 'react'

import { NoMatches } from '@/components/lib/Search'
import FileInstructions from './FileInstructions'

interface QueryResultsProps {
  showFileInstructions: boolean
  numRows: number
  numScreened: number
  numFlagged: number
  queriesList: JSX.Element
}

const QueryResults: React.FC<QueryResultsProps> = ({
  showFileInstructions = false,
  numRows = 0,
  numScreened = 0,
  numFlagged = 0,
  queriesList
}) => {
  if (showFileInstructions) {
    return <FileInstructions />
  } else if (numFlagged === 0 && numScreened === numRows && numRows > 0) {
    return <NoMatches />
  }

  return queriesList
}

export default QueryResults
