import React from 'react'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'

import { TCRONExpression } from '@/lib/features/job'

/**
 * checkboxGridWidth returns the column width of the provided checkbox. All day of the month
 * checkboxes should have a width of 1 except for the special "last day of the month" checkbox.
 *
 * @param {string} value - The checkbox value.
 * @returns {string} - The column width of the checkbox.
 */
const checkboxGridWidth = (value: string) => (value === 'L' ? 'auto' : 1)

/**
 * checkboxLabel returns the label for the provided checkbox. All day of the month checkboxes
 * should have their numeric value as the label except for the special "last day of the month"
 * checkbox.
 *
 * @param {string} value - The checkbox value.
 * @returns {string} - The checkbox label.
 */
const checkboxLabel = (value: string) =>
  value === 'L' ? 'Last day of the month' : value

/**
 * daysOfTheMonthStr translates the set of checked options into a string representing the
 * day-of-month component for a CRON expression.
 *
 * @param {Set<string>} days - The set of checked days of the month.
 * @returns {string} - The day-of-month component of a CRON expression.
 */
const daysOfTheMonthStr = (days: Set<string>) =>
  days.size === 0 ? '*' : [...days].join(',')

const dayOfMonthValues = [...Array(31).keys()].map((i) => (i + 1).toString())
dayOfMonthValues.push('L')

type TDaysOfTheMonthProps = {
  cron: TCRONExpression
  onChange: (cron: TCRONExpression) => void
}

const DaysOfTheMonth = (props: TDaysOfTheMonthProps): JSX.Element => {
  const [days, setDays] = React.useState<Set<string>>(new Set([]))

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDays = new Set(days)
    if (event.target.checked) {
      newDays.add(event.target.name)
    } else {
      newDays.delete(event.target.name)
    }
    setDays(newDays)
    props.onChange({
      ...props.cron,
      dayOfMonth: daysOfTheMonthStr(newDays)
    })
  }

  const checkboxes = dayOfMonthValues.map((v) => (
    <Grid item xs={checkboxGridWidth(v)} key={v}>
      <FormControlLabel
        control={
          <Checkbox checked={days.has(v)} onChange={handleSelect} name={v} />
        }
        label={checkboxLabel(v)}
      />
    </Grid>
  ))

  return (
    <FormControl>
      <FormGroup>
        <Grid container columns={7} spacing={0} sx={{ width: '425px' }}>
          {checkboxes}
        </Grid>
      </FormGroup>
    </FormControl>
  )
}

export default DaysOfTheMonth
