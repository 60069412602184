import { useCallback } from 'react'
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import * as grpcWeb from 'grpc-web'
import {
  useRemoteDataStream,
  IRemoteDataStreamState,
  ERemoteDataStreamActionType
} from '@/lib/hooks'
import {
  useBatchScreenEntitiesRPC,
  IScreenEntitiesBatchParams,
  TBatchScreenEntitiesRPC
} from '@/lib/hooks/api/useBatchScreenEntitiesRPC'
import { TAdHocScreenData, TScreenedEntity } from './types'
import { screenQueryKeys } from './queryKeys'

// TUseBatchScreening encapsulates the state and callbacks for invoking, canceling, resetting a
// batch screen process.
type TUseBatchScreening = TBatchScreenEntitiesRPC & {
  stream: IRemoteDataStreamState<TScreenedEntity>
  query: UseQueryResult<
    TAdHocScreenData | Persisted<TAdHocScreenData> | undefined
  >
}

type TQueryOptions = Omit<
  UseQueryOptions<
    Promise<TAdHocScreenData | Persisted<TAdHocScreenData> | undefined>,
    string[],
    TAdHocScreenData | Persisted<TAdHocScreenData>,
    string[]
  >,
  'queryKey' | 'queryFn'
>

/**
 * useBatchScreening provides functionality for performaing a batch screen.
 *
 * @returns
 */
export const useBatchScreening = (
  options?: TQueryOptions
): TUseBatchScreening => {
  const [stream, dispatch] = useRemoteDataStream<TScreenedEntity>()
  const queryClient = useQueryClient()

  const onData = useCallback(
    (screenEntity: TScreenedEntity) => {
      const oldScreenData = queryClient.getQueryData<TAdHocScreenData>(
        screenQueryKeys.batch
      )
      if (oldScreenData != undefined) {
        queryClient.setQueryData<TAdHocScreenData>(screenQueryKeys.batch, {
          ...oldScreenData,
          entities: [...oldScreenData.entities, screenEntity]
        })
      }
    },
    [queryClient]
  )

  const onStatus = useCallback(
    (status: grpcWeb.Status) => {
      if (status.code === grpcWeb.StatusCode.OK) {
        dispatch({ type: ERemoteDataStreamActionType.Success })
      } else {
        dispatch({
          type: ERemoteDataStreamActionType.Error,
          error: new Error(
            `Error from status code ${status.code}: ${status.details}`
          )
        })
      }
    },
    [dispatch]
  )

  const onError = useCallback(
    (err: grpcWeb.Error) => {
      dispatch({
        type: ERemoteDataStreamActionType.Error,
        error: new Error(`Error from error code ${err.code}: ${err.message}`)
      })
    },
    [dispatch]
  )

  const rpc = useBatchScreenEntitiesRPC({
    onData: onData,
    onStatus: onStatus,
    onError: onError
  })

  const invoke = useCallback(
    async (params: IScreenEntitiesBatchParams) => {
      await rpc.invoke(params)
      const screenData: TAdHocScreenData = {
        screen: {
          screenType: 'batch',
          ranAt: new Date(),
          parameters: {
            listSourceCategories: params.listSourceCategories,
            listSources: params.listSources,
            scoreThreshold: params.scoreThreshold,
            weakAKAs: params.weakAKAs,
            dobMonthsRange: params.dobMonthsRange
          }
        },
        entities: []
      }
      queryClient.setQueryData(screenQueryKeys.batch, screenData)
    },
    [rpc, queryClient]
  )

  const reset = useCallback(() => {
    rpc.reset()
    queryClient.removeQueries(screenQueryKeys.batch)
  }, [rpc, queryClient])

  const cancel = useCallback(() => rpc.cancel, [rpc])

  const query = useQuery(
    screenQueryKeys.batch,
    () =>
      Promise.resolve(
        queryClient.getQueryData<
          TAdHocScreenData | Persisted<TAdHocScreenData>
        >(screenQueryKeys.batch)
      ),
    {
      staleTime: Infinity,
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      ...options
    }
  )

  return {
    stream: stream,
    query: query,
    invoke: invoke,
    reset: reset,
    cancel: cancel
  }
}
