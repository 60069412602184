import React from 'react'
import { styled } from '@mui/material/styles'

const Root = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const Placeholder: React.FC = ({ children }) => {
  return (
    <Root>
      {/* <Typography
                align='center'
                variant='h5'
                paragraph
            >
                {props.message}
            </Typography> */}
      {children}
    </Root>
  )
}

export default Placeholder
