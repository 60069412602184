import React from 'react'

/** Type definition for a byte array representing a serializeable file object*/
export type TSerializeableFileData = number[] | null

/**
 * Hook for creating a serializeable array representation of a file buffer.
 *
 * @param {File} file - The file object.
 * @returns {TSerializeableFileData} - The file data as a byte array.
 */
export const useSerializeableFileData = (
  file: File | null
): TSerializeableFileData => {
  const [fileData, setFileData] = React.useState<TSerializeableFileData>(null)

  React.useEffect(() => {
    if (file !== null) {
      file.arrayBuffer().then((arrayBuffer) => {
        setFileData(Array.from(new Uint8Array(arrayBuffer)))
      })
    }
  }, [file])

  return fileData
}
