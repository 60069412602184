import { useUpdateScheduledJob, TScheduledJob } from '@/lib/features/job'
import { TServerError } from '@/lib/gRPC'
import {
  getDisabledDropDownCategories,
  useListSourcesDropDownOptions,
  selectedListSourcesOptionsIDs,
  translateListSourceSelections
} from '@/components/lib/ListSourcesDropDown'
import { useNotification } from '@/lib/notification'
import JobForm, { IFormValues } from './JobForm'
import { useHistory } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import { handleServerErrors, validateFn } from './validation'
import { trackEvent } from '@/lib/analytics/ga4'
import { useAbility } from '@/lib/authorization'
import {
  DEFAULT_DOB_MONTHS_RANGE,
  FUZZY_DOB_DEFAULT_MONTHS_RANGE,
  FUZZY_DOB_TOGGLE_DEFAULT,
  WEAK_AKAS_DEFAULT
} from '@/lib/constants/search'

type TEditJobFormProps = {
  job: Persisted<TScheduledJob>
}

export const EditJobForm = ({ job }: TEditJobFormProps): JSX.Element => {
  const ability = useAbility()
  const listSourcesDropDownOptions = useListSourcesDropDownOptions(
    {
      category: new Set(job.listSourceCategoryIDs),
      'list-source': new Set(job.listSourceIDs),
      list: new Set(job.listIDs)
    },
    getDisabledDropDownCategories((subject: string) =>
      ability.cannot('manage', 'Jobs', subject)
    )
  )
  const updateScheduledJob = useUpdateScheduledJob()
  const setNotification = useNotification()
  const history = useHistory()

  const showNotificationAndRedirect = () => {
    setNotification({
      message: 'Scheduled screen updated',
      variant: 'success'
    })

    history.push('/jobs')
  }

  const handleSubmit = (currentFilename: string) => (
    values: IFormValues,
    actions: FormikHelpers<IFormValues>
  ) => {
    const filename = values.screenEntities?.name || currentFilename
    if (!values.schedule || !filename) {
      return
    }

    trackEvent('edit_scheduled_job_submitted')
    const listSourceSelections = translateListSourceSelections(
      selectedListSourcesOptionsIDs(listSourcesDropDownOptions.options)
    )
    updateScheduledJob.mutate(
      {
        job: {
          id: job.id,
          name: values.name,
          schedule: values.schedule,
          filename: filename,
          ...listSourceSelections,
          minScore: values.scoreThreshold / 100,
          weakAKAs: values.weakAKAs,
          dobMonthsRange: values.fuzzyDOB
            ? FUZZY_DOB_DEFAULT_MONTHS_RANGE
            : DEFAULT_DOB_MONTHS_RANGE
        },
        file: values.screenEntities ?? undefined
      },
      {
        onSuccess: showNotificationAndRedirect,
        onError: (error: TServerError) => handleServerErrors(error, actions)
      }
    )
  }

  return (
    <JobForm
      initialValues={{
        name: job.name,
        schedule: job.schedule,
        scoreThreshold: Math.trunc(job.minScore * 100),
        screenEntities: null,
        weakAKAs: WEAK_AKAS_DEFAULT,
        fuzzyDOB: FUZZY_DOB_TOGGLE_DEFAULT
      }}
      listSourcesDropDownOptions={listSourcesDropDownOptions}
      filename={job.filename}
      validate={validateFn(true)}
      onSubmit={handleSubmit(job.filename)}
    />
  )
}
