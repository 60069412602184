import React from 'react'
import { styled } from '@mui/material/styles'
import { Redirect } from 'react-router'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'

import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'

import TeaserLink from '@/components/lib/Auth/TeaserLink'
import CustomLink from '@/components/lib/CustomLink'
import { useFormField } from '@/lib/hooks'
import { useNotification } from '@/lib/notification'
import { EnterpriseLink } from '@/components/lib/SupportDialog'

const StyledLink = styled(Link)(() => ({
  cursor: 'pointer'
}))

const SearchWrapper = styled(Grid)(({ theme }) => ({
  '& label': {
    color: theme.palette.primary.dark
  },
  '& label.Mui-focused': {
    color: theme.palette.primary.dark
  },
  '& .MuiOutlinedInput-root': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    '& fieldset': {
      borderColor: theme.palette.text.primary
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.dark
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.text.primary
    },
    '&.Mui-focused:hover fieldset': {
      borderColor: theme.palette.primary.dark
    },
    '& .MuiOutlinedInput-notchedOutline': {
      boxShadow: '0px 0px 6px #BBBBBB'
    }
  }
}))

const SearchFormWrapper = styled('div')(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'column',
  display: 'flex',
  '& hr': {
    margin: theme.spacing(0, 1)
  },
  '& .MuiLink-root': {
    fontSize: '14px'
  }
}))

const searchSuggestions = [
  { groupLabel: 'Names to explore:', label: 'Vladimir Putin' },
  { groupLabel: 'Names to explore:', label: 'Abd al Salam Said Jan' },
  { groupLabel: 'Names to explore:', label: 'Tornado Cash' },
  { groupLabel: 'Names to explore:', label: 'Adrian Darya 1' },
  { groupLabel: 'Names to explore:', label: 'Alisher Usmanov' },
  { groupLabel: 'Names to explore:', label: '長安海連技術有限公司' }
]

const SearchForm: React.FC = () => {
  const [nameField, setNameField] = useFormField<string>('Name', '')
  const setNotification = useNotification()
  const [redirect, setRedirect] = React.useState(false)

  const handleNameChange = (e: React.SyntheticEvent, value: string) => {
    if (e && (e.type === 'change' || e.type === 'click')) {
      setNameField({ action: 'value', value: value })
    }
  }

  const handleSearch = () => {
    if (!nameField.value) {
      setNotification({
        variant: 'info',
        message: 'Please enter some search criteria'
      })
    } else {
      setRedirect(true)
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSearch()
    }
  }

  if (redirect) {
    return <Redirect push to={`/search?name=${nameField.value}`} />
  }

  return (
    <SearchFormWrapper>
      <SearchWrapper
        container
        spacing={2}
        justifyContent='center'
        alignItems='flex-start'
      >
        <Grid item xs={12} md={9}>
          <Autocomplete
            fullWidth
            freeSolo
            value={nameField.value}
            options={searchSuggestions}
            filterOptions={(options) => options}
            inputValue={nameField.value}
            onKeyDown={handleKeyPress}
            onInputChange={handleNameChange}
            getOptionLabel={(option) => (option.label ? option.label : '')}
            groupBy={(option) => option.groupLabel}
            renderGroup={(params) => (
              <Box key={params.key}>
                <Typography fontSize={18} fontWeight={550} p={1}>
                  {params.group}
                </Typography>
                {params.children}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                data-cy='search-input'
                placeholder="Find any person or entity's financial crime risk"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        </Grid>

        <Grid item>
          <Grid container alignItems='center'>
            <Link component={CustomLink} to='/search' underline='hover'>
              Advanced Search
            </Link>

            <Divider orientation='vertical' />

            <TeaserLink
              action='create'
              subject='BatchSearch'
              routerLinkProps={{ component: CustomLink, to: '/screen/persons' }}
              component={(props) => (
                <StyledLink {...props} underline='hover'></StyledLink>
              )}
            >
              Batch Screening
            </TeaserLink>

            <Divider orientation='vertical' />

            <TeaserLink
              action='view'
              subject='Jobs'
              routerLinkProps={{ component: CustomLink, to: '/jobs' }}
              component={(props) => (
                <StyledLink {...props} underline='hover'></StyledLink>
              )}
            >
              Scheduled Screening
            </TeaserLink>

            <Divider orientation='vertical' />

            <TeaserLink
              action='update'
              subject='Workflow'
              routerLinkProps={{ component: CustomLink, to: '/audit-trail' }}
              component={(props) => (
                <StyledLink {...props} underline='hover'></StyledLink>
              )}
            >
              Audit
            </TeaserLink>

            <Divider orientation='vertical' />

            <TeaserLink
              action='view'
              subject='Report'
              routerLinkProps={{ component: CustomLink, to: '/reports' }}
              dialogBody={<EnterpriseLink />}
              component={(props) => (
                <StyledLink {...props} underline='hover'></StyledLink>
              )}
            >
              Reports
            </TeaserLink>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container alignItems='center' justifyContent='center'>
            <Grid item>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                onClick={handleSearch}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </SearchWrapper>
    </SearchFormWrapper>
  )
}

export default SearchForm
