import { useAuth0 } from '@auth0/auth0-react'
import { useQuery, UseQueryResult } from 'react-query'

/** Interface for branding spec */
export interface IBranding {
  company: string
  tagline: string
  title: string
  faviconURI: string
  logoURI: string
  bannerURI: string
  showAds: boolean
  hideThankYouPage?: boolean
}

/** useBranding() return type which is a react-query QueryResult */
export type TBrandingQuery = UseQueryResult<IBranding>

/**
 * Fetch and parse the branding spec JSON file from our CDN.
 *
 * @param {string} path - The relative path to the branding spec file
 */
const fetchBrandingSpec = async (path: string) => {
  const abortController = new AbortController()
  return fetch(`${import.meta.env.VITE_APP_CDN_PATH}/${path}`, {
    signal: abortController.signal,
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((resp) => resp.json())
}

/**
 * Hook for fetching and caching branding information i.e. company name, tagline as well as paths
 * to any other resources in our CDN such as images.
 *
 * @returns {QueryResult<IBranding>}
 */
export const useBranding = (): TBrandingQuery => {
  const { user } = useAuth0()
  const branding = user ? user['http://castellum.ai/branding'] : 'default'
  const specPath = `branding/${branding}/spec.json`

  return useQuery(specPath, () => fetchBrandingSpec(specPath), {
    retry: 3,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })
}

export default useBranding
