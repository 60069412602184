import { useGRPC, TSerializeableFileData } from '@/lib/hooks'
import { File, ValidateBatchFileRequest } from 'proto-js'

/**
 * Hook providing the RPC for validating the batch screening file.
 *
 * @returns {(_key: string, buffer: number[] | null) => Promise<boolean>} - The RPC.
 */
export const useValidateBatchFileRPC = (): ((
  fileData: TSerializeableFileData | null
) => Promise<null>) => {
  const { workflowPromiseService } = useGRPC()

  return async (fileData: TSerializeableFileData | null): Promise<null> => {
    if (fileData) {
      const file = new File()
      const req = new ValidateBatchFileRequest()
      file.setBytes(new Uint8Array(fileData))
      req.setFile(file)

      return workflowPromiseService.validateBatchFile(req, {})
    } else {
      return Promise.resolve(null)
    }
  }
}

export default useValidateBatchFileRPC
