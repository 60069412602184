import React from 'react'

import { styled } from '@mui/material/styles'

import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'

import { TAuditTrail, useGetScreenedEntityAudits } from '@/lib/features/audit'
import {
  screenedEntityStatusLabels,
  hitClassificationLabels
} from '@/lib/features/Screening'
import { ContentWithLoader } from '@/components/lib/Loader'
import { formatDate } from '@/lib/utils'
import { EMPTY_DISPLAY_NAME } from '@/lib/constants/entities'

const INITIAL_DATA_HEIGHT = 80

const DataRow = styled(TableRow)(() => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const NameCell = styled(TableCell)(() => ({
  maxWidth: 250
}))

const EmptyMessage = styled(Typography)(() => ({
  textAlign: 'center',
  paddingTop: 15,
  fontSize: 16
}))

const TableWrapper = styled('div')(() => ({
  width: '100%',
  minHeight: INITIAL_DATA_HEIGHT
}))

const NarrativeCell = styled(TableCell)(() => ({
  width: 250,
  maxWidth: 250
}))

const TruncatedCellWrapper = styled('div')(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

export type TAuditRow = {
  classification: string
  score: number
  name: string
  listSource: string
  lists: string[]
  narrative: string
  timestamp?: Date
  url: string
}

const emptyAuditDetails = {
  narrative: '',
  classification: screenedEntityStatusLabels.incomplete
}

function sortByTimestamp(a, b) {
  if (a.timestamp === undefined) {
    return 1
  } else if (b.timestamp === undefined) {
    return -1
  }
  return b.timestamp.getTime() - a.timestamp.getTime()
}

// Sort audit rows by timestamp, oldest first.
// If both hits are unresolved sort by score
function sortAuditRows(a: TAuditRow, b: TAuditRow) {
  if (a.timestamp === undefined && b.timestamp === undefined) {
    return a.score < b.score ? 1 : -1
  }

  return sortByTimestamp(a, b)
}

// Flatten the nested Hit/Audit structure to return one entry
// per table row
function transformData(hits?: TAuditTrail) {
  if (hits === undefined) return undefined

  const results: TAuditRow[] = []

  hits.forEach((hit) => {
    const { audits, ...otherHitDetails } = hit

    if (audits.length > 0) {
      audits.forEach((audit) => {
        results.push({
          ...otherHitDetails,
          ...audit,
          classification: hitClassificationLabels[audit.classification]
        })
      })
    } else {
      // If the hit has no audits, return a row with the hit information
      // with the narrative and timestamp blank, and an 'incomplete' status
      results.push({
        ...otherHitDetails,
        ...emptyAuditDetails
      })
    }
  })

  // Sort the results in reverse chronological order
  results.sort(sortAuditRows)

  return results
}

const AuditTableRow: React.FC<{
  row: TAuditRow
}> = ({ row }) => {
  const timestampString = row.timestamp ? formatDate(row.timestamp) : ''
  const roundedScore = Math.trunc(row.score * 100)
  const displayName =
    row.listSource === 'Adverse Media'
      ? row.url
      : row.name || EMPTY_DISPLAY_NAME
  const listNames = row.lists.join(' | ')

  return (
    <DataRow>
      <TableCell>{timestampString}</TableCell>
      <TableCell>{roundedScore}</TableCell>
      <NameCell data-cy='hit-name-cell'>
        <Tooltip title={displayName}>
          <TruncatedCellWrapper>{displayName}</TruncatedCellWrapper>
        </Tooltip>
      </NameCell>
      <TableCell>{row.listSource}</TableCell>
      <TableCell>{listNames}</TableCell>
      <TableCell>{row.classification}</TableCell>
      <NarrativeCell>
        <Tooltip title={row.narrative}>
          <TruncatedCellWrapper>{row.narrative}</TruncatedCellWrapper>
        </Tooltip>
      </NarrativeCell>
    </DataRow>
  )
}
const AuditTableBody: React.FC<{
  data?: TAuditRow[]
}> = ({ data }) => {
  return (
    <Table size='small' aria-label='audit-logs'>
      <TableHead>
        <TableRow>
          <TableCell>Audit Time</TableCell>
          <TableCell>Score</TableCell>
          <TableCell>Match</TableCell>
          <TableCell>List Source</TableCell>
          <TableCell>Lists</TableCell>
          <TableCell>Classification</TableCell>
          <NarrativeCell>Narrative</NarrativeCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((row, i) => (
          <AuditTableRow key={i} row={row} />
        ))}
      </TableBody>
    </Table>
  )
}

const AuditTable: React.FC<{ screenEntityID: number }> = ({
  screenEntityID
}) => {
  const { data, isLoading } = useGetScreenedEntityAudits(screenEntityID)
  const transformedData = transformData(data)

  return (
    <Collapse
      in={transformedData && transformedData.length > 0}
      collapsedSize={INITIAL_DATA_HEIGHT}
    >
      <TableWrapper>
        <ContentWithLoader
          showLoader
          isFetching={isLoading}
          data={transformedData}
          emptyMessage={
            <EmptyMessage data-cy='no-matches-text'>No Matches</EmptyMessage>
          }
        >
          {transformedData === undefined ? null : (
            <AuditTableBody data={transformedData} />
          )}
        </ContentWithLoader>
      </TableWrapper>
    </Collapse>
  )
}

export default AuditTable
