import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import {
  useGetAuditReportRPC,
  TGetAuditReportResponse
} from '@/lib/hooks/api/useGetAuditReportRPC'
import { TAuditTrailDownloadParams } from '@/lib/features/audit'
import { auditQueryKeys } from './queryKeys'

type TQueryOptions = Omit<
  UseQueryOptions<
    TGetAuditReportResponse,
    (string | Record<string, string>)[],
    TGetAuditReportResponse,
    (string | Record<string, string>)[]
  >,
  'queryKey' | 'queryFn'
>

/**
 * useGetAuditReport is a hook for retrieving reports of a given type.
 *
 * @param {TQueryOptions} options - react-query query options.
 * @returns {UseQueryResult<TGetAuditReportResponse>} - The query result..
 */
export const useGetAuditReport = (
  params: TAuditTrailDownloadParams,
  options?: TQueryOptions
): UseQueryResult<TGetAuditReportResponse> => {
  const rpc = useGetAuditReportRPC()

  return useQuery(auditQueryKeys.report(params), () => rpc(params), {
    refetchOnWindowFocus: false,
    ...options
  })
}
