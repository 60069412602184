import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { screenQueryKeys, TAdHocScreenData, THit } from '.'
import { IEntitySearchParams } from '@/types'
import {
  useScreenEntityRPC,
  TScreenEntityRPC
} from '@/lib/hooks/api/useScreenEntityRPC'
import { Hit, ScreenEntityResponse } from 'proto-js'

const queryFunc = (
  params: IEntitySearchParams,
  rpc: TScreenEntityRPC
): Promise<TAdHocScreenData> => {
  return rpc(params).then((resp: typeof ScreenEntityResponse) => {
    const hits = resp
      .getHitsList()
      .map((hit: typeof Hit): THit => ({ protobuf: hit }))
    const status = hits.length === 0 ? 'cleared' : 'incomplete'

    return {
      screen: {
        screenType: 'advanced',
        ranAt: new Date(),
        parameters: {
          listSourceCategories: params.listSourceCategories,
          listSources: params.listSources,
          scoreThreshold: params.scoreThreshold,
          weakAKAs: params.weakAKAs,
          dobMonthsRange: params.dobMonthsRange
        }
      },
      entities: [
        {
          name: params.name,
          idNumber: params.idNum,
          location: params.location,
          dob: params.dob,
          totalHits: resp.getTotalHits(),
          hits: hits,
          status: status
        }
      ]
    }
  })
}

type TQueryOptions = Omit<
  UseQueryOptions<
    TAdHocScreenData,
    [string, string, string, IEntitySearchParams, boolean],
    TAdHocScreenData,
    [string, string, string, IEntitySearchParams, boolean]
  >,
  'queryKey' | 'queryFn'
>

/**
 * Hook for screening entities and caching results via react-query. If the params
 * parameter does not pass a boolean condition the query will be idle.
 *
 * @param {IEntitySearchParams} params - The query parameters.
 * @param {searchActive} boolean - If the query should be run or should return idle
 * @returns {UseQueryResult<TPersistedScreen>} - The query results.
 */
export const useAdvancedSearch = (
  params: IEntitySearchParams,
  searchActive: boolean,
  options?: TQueryOptions
): UseQueryResult<TAdHocScreenData> => {
  const rpc = useScreenEntityRPC()
  const query = useQuery(
    screenQueryKeys.advanced(params, searchActive),
    () => queryFunc(params, rpc),
    {
      enabled: searchActive,
      staleTime: Infinity,
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      ...options
    }
  )

  return query
}
