import React from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'

const StyledGrid = styled(Grid)(() => ({
  margin: '10px',
  minWidth: '100%'
}))

const Spinner: React.FC = () => (
  <StyledGrid
    container
    spacing={0}
    alignItems='center'
    direction='column'
    justifyContent='center'
  >
    <CircularProgress size={30} />
  </StyledGrid>
)

export default Spinner
