import { Relationship, RelationshipWithShares, ShareDetails } from './types'
import { Relationship as pbRelationship } from 'proto-js'

const HAS_BENEFICIAL_OWNER = 'Has Sanctioned Beneficial Owner'

/* Comparison function:
   B has a higher value than A iff B is set and A isn't, or both are set and B > A.
   Returns null if both values are null. */
const valBHigherThanA = (valA?: number, valB?: number): number | null => {
  if (valA) {
    if (!valB) {
      return -1
    }

    return valB - valA
  } else if (valB) {
    return 1
  }

  return null
}

type ShareInformation = {
  numShares?: number
  percentage?: number
}

/* Sort share information by percentage when available, falling back to number of shares.
   Higher values first. */
export const sortByPercentageNumber = (
  shareInfoA: ShareInformation,
  shareInfoB: ShareInformation
): number => {
  const { percentage: percentageA, numShares: numberOfSharesA } = shareInfoA
  const { percentage: percentageB, numShares: numberOfSharesB } = shareInfoB

  const bHasHigherPercentage = valBHigherThanA(percentageA, percentageB)

  if (bHasHigherPercentage !== null) {
    return bHasHigherPercentage
  }

  const bHasHigherShares = valBHigherThanA(numberOfSharesA, numberOfSharesB)

  if (bHasHigherShares !== null) {
    return bHasHigherShares
  }

  return 0
}

/* Sort by elevated status before sorting by percentage and number of shares */
export const sortByStatusPercentageNumber = (
  relationshipA: RelationshipWithShares,
  relationshipB: RelationshipWithShares
): number => {
  const {
    elevatedStatus: statusA,
    maxPercentage: percentageA,
    maxNumShares: numberOfSharesA
  } = relationshipA
  const {
    elevatedStatus: statusB,
    maxPercentage: percentageB,
    maxNumShares: numberOfSharesB
  } = relationshipB

  if (statusA === statusB) {
    return sortByPercentageNumber(
      {
        percentage: percentageA,
        numShares: numberOfSharesA
      },
      {
        percentage: percentageB,
        numShares: numberOfSharesB
      }
    )
  }

  return statusA ? -1 : 1
}

export const convertRelationshipsToJson = (
  relationships?: typeof pbRelationship[]
): Relationship[] => {
  if (!relationships) {
    return []
  }

  return relationships.map((relationship) => {
    const { sharesList, ...otherFields } = relationship.toObject()

    return {
      ...otherFields,
      shares: sharesList
    }
  })
}

type CategorizedShareInformation = {
  upstream: RelationshipWithShares[]
  downstream: RelationshipWithShares[]
  uncategorized: Relationship[]
  indirect: Relationship[]
}

/* If any shares are present, sort them and push them onto the
shareList. */
const assignAndSortShares = (
  shares: ShareDetails[],
  categorizedRelations: Set<string>,
  shareList: RelationshipWithShares[],
  elevatedStatus: boolean,
  name: string,
  relation: string
) => {
  if (!shares.length) {
    return
  }

  shares.sort(sortByPercentageNumber)

  shares.forEach((share) => categorizedRelations.add(share.relation))

  shareList.push({
    elevatedStatus,
    name,
    relation,
    maxPercentage: shares[0].percentage,
    maxNumShares: shares[0].numShares,
    shares: shares
  })
}

/* Categorize Share Information into three buckets:
  Those with upstream ownership information (upstream)
  Those with downstream ownership information (downstream)
  Those without ownership information (uncategorized).

  Returns information sorted by highest ownership, including the nested
  shares array. */
export const buildShareTableInformation = (
  relationships: Relationship[]
): CategorizedShareInformation => {
  const upstreamDetails: RelationshipWithShares[] = []
  const downstreamDetails: RelationshipWithShares[] = []
  const uncategorized: Relationship[] = []
  const indirect: Relationship[] = []

  relationships.forEach(
    ({ name, degree, elevatedStatus, relation, shares }) => {
      shares = shares || []
      const upstreamShares: ShareDetails[] = []
      const downstreamShares: ShareDetails[] = []
      const relationshipSubparts = new Set(relation.split(', '))
      const categorizedRelations = new Set<string>()

      shares.forEach((share) => {
        // Ie Shareholder Of, Owner Of, Beneficial Owner Of
        if (share.relation.endsWith('Of')) {
          downstreamShares.push(share)
        } else {
          upstreamShares.push(share)
        }
      })

      assignAndSortShares(
        upstreamShares,
        categorizedRelations,
        upstreamDetails,
        elevatedStatus,
        name,
        relation
      )
      assignAndSortShares(
        downstreamShares,
        categorizedRelations,
        downstreamDetails,
        elevatedStatus,
        name,
        relation
      )

      if (degree && degree > 1) {
        indirect.push({
          name,
          elevatedStatus,
          relation: HAS_BENEFICIAL_OWNER,
          degree: degree
        })

        relationshipSubparts.delete(HAS_BENEFICIAL_OWNER)
      }

      if (categorizedRelations.size !== relationshipSubparts.size) {
        // TODO: Replace in Node 22 with set.difference
        const uncategorizedRelations = new Set(
          [...relationshipSubparts].filter((x) => !categorizedRelations.has(x))
        )

        uncategorized.push({
          name,
          elevatedStatus,
          relation: [...uncategorizedRelations].join(', ')
        })
      }
    }
  )

  return {
    upstream: upstreamDetails.sort(sortByStatusPercentageNumber),
    downstream: downstreamDetails.sort(sortByStatusPercentageNumber),
    uncategorized: uncategorized,
    indirect: indirect
  }
}
