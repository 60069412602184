import React from 'react'
import { styled, useTheme } from '@mui/material/styles'

import Avatar from '@mui/material/Avatar'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText, { ListItemTextProps } from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import Highlight from '@/components/lib/Highlight'

import BusinessSharp from '@mui/icons-material/BusinessSharp'
import DirectionsBoatSharp from '@mui/icons-material/DirectionsBoatSharp'
import FlightSharp from '@mui/icons-material/FlightSharp'
import MonetizationOnSharp from '@mui/icons-material/MonetizationOnSharp'
import HelpSharp from '@mui/icons-material/HelpSharp'
import PersonSharp from '@mui/icons-material/PersonSharp'
import PlaceSharp from '@mui/icons-material/PlaceSharp'
import Newspaper from '@mui/icons-material/Newspaper'
import Inventory2Sharp from '@mui/icons-material/Inventory2Sharp'
import ShowChartSharp from '@mui/icons-material/ShowChartSharp'

import { EMPTY_DISPLAY_NAME } from '@/lib/constants/entities'

const HitAvatar = styled(Avatar)(() => ({
  backgroundColor: '#000'
}))

const ScoreText = styled(Typography)(() => ({
  minWidth: '15%'
}))

const StyledListItem = styled(ListItemText, {
  shouldForwardProp: (props) => props !== 'truncate'
})<ListItemTextProps & { truncate: boolean }>(({ theme, truncate }) => ({
  [`.highlight`]: {
    color: theme.palette.error.main
  },
  marginRight: theme.spacing(2),
  marginTop: 0,
  ...(truncate && {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  })
}))

export const getEntityIcon = (entityType: string): JSX.Element => {
  switch (entityType) {
    case 'media':
      return <Newspaper />
    case 'individual':
      return <PersonSharp />
    case 'entity':
      return <BusinessSharp />
    case 'aircraft':
      return <FlightSharp />
    case 'vessel':
      return <DirectionsBoatSharp />
    case 'location':
      return <PlaceSharp />
    case 'crypto':
      return <MonetizationOnSharp />
    case 'shipment':
      return <Inventory2Sharp />
    case 'security':
      return <ShowChartSharp />
    default:
      return <HelpSharp />
  }
}

type THitListItem = {
  entityType: string
  name: string
  secondaryText: string | React.ReactElement
  score: number
  truncate?: boolean
}

const HitListItem: React.FC<THitListItem> = ({
  entityType,
  name,
  secondaryText,
  score,
  truncate = true
}) => {
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  const displayName = name || EMPTY_DISPLAY_NAME

  return (
    <>
      <ListItemAvatar>
        <HitAvatar>{getEntityIcon(entityType)}</HitAvatar>
      </ListItemAvatar>

      <StyledListItem
        inset={smUp}
        primary={<Highlight str={displayName} />}
        secondary={secondaryText}
        truncate={truncate}
      />
      <ScoreText>Score: {score}</ScoreText>
    </>
  )
}

export default HitListItem
