import React from 'react'

import { HitsListPaginated } from '@/components/lib/HitsList'
import WorkflowDialog from '@/components/lib/Workflow'
import { useDialog } from '@/lib/hooks'
import { AuditTrailDialog } from '@/components/lib/AuditTrail'
import { TScreenData, isPersistedScreenedEntity } from '@/lib/features/Screening'
import { ExcelReportButton } from '@/components/lib/Reporting'

interface ResultsListProps {
  screenData: TScreenData
  selected?: number
  onSelect: (idx: number | null) => void
  listSourceQueryString: string
  reportButton: JSX.Element
}

const ResultsList: React.FC<ResultsListProps> = ({
  screenData,
  selected,
  onSelect,
  listSourceQueryString,
  reportButton
}) => {
  const workflowDialog = useDialog()
  const auditTrailDialog = useDialog()
  const screenedEntity = screenData.entities[0]

  return (
    <>
      <HitsListPaginated
        hits={screenedEntity.hits}
        onSelect={onSelect}
        listSourceQueryString={listSourceQueryString}
        openWorkflow={workflowDialog.onOpen}
        openAudit={auditTrailDialog.onOpen}
        reportButton={reportButton}
        selected={selected}
        excelButton={
          <ExcelReportButton
            reportType='Advanced Search'
            entityItems={[{ index: 0, screenEntity: screenedEntity }]}
          />
        }
        totalHits={screenedEntity.totalHits}
        persisted={'id' in screenedEntity}
      />
      {isPersistedScreenedEntity(screenedEntity) && (
        <AuditTrailDialog
          screenedEntityID={screenedEntity.id}
          open={auditTrailDialog.open}
          onClose={auditTrailDialog.onClose}
        />
      )}

      <WorkflowDialog
        {...workflowDialog}
        screenData={screenData}
        screenedEntityIndex={0}
      />
    </>
  )
}

export default ResultsList
