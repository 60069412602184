import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'

import cronstrue from 'cronstrue'
import { localIANATimezone } from '@/lib/utils'

import { TDialog } from '@/lib/hooks'
import { TCRONExpression, TSchedule } from '@/lib/features/job'
import Scheduler from './Scheduler'

const cronToString = (cron: TCRONExpression): string =>
  [cron.minute, cron.hour, cron.dayOfMonth, cron.month, cron.dayOfWeek].join(
    ' '
  )

export const scheduleToSentence = (schedule: TSchedule): string =>
  `${cronstrue.toString(cronToString(schedule.cron), {
    verbose: true
  })} (${schedule.timezone.replace('_', ' ')})`

const defaultSchedule = {
  cron: {
    minute: 0,
    hour: 9,
    dayOfMonth: '*',
    month: '*',
    dayOfWeek: '*'
  },
  timezone: localIANATimezone()
}

type TSchedulerDialogProps = {
  dialog: TDialog
  onSave: (schedule: TSchedule) => void
}

const SchedulerDialog = ({
  dialog,
  onSave
}: TSchedulerDialogProps): JSX.Element => {
  const [schedule, setSchedule] = React.useState(defaultSchedule)
  const [isError, setError] = React.useState(false)
  React.useEffect(() => {
    setSchedule(defaultSchedule)
  }, [dialog.open])

  const handleSetCRON = (cron: TCRONExpression) => {
    setSchedule({
      ...schedule,
      cron: cron
    })
  }

  const handleSave = () => {
    onSave(schedule)
    dialog.onClose()
  }

  const handleCancel = () => {
    dialog.onClose()
  }

  const scheduleString = isError ? '' : scheduleToSentence(schedule)

  return (
    <>
      <Dialog open={dialog.open}>
        <DialogTitle>Schedule</DialogTitle>
        <DialogContent>
          <DialogContentText>{scheduleString}</DialogContentText>
          <Box m='16px'>
            <Scheduler
              cron={schedule.cron}
              onChange={handleSetCRON}
              onError={setError}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSave} variant='contained' disabled={isError}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SchedulerDialog
