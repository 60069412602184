import { WaveLoader } from '@/components/lib/Loader'

interface TContentWithLoader<Row> {
  children: null | JSX.Element
  data?: Row[]
  emptyMessage?: JSX.Element
  isFetching: boolean
  showLoader: boolean
}

const ContentWithLoader = <Row extends Record<string, unknown>>({
  children,
  data,
  emptyMessage,
  isFetching,
  showLoader = false
}: TContentWithLoader<Row>): JSX.Element | null => {
  const contentHasNotLoaded = isFetching || data === undefined
  const totalRows = data?.length || 0

  if (showLoader && contentHasNotLoaded) {
    return <WaveLoader />
  } else if (emptyMessage !== undefined && totalRows === 0) {
    return emptyMessage
  }

  return children
}

export default ContentWithLoader
