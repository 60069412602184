import { Dispatch } from 'react'
import {
  THitClassification,
  THitClassificationReason
} from '@/lib/features/Screening'

export type TWorkflowConsumer = {
  dispatch: Dispatch<TWorkflowAction>
  state: TWorkflowState
}

export type TWorkflowState = {
  selectedHitIndex: number
  stepIdx: number
  checkedHitIndices: number[]
  classification: THitClassification
  matchType: string
  reasons: Set<THitClassificationReason>
  analysis: string
}

export type TWorkflowAction =
  | { type: 'SELECT_HIT'; payload: number }
  | { type: 'CHECK_MULTIPLE_HITS'; payload: number[] }
  | { type: 'CHECK_HIT'; payload: number }
  | { type: 'UNCHECK_HIT'; payload: number }
  | { type: 'SET_STEP'; payload: number }
  | { type: 'CLASSIFY'; payload: THitClassification }
  | { type: 'SET_MATCH_TYPE'; payload: string }
  | { type: 'SET_REASONS'; payload: Set<THitClassificationReason> }
  | { type: 'SET_ANALYSIS'; payload: string }
  | { type: 'RESET_AUDIT' }
  | { type: 'RESET_CHECKED' }
  | { type: 'RESET' }

export const initialWorkflowState: TWorkflowState = {
  selectedHitIndex: 0,
  stepIdx: 0,
  checkedHitIndices: [],
  classification: 'true-positive',
  matchType: 'individual',
  reasons: new Set<THitClassificationReason>(),
  analysis: ''
}

const workflowReducer = (
  state: TWorkflowState,
  action: TWorkflowAction
): TWorkflowState => {
  switch (action.type) {
    case 'SELECT_HIT':
      return { ...state, selectedHitIndex: action.payload }
    case 'CHECK_MULTIPLE_HITS':
      return { ...state, checkedHitIndices: action.payload }
    case 'CHECK_HIT':
      return {
        ...state,
        checkedHitIndices: [...state.checkedHitIndices, action.payload]
      }
    case 'UNCHECK_HIT': {
      const newHits = state.checkedHitIndices.filter(
        (id: number) => id !== action.payload
      )

      return { ...state, checkedHitIndices: newHits }
    }
    case 'SET_STEP':
      return { ...state, stepIdx: action.payload }
    case 'CLASSIFY':
      return { ...state, classification: action.payload }
    case 'SET_MATCH_TYPE':
      return { ...state, matchType: action.payload.toLowerCase() }
    case 'SET_REASONS':
      return { ...state, reasons: action.payload }
    case 'SET_ANALYSIS':
      return { ...state, analysis: action.payload }
    case 'RESET_AUDIT':
      // Persist the checkbox state, but reset everything else
      return {
        ...initialWorkflowState,
        selectedHitIndex: state.selectedHitIndex,
        checkedHitIndices: [...state.checkedHitIndices]
      }
    case 'RESET_CHECKED':
      return {
        ...initialWorkflowState,
        selectedHitIndex: state.selectedHitIndex,
        checkedHitIndices: []
      }
    case 'RESET':
      return { ...initialWorkflowState }
    default:
      throw new Error()
  }
}

export default workflowReducer
