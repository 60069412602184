// https://mui.com/material-ui/react-table/#CustomPaginationActionsTable.tsx

import React from 'react'

import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import DownloadIcon from '@mui/icons-material/Download'
import Tooltip from '@mui/material/Tooltip'

interface PaginationActionsProps {
  refetch: () => void
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void
}

export const MAX_DOWNLOADABLE_RECORDS = 100000
export const PAGE_SIZE_OPTIONS = [25, 50]
export const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[0]
// Pagination is zero based
// https://stackoverflow.com/questions/61860320/material-ui-table-pagination-is-zero-based-index-how-to-make-it-non-zero
export const FIRST_PAGE = 0

type TDownloadReportButton = {
  disabled: boolean
  isFetching: boolean
  onClick: () => void
}

function DownloadReportButton({
  disabled = false,
  isFetching = false,
  onClick
}: TDownloadReportButton): JSX.Element {
  return (
    <Tooltip
      title={
        disabled &&
        'Download limited to 100,000 screened entries.  Please refine search to reduce screened entries shown to 100,000.'
      }
      placement='top'
    >
      <span data-cy='download-tooltip-trigger'>
        <IconButton
          disabled={disabled || isFetching}
          aria-label='download'
          onClick={onClick}
        >
          <DownloadIcon />
        </IconButton>
      </span>
    </Tooltip>
  )
}

type TPaginationButton = IconButtonProps & {
  rightIcon: JSX.Element
  leftIcon: JSX.Element
}

function PaginationButton({
  rightIcon,
  leftIcon,
  ...buttonProps
}: TPaginationButton): JSX.Element {
  const theme = useTheme()

  return (
    <IconButton {...buttonProps}>
      {theme.direction === 'rtl' ? rightIcon : leftIcon}
    </IconButton>
  )
}

type TPaginationActions = PaginationActionsProps & {
  refetch: () => void
  isFetching: boolean
}

function PaginationActions({
  count,
  isFetching,
  onPageChange,
  page,
  rowsPerPage,
  refetch
}: TPaginationActions): JSX.Element {
  const lastPageIndex = Math.ceil(count / rowsPerPage) - 1

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, FIRST_PAGE)
  }

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, lastPageIndex))
  }

  const downloadDisabled = count > MAX_DOWNLOADABLE_RECORDS
  const isFirstPage = page === FIRST_PAGE
  const isLastPage = page >= lastPageIndex

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <PaginationButton
        onClick={handleFirstPageButtonClick}
        disabled={isFirstPage}
        aria-label='first page'
        leftIcon={<FirstPageIcon />}
        rightIcon={<LastPageIcon />}
      />
      <PaginationButton
        onClick={handleBackButtonClick}
        disabled={isFirstPage}
        aria-label='previous page'
        leftIcon={<KeyboardArrowLeft />}
        rightIcon={<KeyboardArrowRight />}
      />
      <PaginationButton
        onClick={handleNextButtonClick}
        disabled={isLastPage}
        aria-label='next page'
        leftIcon={<KeyboardArrowRight />}
        rightIcon={<KeyboardArrowLeft />}
      />
      <PaginationButton
        onClick={handleLastPageButtonClick}
        disabled={isLastPage}
        aria-label='last page'
        leftIcon={<LastPageIcon />}
        rightIcon={<FirstPageIcon />}
      />
      <DownloadReportButton
        isFetching={isFetching}
        disabled={downloadDisabled}
        onClick={refetch}
      />
    </Box>
  )
}

export default PaginationActions
