import { styled } from '@mui/material/styles'
import Skeleton from '@mui/material/Skeleton'

export const Loader = styled('div')(() => ({
  textAlign: 'center',
  width: '100%'
}))

const WaveLoader = (): JSX.Element => (
  <Loader data-cy='wave-loader'>
    <Skeleton animation='wave' />
    <Skeleton animation='wave' />
    <Skeleton animation='wave' />
  </Loader>
)

export default WaveLoader
