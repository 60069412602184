import { createRoot } from 'react-dom/client';
import './index.css'
import App from '@/components/App'
import * as serviceWorker from './serviceWorker'
import { Auth0Provider, CacheLocation } from '@auth0/auth0-react'
import history from '@/components/App/history'
import { trackEvent } from '@/lib/analytics/ga4'

import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react'

type AppState = {
  returnTo?: string | undefined
  [key: string]: string | undefined
}

const config = {
  apiKey: import.meta.env.VITE_APP_HONEYBADGER_KEY,
  environment: import.meta.env.MODE ?? import.meta.env.NODE_ENV,
  revision: import.meta.env.VITE_APP_GIT_COMMIT
}

const honeybadger = Honeybadger.configure(config)

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState?: AppState | undefined) => {
  trackEvent('login')
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

if (
  !import.meta.env.VITE_APP_AUTH_DOMAIN ||
  !import.meta.env.VITE_APP_AUTH_CLIENT_ID
) {
  throw new Error('Missing required environment variable!')
}

const cache = import.meta.env.VITE_APP_AUTH_STORAGE as CacheLocation

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <HoneybadgerErrorBoundary honeybadger={honeybadger}>
    <Auth0Provider
      domain={import.meta.env.VITE_APP_AUTH_DOMAIN}
      clientId={import.meta.env.VITE_APP_AUTH_CLIENT_ID}
      authorizationParams={{ redirect_uri: window.location.origin, audience: import.meta.env.VITE_APP_AUTH_AUDIENCE }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={cache}
    >
      <App />
    </Auth0Provider>
  </HoneybadgerErrorBoundary>
)

serviceWorker.unregister()
