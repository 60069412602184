import React from 'react'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'

import { useTheme } from '@mui/material/styles'

import { TCRONExpression } from '@/lib/features/job'

type TOrdinal = '1' | '2' | '3' | '4' | '5'

type TDay = '0' | '1' | '2' | '3' | '4' | '5' | '6'

const ordinalLabels: Record<TOrdinal, string> = {
  1: 'First',
  2: 'Second',
  3: 'Third',
  4: 'Fourth',
  5: 'Fifth'
}

const dayLabels: Record<TDay, string> = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
}

type TDayOfTheWeekProps = {
  cron: TCRONExpression
  onChange: (cron: TCRONExpression) => void
}

/**
 * DayOfTheWeek allows users to specify a day of the week component of a monthly schedule e.g.
 * The first Monday of every month.
 *
 * We deliberatly don't enable certain non-standard CRON features that are known to not be
 * supported by our back-end tooling. This includes use of the 'L' symbol to specify the last day
 * of the month. This also includes specifying multiple days of the week e.g. the first Monday and
 * Tuesday of the month via '1,2#1'.
 *
 * @component
 */
const DayOfTheWeek = ({ cron, onChange }: TDayOfTheWeekProps): JSX.Element => {
  const [enabled, setEnabled] = React.useState(false)
  const [ordinal, setOrdinal] = React.useState<TOrdinal>('1')
  const [day, setDay] = React.useState<TDay>('1')
  const theme = useTheme()

  // Update the cron expression whenever the form input changes.
  React.useEffect(() => {
    const dayOfWeekStr = `${day}#${ordinal}`
    onChange({
      ...cron,
      dayOfWeek: enabled ? dayOfWeekStr : '*'
    })
  }, [enabled, ordinal, day])

  const handleToggleEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnabled(event.target.checked)
  }

  const handleSelectOrdinal = (event: SelectChangeEvent<TOrdinal>) => {
    setOrdinal(event.target.value as TOrdinal)
  }

  const handleSelectDay = (event: SelectChangeEvent<TDay>) => {
    if (event.target.value.length !== 0) {
      setDay(event.target.value as TDay)
    }
  }

  return (
    <>
      <Stack
        direction='row'
        spacing={1}
        flexWrap='wrap'
        sx={{ marginBottom: `${theme.spacing(2)}` }}
      ></Stack>

      <Stack direction='row' spacing={1}>
        <Switch
          inputProps={{ 'aria-label': 'Day of the week switch' }}
          onChange={handleToggleEnabled}
        />

        <FormControl disabled={!enabled} fullWidth>
          <InputLabel>The</InputLabel>
          <Select
            inputProps={{ 'aria-label': 'Ordinal drop-down' }}
            label='The'
            onChange={handleSelectOrdinal}
            value={ordinal}
            sx={{ height: '40px' }}
          >
            {Object.entries(ordinalLabels).map(([ordinal, label], idx) => (
              <MenuItem key={idx} value={ordinal}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl disabled={!enabled} fullWidth>
          <Select
            inputProps={{ 'aria-label': 'Day of the week drop-down' }}
            onChange={handleSelectDay}
            sx={{ height: '40px' }}
            value={day}
          >
            {Object.entries(dayLabels).map((e, idx) => (
              <MenuItem key={idx} value={e[0]}>
                {e[1]}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>of the month</FormHelperText>
        </FormControl>
      </Stack>
    </>
  )
}

export default DayOfTheWeek
