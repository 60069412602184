import { ICountry } from '@/types'
import { getCountryName } from '@/lib/entity'
import { useGRPC } from '@/lib/hooks'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { Country, GetCountriesResponse } from 'proto-js'

/** The processed countries response type. */
type TResp = ICountry[]

/**
 * Format the RPC response and create a list of ICountry types.
 *
 * @param {typeof GetCountriesResponse} resp - The raw RPC response protobuf message
 * @returns {TResp} - The list of countries
 */
const prepareRPCResponse = (resp: typeof GetCountriesResponse): TResp => {
  const ret = resp.getCountriesList().map((c: typeof Country) => ({
    name: getCountryName(c),
    iso2: c.getIso31661Alpha2()
  }))
  ret.sort((a: ICountry, b: ICountry) => {
    if (a.name < b.name) {
      return -1
    } else if (a.name > b.name) {
      return 1
    }
    return 0
  })
  return ret
}

/**
 * Hook providing the RPC for getting countries information.
 *
 * @returns {() => Promise<typeof ScreenEntityResponse>} - The RPC function
 */
export const useCountriesDataRPC = (): (() => Promise<ICountry[]>) => {
  const { dataPromiseService } = useGRPC()
  return async (): Promise<TResp> => {
    const req = new Empty()
    const md = { deadline: new Date(Date.now() + 2 * 1000).getTime() }
    return dataPromiseService
      .getCountries(req, md)
      .then((resp: typeof GetCountriesResponse) => {
        return prepareRPCResponse(resp)
      })
  }
}

export default useCountriesDataRPC
