import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import { toTitleCase } from '@/lib/utils'
import IDsTable, { WrappedHighlight } from './IDsTable'
import SharesInformation from './SharesInformation'
import { UBO, UBOBackwardsCompatibilityAlias } from '@/lib/constants/list_sources'

import { Relationship } from './types'
import UpgradeLink from '@/components/lib/Auth/UpgradeLink'
import { ID, ListEntry, Name } from 'proto-js'

import { Can } from '@/lib/authorization'
import { EMPTY_DISPLAY_NAME } from '@/lib/constants/entities'
import {
  SectionTitle,
  PaneContent,
  RelationshipLink,
  RiskIcon
} from './../Common'

const RelationshipWrapper = styled(Typography)({
  display: 'flex',
  alignItems: 'center'
})

import { buildShareTableInformation, convertRelationshipsToJson } from './utils'

/**
 * Parses an entity's relationships information and returns two lists of names to render, one for
 * connections i.e. associates without a direct relation type, and one for close associates i.e.
 * associates with a direct relation type.
 *
 * @param {typeof ListEntry} entity - The ListEntry protobuf message.
 * @returns {[ListItem[], ListItem[]]} - Two lists of ListItem components for connections and close
 *  associates
 */
const getRelationshipsLists = (
  isUBO: boolean,
  relationships: Relationship[],
  listSourceQueryString: string
): JSX.Element[] => {
  const namedRelationships = relationships.filter(
    ({ name }) => name && name != ''
  )

  return namedRelationships.map(
    ({ elevatedStatus, degree, name, relation }: Relationship, i: number) => {
      const substring =
        degree && degree > 1
          ? `(${degree} degrees)`
          : `- (${toTitleCase(relation)})`
      return (
        <ListItem key={i}>
          <ListItemText
            primary={
              <RelationshipWrapper variant='subtitle2'>
                <RelationshipLink
                  name={name}
                  listSourceQueryString={listSourceQueryString}
                />
                {relation && (
                  <Typography variant='body2' display='inline'>
                    {` ${substring}`}
                  </Typography>
                )}
                {elevatedStatus && <RiskIcon isUBO={isUBO} />}
              </RelationshipWrapper>
            }
          />
        </ListItem>
      )
    }
  )
}

type TAliasInformation = {
  name: typeof Name
  ids: typeof ID[]
}

const AliasInformation = ({ name, ids }: TAliasInformation) => {
  const displayName = name.getFullName() || EMPTY_DISPLAY_NAME

  return (
    <>
      <ListItem>
        <ListItemText
          primary={
            <Typography variant='subtitle2'>
              <WrappedHighlight str={displayName} />
              {name.getLowQuality() && (
                <Typography variant='body2' display='inline'>
                  {' - (Weak AKA)'}
                </Typography>
              )}
            </Typography>
          }
        />
      </ListItem>
      {ids.length > 0 && <IDsTable ids={ids} />}
    </>
  )
}

interface ProfilePaneProps {
  entity: typeof ListEntry
  listSourceQueryString: string
}
/**
 * Component containing the contents of the 'Profile' tab of the ListEntry Card. This includes and
 * entities aliases and information about relationships.
 *
 * @param {typeof ListEntry} entity - The ListEntry protobuf message.
 */
const ProfilePane = ({
  entity,
  listSourceQueryString = ''
}: ProfilePaneProps): JSX.Element => {
  const {
    upstream,
    downstream,
    uncategorized,
    indirect
  } = buildShareTableInformation(
    convertRelationshipsToJson(entity.getRelationshipsList())
  )
  const isUBO =
    entity.getListSource().getName() == UBO ||
    entity.getListSource().getName() == UBOBackwardsCompatibilityAlias
  const connections = getRelationshipsLists(
    isUBO,
    uncategorized,
    listSourceQueryString
  )

  const ownership = getRelationshipsLists(
    isUBO,
    indirect,
    listSourceQueryString
  )

  let idsPresent = false
  const aliasesListItems = [entity.getName()]
    .concat(entity.getAliasesList())
    .map((alias: typeof Name, i: number) => {
      const idList = alias.getIdsList()

      if (idList.length > 0) idsPresent = true

      return <AliasInformation key={i} name={alias} ids={idList} />
    })

  return (
    <PaneContent>
      {aliasesListItems.length > 0 && (
        <>
          {idsPresent && (
            <Can not I='view' a='Screen' field='id'>
              <Typography align='center'>
                <UpgradeLink additionalText='to see additional ID information.' />
              </Typography>
            </Can>
          )}
          <SectionTitle title='Aliases' />
          <List dense>{aliasesListItems}</List>
        </>
      )}
      {indirect.length > 0 && (
        <>
          <SectionTitle title='Root Ownership' />
          <List data-cy='indirect-relationships' dense>
            {ownership}
          </List>
        </>
      )}
      <SharesInformation
        upstream={upstream}
        listSourceQueryString={listSourceQueryString}
        downstream={downstream}
      />
      {connections.length > 0 && (
        <>
          <SectionTitle title='Connected To' />
          <List data-cy='connections' dense>
            {connections}
          </List>
        </>
      )}
    </PaneContent>
  )
}

export default ProfilePane
