import React from 'react'
import { styled } from '@mui/material/styles'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'

import { TreeItem, SimpleTreeView } from '@mui/x-tree-view'


import Spinner from '@/components/lib/Spinner'
import { useGetScreenedEntityAudits } from '@/lib/features/audit'
import { AuditLog } from './AuditLog'
import { ADVERSE_MEDIA } from '@/lib/constants/list_sources'

const StyledDialog = styled(Dialog)(() => ({
  overflow: 'scroll'
}))

const StyledAuditLog = styled(AuditLog)(() => ({
  paddingBottom: '20px'
}))

type TAuditTrailDialog = {
  screenedEntityID: number
  open: boolean
  onClose: () => void
}

const AuditTrailDialog: React.FC<TAuditTrailDialog> = ({
  screenedEntityID,
  open,
  onClose
}) => {
  const auditsQuery = useGetScreenedEntityAudits(screenedEntityID, {
    enabled: open
  })
  const handleClose = () => {
    onClose()
  }

  const hitItems: JSX.Element[] = []
  const expandedKeys: string[] = []
  let key = 0
  if (auditsQuery.isSuccess) {
    auditsQuery.data.forEach((hit) => {
      const auditLogs: JSX.Element[] = []
      hit.audits.forEach((audit) => {
        key++
        auditLogs.push(<StyledAuditLog key={key} audit={audit} />)
      })
      key++

      hitItems.push(
        <TreeItem
          key={key}
          itemId={key.toString()}
          label={hit.listSource === ADVERSE_MEDIA ? hit.url : hit.name}
        >
          {auditLogs}
        </TreeItem>
      )

      if (hit.audits.length > 0) {
        expandedKeys.push(key.toString())
      }
    })
  }

  return (
    <StyledDialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
      <DialogTitle>Audit Trail</DialogTitle>
      <DialogContent>
        <DialogContentText />
        {auditsQuery.isSuccess && !auditsQuery.isFetching && (
          <SimpleTreeView
            defaultExpandedItems={expandedKeys}
          >
            {hitItems}
          </SimpleTreeView>
        )}
        {auditsQuery.isFetching && <Spinner />}
      </DialogContent>
      <DialogActions />
    </StyledDialog>
  )
}

export default AuditTrailDialog
