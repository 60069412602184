import {
  SLIDER_DEFAULT,
  WEAK_AKAS_DEFAULT,
  FUZZY_DOB_TOGGLE_DEFAULT
} from '@/lib/constants/search'

export interface IFormValues {
  citizenships: string[]
  countries: string[]
  entityTypes: string[]
  id: string
  location: string
  name: string
  scoreThreshold: number
  weakAKAs: boolean
  dob: Date | null
  fuzzyDOB: boolean
}

export const getInitialFormValues = (queryName: string | null): IFormValues => {
  return {
    name: queryName || '',
    id: '',
    location: '',
    scoreThreshold: SLIDER_DEFAULT,
    weakAKAs: WEAK_AKAS_DEFAULT,
    countries: [],
    citizenships: [],
    entityTypes: [],
    fuzzyDOB: FUZZY_DOB_TOGGLE_DEFAULT,
    dob: null
  }
}

export type TFormErrors = Record<keyof IFormValues, string>

const MAX_CHAR_FIELD_LENGTH = 128

export const validateFn = (values: IFormValues): TFormErrors => {
  const errors = {} as TFormErrors
  if (values.name.length > MAX_CHAR_FIELD_LENGTH) {
    errors.name = `Name must be ${MAX_CHAR_FIELD_LENGTH} characters or less`
  } else if (values.location.length > MAX_CHAR_FIELD_LENGTH) {
    errors.location = `Location must be ${MAX_CHAR_FIELD_LENGTH} characters or less`
  }

  return errors
}
