import React, { Dispatch } from 'react'
import { FieldState, FieldAction } from '@/lib/hooks/useFormField'
import { Toggle } from '@/components/lib/Form'

const TOGGLE_NAME = 'weakAKAs'
const TOOLTIP_TEXT =
  'Weak AKA is a term for a relatively broad or generic alias that may ' +
  'generate a large volume of false hits.'

export interface WeakAkasToggleProps {
  weakAkasField?: FieldState<boolean>
  setWeakAkas?: Dispatch<FieldAction<boolean>>
  controlled?: boolean
  allowed?: boolean
}

const WeakAkasToggle: React.FC<WeakAkasToggleProps> = ({
  weakAkasField,
  setWeakAkas,
  controlled = false,
  allowed
}) => (
  <Toggle
    field={weakAkasField}
    setField={setWeakAkas}
    controlled={controlled}
    label={weakAkasField?.label || 'WeakAKAs'}
    tooltipText={TOOLTIP_TEXT}
    name={TOGGLE_NAME}
    disabled={!allowed}
  />
)

export default WeakAkasToggle
