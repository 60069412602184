import React from 'react'

import { styled } from '@mui/material/styles'

import ResultsTable from './ResultsTable/ResultsTable'
import {
  useSearchScreenedEntities,
  TAuditTrailSearchParams
} from '@/lib/features/audit'
import { ContentWithLoader } from '@/components/lib/Loader'
import Typography from '@mui/material/Typography'

const EmptyMessage = styled(Typography)(() => ({
  textAlign: 'center',
  paddingTop: 25
}))

const Root = styled('div')(() => ({
  marginTop: 25,
  width: '100%'
}))

const SearchResults: React.FC<{
  params: TAuditTrailSearchParams
  setPageNumber: (pageNumber: number) => void
  setPageSize: (pageSize: number) => void
}> = ({ params, setPageNumber, setPageSize }) => {
  const { data, isFetching } = useSearchScreenedEntities(params)

  return (
    <Root>
      <ContentWithLoader
        showLoader
        isFetching={isFetching}
        data={data?.entities}
        emptyMessage={
          <EmptyMessage variant='h5' data-cy='no-matches-text'>
            No Matching Records Found
          </EmptyMessage>
        }
      >
        {data === undefined ? null : (
          <ResultsTable
            totalResults={data.total}
            results={data.entities}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            params={params}
          />
        )}
      </ContentWithLoader>
    </Root>
  )
}

export default SearchResults
