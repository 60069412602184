import { useEffect } from 'react'

import { withBreadcrumbs } from '@/components/lib/Breadcrumbs'
import ContentPane from '@/components/lib/ContentPane'

import Typography from '@mui/material/Typography'
import DownloadIcon from '@mui/icons-material/Download'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import { useGetListEntriesData, TReportName } from '@/lib/features/reporting'
import { TGetListEntriesDataResponse } from '@/lib/hooks/api/useGetListEntriesDataRPC'

import { generateCryptoReport } from '@/lib/reporting/xlsx'
import { reportDate } from '@/components/lib/Reporting/utils'
import { useAbility } from '@/lib/authorization'
import { QueryObserverResult } from 'react-query'

type TReportProps = {
  accessible: boolean
  title: string
  secondary?: string
  type: TReportName
  filename: string
}

type TReportButtonProps = {
  isFetching: boolean
  accessible: boolean
  title: string
  secondary?: string
  refetch: () => Promise<QueryObserverResult<TGetListEntriesDataResponse>>
}

const ReportButton = ({
  accessible,
  title,
  secondary,
  refetch,
  isFetching
}: TReportButtonProps) => (
  <ListItem
    disabled={!accessible}
    disableGutters
    secondaryAction={
      <IconButton
        onClick={() => (accessible ? refetch() : null)}
        disabled={!accessible || isFetching}
        edge='end'
        aria-label='delete'
      >
        <DownloadIcon />
      </IconButton>
    }
  >
    <ListItemText primary={title} secondary={secondary ? secondary : null} />
  </ListItem>
)

const Report = ({
  accessible,
  filename,
  title,
  secondary,
  type
}: TReportProps) => {
  const { data, refetch, isFetching } = useGetListEntriesData(type, {
    cacheTime: 0,
    enabled: false
  })

  useEffect(() => {
    if (!data) return

    generateCryptoReport(title, filename, data.entries)
  }, [data])

  return (
    <ReportButton
      accessible={accessible}
      title={title}
      secondary={secondary}
      refetch={refetch}
      isFetching={isFetching}
    />
  )
}

const ReportPage = () => {
  const ability = useAbility()

  return (
    <ContentPane>
      <Typography variant='h6'>Report Type</Typography>
      <List>
        <Report
          accessible={ability.can('view', 'Report', 'crypto_sanctions')}
          title='Sanctioned Crypto Wallets - Global (XLSX)'
          filename={`Sanctioned Crypto Wallets Report ${reportDate()}.xlsx`}
          type='crypto_sanctions'
        />
      </List>
    </ContentPane>
  )
}

export default withBreadcrumbs(ReportPage)
