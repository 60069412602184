import { format } from 'date-fns'
import { TCRONExpression } from '@/lib/features/job'
import { utcToZonedTime } from 'date-fns-tz'
import { parseCronExpression } from 'cron-schedule'

export const XLSX_MIME_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const toTitleCase = (s: string): string => {
  return s
    .split(' ')
    .map((w) => w.charAt(0).toUpperCase() + w.substr(1).toLowerCase())
    .join(' ')
}

const reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validateEmail = (emailString: string): boolean => {
  return reEmail.test(emailString)
}

export const formatDate = (date: Date): string => {
  return format(date, 'MM/dd/yyyy H:mm')
}

export const formatDateISO8601 = (date: Date): string => {
  return format(date, 'yyyy-MM-dd')
}

export const formatTZAgnosticDate = (date: Date): string => {
  // Take into account single digit days/months by adding zero-padding
  return (
    date.getUTCFullYear() +
    '-' +
    (date.getUTCMonth() + 1).toString().padStart(2, '0') + // months are zero-indexed, must add 1
    '-' +
    date.getUTCDate().toString().padStart(2, '0')
  )
}

export const formatMediaDate = (date: Date): string => {
  return format(date, 'MMMM d, yyyy')
}

// ParseCronExpression assumes the schedule is in localtime
// to work around this, as it's in UTC, we need to pass the date
// argument as if the current time was in UTC, and then convert the
// date returned back to as if it were local time
export const getNextScreenDate = (
  cronExpression: TCRONExpression,
  timezone: string
): Date => {
  const cron = parseCronExpression(
    `${cronExpression.minute} ${cronExpression.hour} ${cronExpression.dayOfMonth} ${cronExpression.month} ${cronExpression.dayOfWeek}`
  )

  const currentDate = new Date()
  const timeInZone = new Date(utcToZonedTime(currentDate, timezone))

  return cron.getNextDate(timeInZone)
}

/**
 * localIANATimezone returns the current users local timezone as IANA TZ database name e.g.
 * 'America/New_York'.
 *
 * @returns {string} - The local IANA timezone string.
 */
export const localIANATimezone = (): string =>
  Intl.DateTimeFormat().resolvedOptions().timeZone
