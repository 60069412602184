import React, { HTMLAttributes, useCallback, useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderGroupParams
} from '@mui/material/Autocomplete'
import { VariableSizeList, ListChildComponentProps } from 'react-window'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ListSubheader from '@mui/material/ListSubheader'
import { IOption, TDropDownProps } from '.'

const ELE_PER_PAGE = 8
const LISTBOX_PADDING_PX = 8
const SCROLLBAR_WIDTH_PX = 10
const CHECKBOX_WIDTH_PX = 42
const ITEM_PADDING_WIDTH_PX = 16
const ITEM_HEIGHT_SM_PX = 36
const ITEM_HEIGHT_MD_PX = 48
const HEADING_FONT_HEIGHT_REM = 0.875
const OPTION_FONT_HEIGHT_REM = 1
const PX_TO_CHARS_CONVERSION_CONST = 1 / 7

/**
 * Component for rendering a row of the virtualized lists drop-down menu. Adapter modified from
 * https://material-ui.com/components/autocomplete/#virtualization.
 *
 * @component
 */
const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING_PX
    }
  })
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})
OuterElementType.displayName = 'OuterElementType'

/**
 * Keeps react-window variableSizeList singleton, init list if not initialized.
 *
 * @param {any} listSize - props for rendering the list, just for dependencies.
 * @returns {React.RefObject<VariableSizeList>}
 */
const useResetCache = (listSize: number) => {
  const ref = React.useRef<VariableSizeList>(null)

  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [listSize])

  return ref
}

type ListboxProps = {
  children?: React.ReactNode
}

/**
 * Component for rendering the virtualized list. Takes place of the default component of
 * Autocomplete.
 *
 * @component
 */
const Listbox = (
  { children, ...other }: ListboxProps,
  ref:
    | ((instance: HTMLDivElement | null) => void)
    | React.MutableRefObject<HTMLDivElement | null>
    | null
) => {
  const itemData = React.Children.toArray(children)
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
  const itemCount = itemData.length
  // Default single-line list item height
  const itemHeight = smUp ? ITEM_HEIGHT_MD_PX : ITEM_HEIGHT_SM_PX

  const [listBoxWrapperMounted, setListBoxWrapperMounted] = useState(false)
  const [listboxWrapperWidthPx, setListboxWrapperWidthPx] = useState(0)
  // Callback ref for the listbox wrapper that, upon mounting of it, updates dependent state
  const listBoxWrapperRef = useCallback((node) => {
    // After the listbox wrapper is mounted (when its reference is not null):
    if (node !== null) {
      // Set width val from it, for height calculations of its child VariableSizeList
      setListboxWrapperWidthPx(node.getBoundingClientRect().width)
      // Set flag for whether it is mounted, to indicate it can actually be referenced for its width
      setListBoxWrapperMounted(true)
    }
  }, [])

  /**
   * @param fontSizeRem item's font size
   * @param lineWidthPx item's line width in pixels
   * @returns a list item's – either a group subheader or an option – line width in characters
   */
  const getLineWidthInChars = (fontSizeRem: number, lineWidthPx: number) =>
    (lineWidthPx * PX_TO_CHARS_CONVERSION_CONST) / fontSizeRem

  /**
   * Depends on the state var listboxWrapperWidthPx that equals the listbox wrapper's width,
   * which depends on the wrapper's parents' dimensions
   * @returns horizontal space in pixels needed for an item's line text
   */
  const getWidthForTextInPx = () =>
    listboxWrapperWidthPx -
    (SCROLLBAR_WIDTH_PX + CHECKBOX_WIDTH_PX + 2 * ITEM_PADDING_WIDTH_PX)

  /**
   * Calculate the height of a single item in the list
   * @param child reactNode contains metadata of a row in a variableSizeList
   * @returns height of row calculated from the length of the text content
   */
  const getChildHeight = (child: React.ReactNode) => {
    if (!React.isValidElement(child)) {
      return itemHeight
    }
    let itemContent: string, numCharsPerLine: number, numLines: number
    if (child.type === ListSubheader) {
      /**
       * If the item is a subheader to option(s), calculate the approximate necessary height from:
       * - The available horizontal space for the text content, and
       * - Our custom subheader font size
       */
      itemContent = child?.props.children
      numCharsPerLine = getLineWidthInChars(
        HEADING_FONT_HEIGHT_REM,
        getWidthForTextInPx()
      )
      numLines = Math.ceil(itemContent.length / numCharsPerLine)
      return ITEM_HEIGHT_MD_PX * numLines
    } else {
      /**
       * If the item is an option, calculate its approximate necessary height from:
       * - Available horizontal space for text content, and
       * - Our custom option font size
       */
      itemContent = child?.props.children[1]
      numCharsPerLine = getLineWidthInChars(
        OPTION_FONT_HEIGHT_REM,
        getWidthForTextInPx()
      )
      numLines = Math.ceil(itemContent.length / numCharsPerLine)
      return itemHeight * numLines
    }
  }

  /**
   * Get semi-final list height (doens't include padding) from its subheader/option items' heights
   * @returns almost-final height of list
   */
  const getListHeight = () => {
    const getItemHeightsSum = (data: typeof itemData) =>
      data.map(getChildHeight).reduce((a, b) => a + b, 0)

    if (itemCount > ELE_PER_PAGE) {
      return getItemHeightsSum(itemData.slice(0, ELE_PER_PAGE))
    }
    return getItemHeightsSum(itemData)
  }
  const gridRef = useResetCache(itemCount)
  return (
    <div ref={ref}>
      <div ref={listBoxWrapperRef}>
        {listBoxWrapperMounted && (
          <OuterElementContext.Provider value={other}>
            <VariableSizeList
              data-cy='lists-dropdown-listbox'
              itemData={itemData}
              height={getListHeight() + 2 * LISTBOX_PADDING_PX}
              width='100%'
              ref={gridRef}
              outerElementType={OuterElementType}
              innerElementType='ul'
              itemSize={(index) => {
                return getChildHeight(itemData[index])
              }}
              overscanCount={5}
              itemCount={itemCount}
            >
              {renderRow}
            </VariableSizeList>
          </OuterElementContext.Provider>
        )}
      </div>
    </div>
  )
}

const ListboxComponent = React.forwardRef<HTMLDivElement>(Listbox)

/**
 * Overrides the default render group material-ui Autocomplte group used for
 * adaption of react-window.
 *
 * @param {AutocompleteRenderGroupParams} params - contains key (for rendering li), group (for
 * groupBy function) and children (listSources data).
 * @returns {React.ReactNode[]}
 */
const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component='div'>
    {params.group}
  </ListSubheader>,
  params.children
]

/**
 * Component rendering a virtualized drop-down menu of list options.
 *
 * @component
 * @example
 * optionsData = useListSourcesDropDownOptions()
 * return (
 *  <ListsDropDown
 *    status=optionsData.status
 *    options=optionsData.options
 *    toggle=optionsData.toggle
 *    prune=optionsData.prune
 *    clear=optionsData.clear
 *  />
 * )
 */
export const ListsDropDown = (props: TDropDownProps): JSX.Element => {
  const [input, setInput] = React.useState('')
  const [selectedCount, setSelectedCount] = React.useState(0)

  const getOptions: IOption[] = React.useMemo(() => {
    if (props.status === 'success') {
      const listOptions = [props.options.option]
      let numSelected = 0

      for (const categoryOptionNode of props.options.subOptions.values()) {
        for (const listSourceOptionNode of categoryOptionNode.subOptions.values()) {
          for (const listOptionNode of listSourceOptionNode.subOptions.values()) {
            if (listOptionNode.option.selected) {
              numSelected++
            }
            if (!listOptionNode.option.hidden) {
              listOptions.push(listOptionNode.option)
            }
          }
        }
      }

      setSelectedCount(numSelected)
      return listOptions
    }
    return []
  }, [props.status, props.options])

  const getOptionSelected = (option: IOption, value: IOption): boolean => {
    return option.id === value.id && option.type === value.type
  }

  const onChange = (
    event: React.SyntheticEvent,
    value: IOption[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<IOption> | undefined
  ) => {
    if (reason === 'clear') {
      props.clear()
    } else if (details) {
      props.toggle(details.option.type, details.option.id)
    }
  }

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: IOption
  ) => (
    <li {...props}>
      <Checkbox
        color='primary'
        checked={option.selected}
        indeterminate={option.partial}
      />
      {option.name}
    </li>
  )

  return (
    <Autocomplete
      disableCloseOnSelect
      disabled={props.status !== 'success'}
      fullWidth
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={getOptionSelected}
      groupBy={(option) => option.group || ''}
      id='lists'
      includeInputInList
      inputValue={input}
      limitTags={-1}
      ListboxComponent={
        ListboxComponent as React.ComponentType<
          React.HTMLAttributes<HTMLElement>
        >
      }
      multiple
      onClose={() => {
        setInput('')
        props.prune()
      }}
      onChange={onChange}
      onInputChange={(event, value, reason) => {
        if (reason !== 'reset') {
          setInput(value)
        }
      }}
      options={getOptions}
      renderGroup={renderGroup}
      renderInput={(params) => (
        <TextField
          {...params}
          data-cy='lists-dropdown-input'
          error={props.status === 'error'}
          label={`Lists (${selectedCount})`}
          variant='standard'
        />
      )}
      renderOption={renderOption}
      renderTags={() => null}
      size='small'
      value={[props.options.option]}
    />
  )
}
export default ListsDropDown
