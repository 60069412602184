import React from 'react'
import { styled } from '@mui/material/styles'

import { QueryChip } from '@/components/lib/Query'
import Typography from '@mui/material/Typography'

import { TScreenedEntity } from '@/lib/features/Screening'

const PREFIX = 'QueryOverview'

const classes = {
  hitCount: `${PREFIX}-hitCount`,
  wrapper: `${PREFIX}-wrapper`
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.hitCount}`]: {
    marginRight: theme.spacing(1)
  },

  [`&.${classes.wrapper}`]: {
    display: 'flex',
    justifyContent: 'flex-start'
  }
}))

interface QueryOverviewProps {
  screenEntity: TScreenedEntity
}

const QueryOverview: React.FC<QueryOverviewProps> = ({ screenEntity }) => {
  const totalHits = screenEntity.hits.length
  let hitsStr = `${totalHits} hits for `

  if (totalHits === 1) {
    hitsStr = `${totalHits} hit for `
  }

  return (
    <Root className={classes.wrapper}>
      <Typography className={classes.hitCount} variant='h5' align='center'>
        {hitsStr}
      </Typography>
      <QueryChip screenEntity={screenEntity} />
    </Root>
  )
}

export default QueryOverview
