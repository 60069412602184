import React from 'react'
import {
  useLocation,
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useAuth0 } from '@auth0/auth0-react'
import { Helmet } from 'react-helmet'

import { styled } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import HomePage from './HomePage'
import AuditTrailPage from './AuditTrailPage'
import ErrorPage from './ErrorPage'
import SearchPage from './SearchPage'
import BatchSearchPage from './BatchSearchPage'
import ScheduledScreeningPage from './ScheduledScreeningPage'
import ReportPage from './ReportPage'
import { GRPCProvider } from '@/lib/gRPC/grpcContext'
import PrivateRoute from '@/components/lib/Auth/PrivateRoute'
import { useBranding } from '@/lib/hooks'
import { trackUser } from '@/lib/analytics/ga4'
import { NotificationProvider } from '@/lib/notification'
import { Honeybadger } from '@honeybadger-io/react'
import { extractUserDetails } from '@/lib/helpers/user'

import { buildAbilityFor, AbilityContext } from '@/lib/authorization'

import { jwtDecode } from 'jwt-decode'

const HEADER_SUFFIXES: { [id: string]: string } = {
  search: ' - Advanced Search',
  'audit-trail': ' - Audit',
  screen: ' - Batch Screening',
  jobs: ' - Scheduled Screening',
  reports: ' - Reports'
}

const StyledAppContainer = styled('div')(() => ({
  minHeight: '100vh',
  minWidth: '360px'
}))

const ScrollToTop = () => {
  const { pathname } = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

/**
 * Component for dynamically setting certain <head> elements based on the branding spec.
 *
 * @component
 */
const DynamicHead = () => {
  const branding = useBranding()
  const location = useLocation()
  const headerSuffix = HEADER_SUFFIXES[location.pathname.split('/')[1]] || ''
  let title = 'Castellum.AI' + headerSuffix
  let link = '/favicon.ico'

  if (branding.isSuccess) {
    title = branding.data.title + headerSuffix
    link = import.meta.env.VITE_APP_CDN_PATH + '/' + branding.data.faviconURI
  }

  return (
    <Helmet>
      <title>{title}</title>
      <link rel='shortcut icon' href={link} />
    </Helmet>
  )
}

const Pages: React.FC = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Router>
        <DynamicHead />
        <ScrollToTop />
        <StyledAppContainer>
          <Switch>
            <PrivateRoute
              path='/audit-trail'
              exact
              component={AuditTrailPage}
            />
            <PrivateRoute
              path='/signup'
              exact
              component={HomePage}
              signUp={true}
            />
            <PrivateRoute path='/login' exact component={HomePage} />
            <PrivateRoute
              path='/screen/persons'
              exact
              component={BatchSearchPage}
            />
            <PrivateRoute path='/jobs' component={ScheduledScreeningPage} />
            <PrivateRoute path='/reports' exact component={ReportPage} />
            <Route path='/search' exact component={SearchPage} />
            <Route
              path='/FAQ/'
              exact
              component={() => {
                // Redirect from our old private FAQ page to our newer public knowledge base page.
                window.location.replace('https://support.castellum.ai')
                return null
              }}
            />
            <Route path='/error' exact component={ErrorPage} />
            <Route path='/' component={HomePage} />
          </Switch>
        </StyledAppContainer>
      </Router>
    </LocalizationProvider>
  )
}

const AppContainer = () => {
  const {
    isLoading,
    isAuthenticated,
    user,
    getAccessTokenSilently
  } = useAuth0()

  const [token, setToken] = React.useState<Token | undefined>()
  const ability = buildAbilityFor(user, token)

  React.useEffect(() => {
    if (isAuthenticated) {
      const getToken = async () => {
        const accessToken = await getAccessTokenSilently()
        const decodedToken = jwtDecode(accessToken)
        const adverseMedia = decodedToken?.permissions?.includes(
          'https://castellum.ai/pb.v1.DataService/GetListSources?adverse_media'
        )

        const { userID, internal } = extractUserDetails(user)
        trackUser(decodedToken, adverseMedia, userID, internal)
        Honeybadger.setContext({ user_id: userID, internal })

        setToken(decodedToken)
      }
      getToken()
    }
  }, [getAccessTokenSilently, isAuthenticated])

  if (isLoading || (isAuthenticated && token === undefined)) {
    return <div>Loading...</div>
  }

  // Disable refetching on focus in dev
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus:
          import.meta.env.PROD ? true : false
      }
    }
  })

  return (
    <AbilityContext.Provider value={ability}>
      <NotificationProvider>
        <QueryClientProvider client={queryClient}>
          <GRPCProvider>
            <Pages />
          </GRPCProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </NotificationProvider>
    </AbilityContext.Provider>
  )
}

export default AppContainer
