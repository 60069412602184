import React from 'react'
import { HashLink } from 'react-router-hash-link'
import { LinkProps as RouterLinkProps } from 'react-router-dom'

const CustomLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => <HashLink innerRef={ref} {...props} />
)
CustomLink.displayName = 'CustomLink'

export default CustomLink
