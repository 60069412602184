import React, { useEffect, useRef } from 'react'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import { Can } from '@/lib/authorization/authorizationContext'
import { Form, useFormikContext } from 'formik'
import { ConnectedTextField } from '@/components/lib/Form'

import {
  ListSourcesDropDown,
  ListsDropDown,
  IDropDownData
} from '@/components/lib/ListSourcesDropDown'
import EntityTypesSelect from '@/components/lib/EntityTypesSelect'
import { ConnectedCountriesSelect } from '@/components/lib/CountriesSelect'
import { useEntityTypesData, useCountriesData } from '@/lib/features/Screening'
import { FuzzyDOBToggle, WeakAkasToggle } from '@/components/lib/Search'
import { ConnectedSearchSlider } from '@/components/lib/SearchSlider'
import ConnectedDatePicker from '@/components/lib/DateSelection/ConnectedDatePicker'

interface IProps {
  listSourcesDropDownOptions: IDropDownData
  queryName: string | null
}

const SearchFormBody: React.FC<IProps> = ({
  listSourcesDropDownOptions,
  queryName
}) => {
  const form = useFormikContext()
  const countriesData = useCountriesData()
  const entityTypesData = useEntityTypesData()
  const initialized = useRef(false)

  useEffect(() => {
    // If there is no query param passed, nor were we redirected from
    // the home page, then simply return.  Otherwise, trigger a search.

    if (
      listSourcesDropDownOptions.status === 'success' &&
      listSourcesDropDownOptions.anySelected() &&
      !initialized.current
    ) {
      if (!queryName) return

      form.submitForm()
      initialized.current = true
    }
  }, [listSourcesDropDownOptions.options, listSourcesDropDownOptions.status, queryName])

  return (
    <Grid item>
      <Form>
        <Grid item container spacing={3}>
          <Grid item xs={12} md={4}>
            <ConnectedTextField name='name' label='Name' type='search' />
          </Grid>

          <Grid item xs={12} md={3}>
            <Can I='filter' a='Screen' field='id' passThrough>
              {(allowed: boolean) => (
                <ConnectedTextField
                  name='id'
                  label='ID'
                  disabled={!allowed}
                  type='search'
                />
              )}
            </Can>
          </Grid>

          <Grid item xs={12} md={5}>
            <ConnectedCountriesSelect
              error={countriesData.status === 'error'}
              countryMenuItems={countriesData.data || []}
              label='Citizenship'
              limit={1}
              name='citizenships'
            />
          </Grid>

          <Grid item xs={12} md={4} data-cy='dob-picker'>
            <Can I='filter' a='Screen' field='dob' passThrough>
              {(allowed: boolean) => (
                <ConnectedDatePicker
                  name='dob'
                  label='Date of birth'
                  allowed={allowed}
                />
              )}
            </Can>
          </Grid>

          <Grid item xs={12} md={4}>
            <Can I='filter' a='Screen' field='location' passThrough>
              {(allowed: boolean) => (
                <ConnectedTextField
                  name='location'
                  label='Location'
                  disabled={!allowed}
                  type='search'
                />
              )}
            </Can>
          </Grid>

          <Grid item xs={12} md={4}>
            <ConnectedCountriesSelect
              error={countriesData.status === 'error'}
              countryMenuItems={countriesData.data || []}
              label='Country'
              limit={3}
              name='countries'
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <ListSourcesDropDown
              status={listSourcesDropDownOptions.status}
              options={listSourcesDropDownOptions.options}
              toggle={listSourcesDropDownOptions.toggle}
              prune={listSourcesDropDownOptions.prune}
              clear={listSourcesDropDownOptions.clear}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4} data-cy='lists-dropdown'>
            <ListsDropDown
              status={listSourcesDropDownOptions.status}
              options={listSourcesDropDownOptions.options}
              toggle={listSourcesDropDownOptions.toggle}
              prune={listSourcesDropDownOptions.prune}
              clear={listSourcesDropDownOptions.clear}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <EntityTypesSelect
              entityTypesData={entityTypesData}
              name='entityTypes'
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <ConnectedSearchSlider name='scoreThreshold' />
          </Grid>

          <Grid item xs={12} md={2}>
            <Can I='filter' a='Screen' field='dob' passThrough>
              {(allowed: boolean) => (
                <FuzzyDOBToggle allowed={allowed} controlled />
              )}
            </Can>
          </Grid>

          <Grid item xs={12} md={2}>
            <Can I='filter' a='Screen' field='weakAka' passThrough>
              {(allowed: boolean) => (
                <WeakAkasToggle allowed={allowed} controlled />
              )}
            </Can>
          </Grid>

          <Grid item container xs={12} justifyContent='center'>
            <Button
              variant='contained'
              type='submit'
              data-cy='search-button'
              color='secondary'
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Grid>
  )
}

export default SearchFormBody
