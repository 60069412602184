import { ComponentType } from 'react'
import { Switch, useRouteMatch, RouteComponentProps } from 'react-router-dom'

import { CreateJobPage, EditJobPage } from './CreateJobPage'
import JobListPage from './JobListPage'
import ScreenResultsPageContainer from './ScreenResultsPage'
import PrivateRoute from '@/components/lib/Auth/PrivateRoute'
import { withBreadcrumbs } from '@/components/lib/Breadcrumbs'
import ContentPane from '@/components/lib/ContentPane'

type TEditPageRouteProps = RouteComponentProps<{ jobID: string }>

const EditPageRoute = (props: TEditPageRouteProps): JSX.Element => {
  const jobIDNum = parseInt(props.match.params.jobID)
  return <EditJobPage jobID={jobIDNum} />
}

type TScreenResultsPageRouteProps = RouteComponentProps<{
  jobID: string
  screenID: string
}>

const ScreenResultsPageRoute = (
  props: TScreenResultsPageRouteProps
): JSX.Element => {
  const screenIDNum = parseInt(props.match.params.screenID)
  return <ScreenResultsPageContainer screenID={screenIDNum} />
}

const JobPages = () => {
  const { path } = useRouteMatch()

  return (
    <ContentPane>
      <Switch>
        <PrivateRoute path={path} component={JobListPage} />
      </Switch>
    </ContentPane>
  )
}

const ScheduledScreeningPage = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <PrivateRoute path={`${path}/new`} exact component={CreateJobPage} />
      <PrivateRoute
        path={`${path}/:jobID/edit`}
        exact
        // Not clear how else to satisfy the typechecker here. Using the render prop over
        // component might work but would need to expand the PrivateRoute component.
        component={EditPageRoute as ComponentType}
      />
      <PrivateRoute
        path={`${path}/:jobID/screens/:screenID`}
        exact
        component={ScreenResultsPageRoute as ComponentType}
      />
      <PrivateRoute path='/jobs' component={JobPages} />
      <PrivateRoute
        path={`${path}/:jobID/screens/:screenID`}
        exact
        component={ScreenResultsPageRoute as ComponentType}
      />
    </Switch>
  )
}

export default withBreadcrumbs(ScheduledScreeningPage)
