import React, { useState } from 'react'
import { styled } from '@mui/material/styles'

import Link from '@mui/material/Link'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'

const DialogBody = styled(Typography)(() => ({
  padding: '40px 24px',
  paddingTop: '0'
}))

type SupportModalProps = {
  buttonComponent: (fn: () => void) => JSX.Element
}

export const PricingLink = (): JSX.Element => (
  <Link
    href='https://www.castellum.ai/pricing'
    rel='noreferrer'
    target='_blank'
  >
    here
  </Link>
)

export const EnterpriseLink = (): JSX.Element => (
  <span>
    Upgrade to enterprise <PricingLink /> to access this feature.
  </span>
)

const SupportDialog: React.FC<SupportModalProps> = ({
  children,
  buttonComponent
}) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      {buttonComponent(handleOpen)}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='contact-support-header'
        aria-describedby='contact-support-modal'
      >
        <DialogTitle id='contact-support-header'>Upgrade Account</DialogTitle>
        <DialogBody id='contact-support-modal' sx={{ mt: 2 }}>
          {children === undefined ? (
            <span>
              Upgrade to premium <PricingLink /> to access this feature.
            </span>
          ) : (
            children
          )}
        </DialogBody>
      </Dialog>
    </>
  )
}

export default SupportDialog
