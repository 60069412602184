import React from 'react'
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export type TNotificationVariant = 'info' | 'error' | 'warning' | 'success'

interface IProps extends SnackbarProps {
  variant: TNotificationVariant
}

const Notification: React.FC<IProps> = ({
  message,
  variant,
  ...snackbarProps
}) => {
  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      open={message !== null}
      data-cy={`${variant}-notification`}
      {...snackbarProps}
    >
      <Alert severity={variant} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default Notification
