import React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Accordion from '@mui/material/Accordion'

import { AuditButton } from '@/components/lib/AuditTrail'
import { THit } from '@/lib/features/Screening'

import Paginator, { ROWS_PER_PAGE_OPTIONS } from './Paginator'
import HiddenResultsMessage from './HiddenResultsMessage'
import HitsListRow from './HitsListRow'

const classes = {
  default: 'default',
  success: 'success',
  error: 'error',
  needMoreInfo: 'needMoreInfo'
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.default}`]: {
    backgroundColor: 'white'
  },
  [`& .${classes.success}`]: {
    backgroundColor: theme.palette.success.light
  },
  [`& .${classes.error}`]: {
    backgroundColor: theme.palette.error.light
  },
  [`& .${classes.needMoreInfo}`]: {
    backgroundColor: theme.palette.warning.light
  }
}))

interface HitsListPaginatedProps {
  hits: THit[]
  persisted: boolean
  onSelect: (idx: number | null) => void
  listSourceQueryString: string
  openWorkflow: () => void
  openAudit: () => void
  reportButton: JSX.Element
  excelButton: JSX.Element
  selected?: number
  totalHits: number
}

const StyledList = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap'
}))

const ButtonList = styled('div')(() => ({
  alignItems: 'center',
  minHeight: '52px',
  display: 'flex',
  '& .MuiButton-root': {
    marginLeft: '8px'
  }
}))

const StyledPaginator = styled(Paginator)(() => ({
  flexGrow: 1
}))

const HitsListPaginated: React.FC<HitsListPaginatedProps> = ({
  excelButton,
  hits,
  openAudit,
  openWorkflow,
  onSelect,
  listSourceQueryString,
  persisted,
  reportButton,
  selected,
  totalHits
}) => {
  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]
  })

  const handleChangePage = (
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setState({
      ...state,
      page: newPage
    })
  }

  const handleChangeRowsPerPage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setState({
      rowsPerPage: parseInt(e.target.value, 10),
      page: 0
    })
  }

  const handleClickWorkflow = () => {
    openWorkflow()
  }

  const handleSelectHit = (idx: number | null) => {
    onSelect(idx)
  }

  const rows = []
  const firstValueOnPage = state.page * state.rowsPerPage
  const lastValueOnPage = (state.page + 1) * state.rowsPerPage
  for (let i = firstValueOnPage; i < lastValueOnPage; i++) {
    if (i >= hits.length) break

    rows.push(
      <HitsListRow
        key={i}
        idx={i}
        hit={hits[i]}
        selected={selected === i}
        select={handleSelectHit}
        listSourceQueryString={listSourceQueryString}
      />
    )
  }

  return (
    <Root>
      <Accordion elevation={4}>
        <StyledList>
          <ButtonList>
            <Button
              color='grey'
              variant='contained'
              data-cy='start-onboarding-button'
              onClick={handleClickWorkflow}
            >
              Start Workflow
            </Button>
            <AuditButton
              color='grey'
              variant='contained'
              data-cy='start-audit-button'
              openAudit={openAudit}
              disabled={!persisted}
            />
            {reportButton}
            {excelButton}
          </ButtonList>
          <StyledPaginator
            count={hits.length}
            page={state.page}
            rowsPerPage={state.rowsPerPage}
            totalHits={totalHits}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </StyledList>
      </Accordion>
      {rows}
      <HiddenResultsMessage totalHits={totalHits} />
      <Accordion elevation={4}>
        <Paginator
          count={hits.length}
          page={state.page}
          rowsPerPage={state.rowsPerPage}
          totalHits={totalHits}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Accordion>
    </Root>
  )
}

export default HitsListPaginated
