import React from 'react'
import Link from '@mui/material/Link'

const UpgradeLink: React.FC<{ additionalText?: string }> = ({
  additionalText
}) => (
  <>
    <Link
      href='https://www.castellum.ai/pricing'
      target='_blank'
      rel='noopener noreferrer'
      data-cy='upgrade-link'
    >
      Upgrade
    </Link>{' '}
    {additionalText}
  </>
)

export default UpgradeLink
