import React from 'react'
import { styled } from '@mui/material/styles'

import { useBranding } from '@/lib/hooks'
import Image from '@/components/lib/Image'

const PREFIX = 'Banner'

const classes = {
  root: `${PREFIX}-root`,
  banner: `${PREFIX}-banner`
}

const StyledBranding = styled('div')(() => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100px',
    minWidth: '400px'
  },
  [`& .${classes.banner}`]: {
    height: 'auto',
    width: '80%'
  }
}))

export const Banner: React.FC = () => {
  const branding = useBranding()

  return (
    <StyledBranding className={classes.root}>
      {branding.isSuccess && (
        <Image
          data-cy='banner'
          className={classes.banner}
          src={`${import.meta.env.VITE_APP_CDN_PATH}/${branding.data.bannerURI}`}
          alt='banner'
        />
      )}
    </StyledBranding>
  )
}
