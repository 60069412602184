import React from 'react'

import { styled } from '@mui/material/styles'

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

const Root = styled('span')(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  verticalAlign: 'middle'
}))

const TextWrapper = styled('span')(() => ({
  paddingLeft: 6
}))

const IconWithText: React.FC<{ text: string }> = ({ children, text }) => (
  <Root>
    {children}
    <TextWrapper>{text}</TextWrapper>
  </Root>
)

export const CompleteIcon = (): JSX.Element => (
  <IconWithText text='Complete'>
    <CheckCircleOutlineOutlinedIcon />
  </IconWithText>
)

export const IncompleteIcon = (): JSX.Element => (
  <IconWithText text='Incomplete'>
    <RemoveCircleIcon />
  </IconWithText>
)

export const ScreenedIcon = ({
  screenedAt
}: {
  screenedAt: string
}): JSX.Element => (
  <IconWithText text={screenedAt}>
    <AccessTimeIcon />
  </IconWithText>
)
