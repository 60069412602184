import React from 'react'
import {
  ListValue,
  JavaScriptValue
} from 'google-protobuf/google/protobuf/struct_pb.js'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import List from '@mui/material/List'
import TableRow from '@mui/material/TableRow'

import CatchAllValue from './CatchAllValue'
import { ListEntry } from 'proto-js'

type TCatchAllEntry = {
  name: string
  values: JavaScriptValue[]
}

const CatchAllEntry: React.FC<TCatchAllEntry> = ({
  name,
  values
}): JSX.Element => (
  <TableRow>
    <TableCell variant='head'>{name}</TableCell>
    <TableCell variant='body'>
      <List dense>
        {values.map((v: JavaScriptValue, i: number) => (
          <CatchAllValue key={i} value={v} />
        ))}
      </List>
    </TableCell>
  </TableRow>
)

type TCatchAllPane = {
  entity: typeof ListEntry
}

const EXCLUDED_CATCH_ALL_FIELDS = ['Shares']

const CatchAllPane: React.FC<TCatchAllPane> = ({ entity }) => {
  const catchAll: Map<string, ListValue> = entity.getCatchAllMap()
  const rows: JSX.Element[] = []
  const providerId = entity.getProviderId()

  catchAll.forEach((v, k) => {
    if (EXCLUDED_CATCH_ALL_FIELDS.includes(k)) {
      return
    }

    const values = v.getValuesList().map((v) => v.toJavaScript())
    rows.push(<CatchAllEntry key={k} name={k} values={values} />)
  })

  if (providerId !== '') {
    rows.push(
      <CatchAllEntry
        key={'List Provider ID'}
        name={'List Provider ID'}
        values={[providerId]}
      />
    )
  }

  return (
    <Table size='small'>
      <TableBody>{rows}</TableBody>
    </Table>
  )
}

export default CatchAllPane
