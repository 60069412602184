import { User } from '@auth0/auth0-spa-js'

type userDetails = {
  userID?: string
  internal: boolean
}
export function extractUserDetails(user?: User): userDetails {
  return {
    userID: user?.sub,
    internal: user?.['http://castellum.ai/internal'] === true
  }
}
