import React from 'react'

import Button, { ButtonProps } from '@mui/material/Button'
import { SupportDialog } from '@/components/lib/SupportDialog'
import { styled } from '@mui/material/styles'

import { Can } from '@/lib/authorization'
import MuiDialogActions from '@mui/material/DialogActions'
import { steps } from './types'

const NUMBER_OF_STEPS = steps.length

interface DialogButtonProps extends ButtonProps {
  stepIdx: number
  numHitsSelected: number
  onBackClick: () => void
  onNextClick: () => void
  onSubmitClick: () => void
}

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  backgroundColor: theme.palette.background.default
}))

export const DialogButtons: React.FC<DialogButtonProps> = ({
  stepIdx,
  numHitsSelected,
  onBackClick,
  onNextClick,
  onSubmitClick
}) => {
  return (
    <DialogActions>
      <Button
        disabled={stepIdx === 0}
        onClick={onBackClick}
        data-cy='back-button'
      >
        Back
      </Button>

      {stepIdx === NUMBER_OF_STEPS - 1 ? (
        <Can I='update' a='Workflow' passThrough>
          {(allowed: boolean) => (
            <SupportDialog
              buttonComponent={(openModal) => (
                <Button
                  disabled={numHitsSelected === 0}
                  onClick={allowed ? onSubmitClick : openModal}
                  color='primary'
                  variant='contained'
                  data-cy='submit-button'
                >
                  {numHitsSelected > 1 ? 'Submit All' : 'Submit'}
                </Button>
              )}
            />
          )}
        </Can>
      ) : (
        <Button
          disabled={numHitsSelected === 0}
          onClick={onNextClick}
          color='primary'
          variant='contained'
          data-cy='next-button'
        >
          Next
        </Button>
      )}
    </DialogActions>
  )
}

export default DialogButtons
