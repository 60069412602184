import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import Launch from '@mui/icons-material/Launch'
import WarningIcon from '@mui/icons-material/Warning'
import { SvgIconProps } from '@mui/material/SvgIcon'

const Title = styled(Typography)({
  fontWeight: 500
})

interface SectionTitleProps {
  title: string
}

export const SectionTitle = ({
  title,
  ...otherProps
}: SectionTitleProps): JSX.Element => {
  return <Title {...otherProps}>{title.toUpperCase()}</Title>
}

export const PaneContent = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2)
}))

interface RiskIconProps extends SvgIconProps {
  color?: 'error' | 'warning'
  isUBO?: boolean
}

export const RiskIcon = styled(
  ({ color = 'error', isUBO = false, ...otherProps }: RiskIconProps) => {
    const isWarning = color === 'warning'
    const titlePrefix = isWarning ? 'Indirect' : 'Elevated'
    const title = isUBO ? `${titlePrefix} Risk Entry` : 'Root PEP'

    return (
      <WarningIcon
        fontSize='small'
        titleAccess={title}
        color={color}
        {...otherProps}
      />
    )
  }
)<RiskIconProps>({
  marginLeft: '6px'
})

interface RelationshipLinkProps {
  name: string
  listSourceQueryString: string
}

export const RelationshipLink = ({
  name,
  listSourceQueryString
}: RelationshipLinkProps): JSX.Element => (
  <Link
    component={RouterLink}
    target='_blank'
    to={`/search?name=${encodeURIComponent(
      name
    )}${listSourceQueryString}&expand=true`}
    underline='hover'
  >
    <>
      {name}
      <sup>
        <Launch titleAccess='search in new tab' fontSize='inherit' />
      </sup>
    </>
  </Link>
)
