import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Edit from '@mui/icons-material/Edit'
import { useField } from 'formik'

import SchedulerDialog, {
  scheduleToSentence
} from '@/components/lib/SchedulerDialog'
import { TSchedule } from '@/lib/features/job'
import { useDialog } from '@/lib/hooks'

import { TFormErrors } from './JobForm'

export const validateScheduleField = (
  errors: TFormErrors,
  value: TSchedule | null
): void => {
  if (!value) {
    errors.schedule = 'Required'
  }
}

const ScheduleField = (): JSX.Element => {
  const scheduleDialog = useDialog()
  const [field, meta, helpers] = useField('schedule')
  return (
    <>
      <Typography variant='body1'>
        {field.value ? scheduleToSentence(field.value) : 'Enter a schedule...'}
        <IconButton
          color='primary'
          size='small'
          aria-label='edit schedule'
          onClick={() => scheduleDialog.onOpen()}
        >
          <Edit fontSize='inherit' />
        </IconButton>
      </Typography>

      <SchedulerDialog
        onSave={(schedule) => {
          helpers.setValue(schedule)
        }}
        dialog={scheduleDialog}
      />
      <FormHelperText
        hidden={!(meta.touched && Boolean(meta.error))}
        error={meta.touched && Boolean(meta.error)}
      >
        {meta.error}
      </FormHelperText>
    </>
  )
}

export default ScheduleField
