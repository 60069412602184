import {
  TAuditTrailDownloadParams,
  TAuditTrailSearchParams
} from '@/lib/features/audit'

export const auditQueryKeys = {
  logs: (screenedEntityID: number): (string | Record<string, number>)[] => [
    'audit',
    'logs',
    { screenedEntityID: screenedEntityID }
  ],
  searchScreenedEntities: (
    params: TAuditTrailSearchParams
  ): (string | Record<string, string>)[] => [
    'audit',
    'search',
    'screenedEntities',
    {
      endDate: params.endDate?.toString() || '',
      startDate: params.startDate?.toString() || '',
      name: params.name,
      idNumber: params.idNumber,
      // Table pagination is zero based
      pageNumber: (params.pageNumber + 1).toString(),
      pageSize: params.pageSize.toString()
    }
  ],
  report: (
    params: TAuditTrailDownloadParams
  ): (string | Record<string, string>)[] => [
    'audit',
    'report',
    {
      endDate: params.endDate?.toString() || '',
      startDate: params.startDate?.toString() || '',
      name: params.name,
      idNumber: params.idNumber
    }
  ]
}
