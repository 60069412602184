import React from 'react'
import { styled } from '@mui/material/styles'

import PlaceIcon from '@mui/icons-material/Place'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import CalendarMonth from '@mui/icons-material/CalendarMonth'

import Chip from '@mui/material/Chip'
import { TScreenEntity } from '@/lib/features/Screening'
import { format } from 'date-fns'

const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1)
}))

interface QueryChipProps {
  screenEntity: TScreenEntity
}

const QueryChip: React.FC<QueryChipProps> = ({
  screenEntity: { name, idNumber, location, referenceId, dob }
}) => {
  let formattedDob
  if (dob && dob instanceof Date) {
    formattedDob = format(dob, 'yyyy-MM-dd')
  } else {
    formattedDob = dob // Persisted screens return dob as a string
  }

  return (
    <>
      {name && <StyledChip color='primary' label={name} variant='outlined' />}
      {location && (
        <StyledChip
          color='primary'
          icon={<PlaceIcon />}
          label={location}
          variant='outlined'
        />
      )}
      {idNumber && (
        <StyledChip
          color='primary'
          icon={<AssignmentIndIcon />}
          label={idNumber}
          variant='outlined'
        />
      )}
      {formattedDob && (
        <StyledChip
          color='primary'
          icon={<CalendarMonth />}
          label={formattedDob}
          variant='outlined'
        />
      )}
      {referenceId && (
        <StyledChip
          color='primary'
          label={'Reference ID: ' + referenceId}
          variant='outlined'
        />
      )}
    </>
  )
}

export default QueryChip
