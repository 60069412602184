import { styled } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

const Root = styled(Grid)(() => ({
  paddingBottom: 25
}))

const PageHeader = (): JSX.Element => {
  return (
    <Root container spacing={2} justifyContent='flex-start'>
      <Grid item md={3}>
        <Button
          variant='contained'
          fullWidth
          color='grey'
          component={RouterLink}
          data-cy='new-scheduled-screen-button'
          to='/jobs/new'
        >
          NEW SCHEDULED SCREEN
        </Button>
      </Grid>
    </Root>
  )
}

export default PageHeader
