import createTheme from './createTheme'
import { responsiveFontSizes } from '@mui/material/styles'

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0px'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        //--- webkit scrollbar settings
        '*::-webkit-scrollbar': {
          width: '10px'
        },
        '*::-webkit-scrollbar-track': {
          WebkitBoxShadow: 'inset 0 0 3px rgba(50,50,50,0.30)'
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: '5px',
          backgroundColor: '#444444',
          outline: '1px solid slategrey'
        },

        //--- Firefox scrollbar settings
        '*': {
          scrollbarColor: '#444444 rgba(0,0,0,0.10)',
          scrollbarWidth: 'thin'
        },
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          rounded: false
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        icon: ({ ownerState }) => ({
          ...(ownerState.severity === 'warning' && {
            color: '#ed6c02 !important'
          })
        }),
        outlinedWarning: {
          borderColor: '#ff9800'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    }
  },
  palette: {
    primary: {
      light: '#484848',
      main: '#212121',
      dark: '#000000',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#fce700'
    },
    error: {
      light: '#ffe8eb',
      main: '#cf0a1a',
      dark: '#a60311',
      contrastText: '#000000'
    },
    success: {
      light: '#eaffe8',
      main: '#54E247',
      dark: '#249819',
      contrastText: '#000000'
    },
    warning: {
      light: '#fffee8',
      main: '#f5ed84',
      dark: '#f5ed84'
    },
    highlight: {
      main: '#ff0000'
    },
    text: {
      primary: '#000000'
    },
    dividerLight: 'rgba(255, 255, 255, 0.25)',
    background: {
      default: '#FFFFFF'
    }
  },
  shape: {
    borderRadius: 1
  },
  transitions: {
    duration: {
      standard: 300
    }
  }
})

export default responsiveFontSizes(theme)
