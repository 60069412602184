export const jobQueryKeys = {
  job: (jobID: number): (string | Record<string, number>)[] => [
    'job',
    { jobID: jobID }
  ],
  screens: (
    jobID: number,
    pageSize?: number
  ): (string | Record<string, number | undefined>)[] => [
    'job',
    'screens',
    { jobID: jobID, pageSize: pageSize }
  ],
  userJobs: ['job', 'all']
}
