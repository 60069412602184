import Box from '@mui/material/Box'

import { TCRONExpression } from '@/lib/features/job'
import DayOfTheWeek from './DayOfTheWeek'
import DaysOfTheMonth from './DaysOfTheMonth'

type TMonthlyProps = {
  cron: TCRONExpression
  onChange: (cron: TCRONExpression) => void
}

export const Monthly = (props: TMonthlyProps): JSX.Element => {
  return (
    <Box>
      <DayOfTheWeek {...props} />
      <DaysOfTheMonth {...props} />
    </Box>
  )
}

export default Monthly
