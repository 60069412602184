import React from 'react'
import { styled } from '@mui/material/styles'
import AuditLogPreview from '@/components/lib/Workflow/AuditLogPreview'
import { ListEntry } from 'proto-js'
import {
  THitClassification,
  THitClassificationReason,
  TScreenedEntity
} from '@/lib/features/Screening'

const PREFIX = 'Review'

const classes = {
  root: `${PREFIX}-root`
}

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    flexBasis: '75%'
  }
}))

type TReview = {
  screenEntity: TScreenedEntity
  listEntry?: typeof ListEntry
  timestamp: Date
  classification: THitClassification
  matchType: string
  reasons: THitClassificationReason[]
  analysis: string
  singleHitSelected?: boolean
}

const Review: React.FC<TReview> = ({
  screenEntity,
  listEntry,
  timestamp,
  classification,
  matchType,
  reasons,
  analysis
}) => {
  return (
    <Root className={classes.root}>
      <AuditLogPreview
        screenEntity={screenEntity}
        listEntry={listEntry}
        timestamp={timestamp}
        classification={classification}
        matchType={matchType}
        reasons={reasons}
        analysis={analysis}
      />
    </Root>
  )
}

export default Review
