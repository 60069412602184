import React, { useEffect } from 'react'

import { styled } from '@mui/material/styles'

import Collapse from '@mui/material/Collapse'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import TableFooter from '@mui/material/TableFooter'
import {
  TSearchScreenedEntitiesResult,
  TAuditTrailSearchParams,
  useGetAuditReport
} from '@/lib/features/audit'
import { screenTypeLabels } from '@/lib/features/Screening'
import AuditTable from './AuditTable'
import PaginationActions, { PAGE_SIZE_OPTIONS } from './PaginationActions'
import { formatDate, XLSX_MIME_TYPE } from '@/lib/utils'
import { saveAs } from 'file-saver'
import { reportDate } from '@/components/lib/Reporting/utils'

const ResultDetails = styled(Typography)(() => ({
  margin: '15px 0',
  fontSize: 14,
  fontWeight: 500
})) as typeof Typography

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: 500,
    fontSize: 14
  }
}))

const SearchDetails = styled('div')(() => ({
  display: 'inline',
  marginRight: 24,
  fontSize: 14
}))

const DetailsTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  paddingTop: 0
}))

const DataRow = styled(TableRow)(({ theme }) => ({
  // Style every 3rd and 4th row the same color to account for the
  // 'hidden' audit results row that will expand
  '&:nth-of-type(4n+3), &:nth-of-type(4n+4)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const ScreenInfoRow = styled(DataRow)(() => ({
  '& > td': {
    borderBottom: 'unset'
  }
}))

const ScreenRows: React.FC<{ rowData: TSearchScreenedEntitiesResult }> = ({
  rowData
}) => {
  const [open, setOpen] = React.useState(false)

  const screenType = screenTypeLabels[rowData.screenType]
  const formattedScore = Math.trunc(rowData.minScore * 100)

  // Close the pane when we change pages and row data changes
  useEffect(() => {
    setOpen(false)
  }, [rowData.id])

  return (
    <>
      <ScreenInfoRow key={rowData.name}>
        <StyledTableCell data-cy='audit-result-time'>
          {formatDate(rowData.ranAt)}
        </StyledTableCell>
        <StyledTableCell data-cy='audit-result-name'>
          {rowData.name}
        </StyledTableCell>
        <StyledTableCell>{rowData.idNumber}</StyledTableCell>
        <StyledTableCell>{rowData.location}</StyledTableCell>
        <StyledTableCell>{rowData.referenceId}</StyledTableCell>
        <StyledTableCell align='right'>
          <IconButton
            aria-label='expand row'
            size='small'
            data-cy='expand-row'
            onClick={() => setOpen((state) => !state)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
      </ScreenInfoRow>
      <DataRow data-cy='audit-record-row-item'>
        <DetailsTableCell colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <ResultDetails component='div'>
              <SearchDetails data-cy='screen-type'>
                Search Type: {screenType}
              </SearchDetails>
              <SearchDetails data-cy='score-threshold'>
                Score Threshold: {formattedScore}
              </SearchDetails>
            </ResultDetails>
            <AuditTable screenEntityID={rowData.id} />
          </Collapse>
        </DetailsTableCell>
      </DataRow>
    </>
  )
}

const ResultsTable: React.FC<{
  results: TSearchScreenedEntitiesResult[]
  totalResults: number
  setPageNumber: (pageNumber: number) => void
  setPageSize: (pageSize: number) => void
  params: TAuditTrailSearchParams
}> = ({
  results,
  params: { pageNumber, pageSize, ...reportParams },
  setPageNumber,
  setPageSize,
  totalResults
}) => {
  const { data, isFetching, refetch } = useGetAuditReport(reportParams, {
    cacheTime: 0,
    enabled: false
  })

  useEffect(() => {
    if (!data) return

    const file = new Blob(data, {
      type: XLSX_MIME_TYPE
    })
    saveAs(file, `Audit Log Export ${reportDate()}.xlsx`)
  }, [data])

  const Paginator = (
    <TablePagination
      colSpan={6}
      count={totalResults}
      page={pageNumber}
      rowsPerPage={pageSize}
      rowsPerPageOptions={PAGE_SIZE_OPTIONS}
      SelectProps={{
        inputProps: {
          'aria-label': 'rows per page'
        },
        native: true
      }}
      onPageChange={(event, page) => setPageNumber(page)}
      onRowsPerPageChange={(event) => setPageSize(parseInt(event.target.value))}
      ActionsComponent={(paginatorProps) => (
        <PaginationActions
          refetch={refetch}
          isFetching={isFetching}
          {...paginatorProps}
        />
      )}
    />
  )

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>{Paginator}</TableRow>
          <TableRow>
            <StyledTableCell>Search Time</StyledTableCell>
            <StyledTableCell>Search Name</StyledTableCell>
            <StyledTableCell>Search ID</StyledTableCell>
            <StyledTableCell>Search Location</StyledTableCell>
            <StyledTableCell>Reference ID</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((row, i) => (
            <ScreenRows key={i} rowData={row} />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>{Paginator}</TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default ResultsTable
