import { useField } from 'formik'
import Switch, { SwitchProps } from '@mui/material/Switch'

interface TConnectedSwitchProps extends SwitchProps {
  name: string
}

export const ConnectedSwitch = ({
  name,
  ...muiSwitchProps
}: TConnectedSwitchProps): JSX.Element => {
  const [field, , helpers] = useField(name)

  const toggleChecked = () => {
    helpers.setValue(!field.value)
  }

  return (
    <Switch
      {...muiSwitchProps}
      {...field}
      checked={field.value}
      onChange={toggleChecked}
    />
  )
}

export default ConnectedSwitch
