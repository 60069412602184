import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { TSerializeableFileData, useSerializeableFileData } from '@/lib/hooks'
import { File } from 'proto-js'
import { useValidateBatchFileRPC } from '@/lib/hooks/api/useValidateBatchFileRPC'

type TQueryOptions = Omit<
  UseQueryOptions<null, Error, null, (string | TSerializeableFileData)[]>,
  'queryKey' | 'queryFn'
>

/**
 * Hook for validating a CSV file submitted for batch screening. We use the
 * useSerializeableFileBuffer hook to create a plain serializeable array of the file
 * buffer. This is so that it can be passed in to useQuery which requires that the query
 * key be serializeable.
 *
 * @param {File} file - The file object.
 * @returns {QueryResult<boolean>} - True if the file is valid otherwise the details are
 * in the error.
 */
export const useValidateBatchFile = (
  file: File | null,
  options?: TQueryOptions
): UseQueryResult<null, Error> => {
  const fileData = useSerializeableFileData(file)
  const rpc = useValidateBatchFileRPC()
  return useQuery(['validateBatchFile', fileData], () => rpc(fileData), {
    enabled: fileData != null,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...options
  })
}

export default useValidateBatchFile
