import React from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

import { steps, TWorkflowStep } from '.'

const stepNames: Record<TWorkflowStep, string> = {
  classify: 'Classify',
  reasons: 'Reasons',
  analysis: 'Analysis',
  review: 'Review'
}

interface Props {
  stepIdx: number
}

const WorkflowStepper: React.FC<Props> = (props) => {
  return (
    <Stepper
      style={{ padding: 24 }}
      activeStep={props.stepIdx}
      data-cy='workflow-stepper'
    >
      {steps.map((s, i) => (
        <Step key={i}>
          <StepLabel>{stepNames[s]}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default WorkflowStepper
